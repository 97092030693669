import React, { useEffect, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import Checkbox from '@mui/material/Checkbox';
import * as Yup from "yup";
import { axiosToken, s3config } from '../../services/api';
import constant from '../../constant/Validation';
import { toast } from "react-toastify";
import S3 from "react-aws-s3";
import Images from '../../constant/Images';
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";


export default function AdminProfile({ openDialogProp, onClose, onCancel, data }) {
    let config = { ...s3config, dirName: "admin-profile", };
    const initialValues = {
        name: data?.name,
        email: data?.email,
        profile_pic: data?.profile_pic,
        password: '',
        id: data?.id,
        auth: data?.auth,
        confirmpassword:''

    }
    const ReactS3Client = new S3(config);
    const uploadFile = (file, name, setFieldValue, field_name) => {
        ReactS3Client.uploadFile(
            file, "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name
        )
            .then((data) => {
                setFieldValue(field_name, data.key);
            })
            .catch((err) => console.log(err));
    };
    const StudentSchema = Yup.object({
        name: Yup.string().required("Please enter name"),
        email: Yup.string().required("Please enter email").email('Please enter valid email'),
        password: Yup.string().matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})", 'Please Enter Valid password'),
        confirmpassword: Yup.string()
            .when("password", {
                is: (password) => (password && password.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    constant.CreatePassword.matchErrMsg
                ),
            }),
    })
    const [open, setOpen] = useState(false);
    const handleClose = () => { onClose(false); setOpen(false); };
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp]);
    useEffect(() => { console.log(initialValues, 'initalvalues') }, [])
    const upload = useRef(null);
    function checkValidFile(inputID, exts, size) {
        if (inputID.size <= size) {
            return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
                inputID.name
            );
        }
    }


    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Admin Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={StudentSchema}
                                onSubmit={(values) => {
                                    console.log(values, 'faq values')
                                    axiosToken.put('/admin/createsuperadmin', values)
                                        .then((res) => {
                                            toast.success(res.data.message, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                            window.location.reload();
                                        })
                                        .catch((err) => { console.log(err.response) })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-0'>
                                                        <div className='col-md-12 mb-3'>
                                                            <div className="user__info_tutor_profile">
                                                                {initialValues?.profile_pic ? <img src={constant.common.bidBucket + initialValues?.profile_pic} id="profile" className="user_img_tutor__profilepic" />
                                                                    : <img src={Images.RoundImg} id="profile" className="user_img_tutor__profile" />}
                                                                <h6 onClick={() => { upload.current.click(); }} className="profile__cameraicon">
                                                                    <PhotoCameraIcon />
                                                                </h6>
                                                                <input type="file" accept="image/*" id="file" ref={upload} style={{ display: "none" }}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)
                                                                        ) {
                                                                            //setFieldValue( "profile_pic", e.target.files[0].name);
                                                                            uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "profile_pic");
                                                                            var output = document.getElementById("profile");
                                                                            output.src = URL.createObjectURL(e.target.files[0]);
                                                                            output.onload = function () { URL.revokeObjectURL(output.src); };
                                                                        } else {
                                                                            toast.error("Please Select Valid Image. or Check image Size", { position: toast.POSITION.TOP_RIGHT, });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <TextField
                                                                    className="fieldtext"
                                                                    type="text"
                                                                    name="name"
                                                                    label="Name"
                                                                    error={Boolean(touched.name && errors.name)}
                                                                    helperText={touched.name && errors.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.name}
                                                                    variant="outlined"
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <TextField
                                                                    className="fieldtext"
                                                                    type="text"
                                                                    name="email"
                                                                    label="Email"
                                                                    disabled={true}
                                                                    error={Boolean(touched.email && errors.email)}
                                                                    helperText={touched.email && errors.email}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.email}
                                                                    variant="outlined"
                                                                />
                                                            </div>
                                                            <div className='col-md-12 mt-4'>
                                                                {/* <TextField
                                                                    className="fieldtext"
                                                                    type="text"
                                                                    name="password"
                                                                    label="Password"
                                                                    error={Boolean(touched.password && errors.password)}
                                                                    helperText={touched.password && errors.password}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.password}
                                                                    variant="outlined"
                                                                /> */}
                                                            </div>
                                                            <div className='col-md-6 mt-4'>
                                                            {/* <TextField
                                                                    className="fieldtext"
                                                                    type="text"
                                                                    name="confirmpassword"
                                                                    label="Password"
                                                                    error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                                                                    helperText={touched.confirmpassword && errors.confirmpassword}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.confirmpassword}
                                                                    variant="outlined"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                    >
                                                        <h6 onClick={() => { handleSubmit() }}>Update</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5">
                                                        <h6 onClick={() => { onCancel(false) }}>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
