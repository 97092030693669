const RegisterImg = require("../assets/img/registerimg.jpg");
const LoginImg = require("../assets/img/loginimg.jpg");
const Facebook = require("../assets/img/facebook.png");
const Google = require("../assets/img/google.png");
const Icone = require("../assets/img/icone.jpg");
const Logo = require("../assets/img/i2global_logo.png");
const upload_icon = require("../assets/img/upload_icon.png");
const add_icon = require("../assets/img/plus.png");
const BackgroundImg = require("../assets/img/otp1screen.jpg");
const Verify = require("../assets/img/verify3.jpg");
const edu_icon = require("../assets/img/education_icon.png");
const Gplay = require("../assets/img/gplay.png");
const AppleImg = require("../assets/img/app3.png");
const App3 = require("../assets/img/app3.png");
const Preview = require("../assets/img/preview.png");
const RoundImg = require("../assets/img/img5.png");
const OTPSuccess = require("../assets/img/otp_success.png");
const clock = require("../assets/icons/clock.png");
const calendar = require("../assets/icons/calendar.png");
const edit_icon = require("../assets/icons/edit.png");
const view_icon = require("../assets/icons/view.png");
const cancel_icon = require("../assets/icons/cancel.png");
const check_icon = require("../assets/icons/check.png");
const webinar_icon = require("../assets/icons/webinar.png");
const schedule = require("../assets/icons/schedule.png");
const UploadAssign = require("../assets/img/upload_assign.jpg");
const Time = require("../assets/img/time.jpg");
const Eyeicon = require("../assets/img/eyeicon.jpg");
const Bg = require("../assets/img/BG.png");
const Fb = require("../assets/img/fb.png");
const Fbimg = require("../assets/img/Fbimg.png");
const AddIconAssign = require("../assets/img/addicon.png");
const Reg = require("../assets/img/reggg.png");
const Pen = require("../assets/img/pen.png");
const About = require("../assets/img/test.jpeg")
const NoImg = require("../assets/img/no_img.jpg")
const PlayStore = require("../assets/img/playstore.png")
const AppStore = require("../assets/img/appstore.png")
const AboutImg1 = require("../assets/img/testimg.jpg")
const AboutImg2 = require("../assets/img/testimg2.jpg")
const Minus = require('../assets/img/minus.png');
export default {
  RegisterImg,
  LoginImg,
  Facebook,
  Google,
  Icone,
  Logo,
  upload_icon,
  add_icon,
  BackgroundImg,
  Verify,
  edu_icon,
  AddIconAssign,
  Gplay,
  AppleImg,
  App3,
  Preview,
  RoundImg,
  OTPSuccess,
  clock,
  calendar,
  schedule,
  edit_icon,
  view_icon,
  cancel_icon,
  check_icon,
  webinar_icon,
  UploadAssign,
  Time,
  Eyeicon,
  Bg,
  Fb,
  Fbimg,
  Reg,
  Pen,
  About,
  NoImg,
  PlayStore,
  AppStore,
  AboutImg1,
  AboutImg2,
  Minus
};
