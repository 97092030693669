import React, { useEffect, useRef, useState } from 'react'
import { axiosToken, s3config } from '../../../services/api';
import S3 from "react-aws-s3";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { loading } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import Images from '../../../constant/Images';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field, ErrorMessage } from "formik";;


export default function UploadCertificate({ openDialogProp, onClose, data, onCancel, refresh,studentid }) {
    const dispatch = useDispatch();
    const blob_img = useRef(null);
    const [open, setOpen] = useState(false);
    const handleClose = () => { onClose(false); setOpen(false); };
    const { id } = useParams();
    useEffect(() => { setOpen(openDialogProp); }, [openDialogProp])
    let config = { ...s3config, dirName: "certificate", };
    useEffect(() => { console.log(data) }, [])
    const ReactS3Client = new S3(config);
    const uploadFile = (file, name, setFieldValue,field_name) => {
        dispatch(loading(true))
        ReactS3Client.uploadFile(file,
            "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name)
            .then((data) => {
                // field_name === 'course_image' ? setcourse_image_name(name) : setVideoName(name);
                // field_name === 'course_image' ? setthumbLink(data.key) : setVideoLink(data.key)
                setFieldValue(field_name,data.key)
                dispatch(loading(false))
            })
            .catch((err) => { console.log(err); dispatch(loading(false)) });
    };
    function checkValidFile(inputID, exts, size) {
        if (inputID.size <= size) {
            return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(inputID.name);
        }
    }
    const initialValues = {
        student: studentid,
        course_master: id,
        blob: '',
        title: '',
        description: ''
    }
    useEffect(()=>{
        console.log(initialValues)
    },[studentid])
    const CertificateSchema = Yup.object({
        title: Yup.string().required('Please Enter Title').trim()
        .matches(/^[aA-zZ\s]+$/, "alphabets are allowed for this field "),
        description: Yup.string().required('Please enter description'),
        blob:Yup.string().required('Please Upload Certificate')
      })

    return (


        <Dialog
            open={open}
            maxWidth={'lg'}
            keepMounted={false}
            aria-describedby="alert-dialog-slide-description"
            className='assignment__dialog certificate_upload'
        >
            
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={CertificateSchema}
                        onSubmit={(values) => {
                           axiosToken.post('tutor/studentcertificate',values)
                           .then((res)=>{
                            toast.success(res.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                              handleClose()
                           })
                           .catch((err)=>{
                            toast.error(err.response.data.detail, {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                           })
                        }}
                    >
                        {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (

                            <Form>
                                <div>
                                    <p>Please Upload Certificate for Student</p>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-10 mx-auto'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <TextField className="fieldtext" type="text" name="title"
                                                            label="Title"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.title && errors.title)}
                                                            helperText={touched.title && errors.title}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <TextField className="fieldtext" type="text" name="description"
                                                            label="Description"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.description && errors.description)}
                                                            helperText={touched.description && errors.description}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className='col-md-12 mx-auto  position-relative mt-3'>
                                                        <TextField id="outlined-basic" label="Upload Certificate" variant="outlined"
                                                            error={Boolean(touched.blob && errors.blob)}
                                                            helperText={touched.blob && errors.blob}
                                                            onBlur={handleBlur}
                                                            name="blob"
                                                            value={values.blob}
                                                        />
                                                        <span className="upload__icones" onClick={() => { blob_img.current.click(); }}>
                                                            <img src={Images.upload_icon} alt="upload" />
                                                        </span>
                                                        <span style={{ fontSize: '13px' }}>800X400 for better resolution</span>
                                                        <div className={"preview " + (values.blob ? "d-block" : "d-none")
                                                        }>
                                                            <Tooltip title={'Remove'}
                                                                onClick={() => { setFieldValue('blob', ''); blob_img.current.value = '' }}
                                                                style={{ position: 'absolute', right: '6px', top: '65px', background: 'snow', objectFit: 'cover' }}>
                                                                <IconButton><CloseIcon /> </IconButton>
                                                            </Tooltip>
                                                            <img id='output' style={{ width: '100px', height: '100px', borderRadius: '20px', marginTop: '20px', marginLeft: 'auto', display: 'block' }} />
                                                        </div>
                                                        <input type="file" multiple id="file" ref={blob_img} style={{ display: "none" }}
                                                            onChange={(e) => {
                                                                if (checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)) {
                                                                    //setFieldValue("blob", e.target.files[0].name);
                                                                    uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "blob");
                                                                    var output = document.getElementById("output");
                                                                    output.src = URL.createObjectURL(e.target.files[0]);
                                                                    output.onload = function () {
                                                                        URL.revokeObjectURL(output.src); // free memory
                                                                    };
                                                                } else {
                                                                    toast.error("Please Select Valid Image or Check Valid Size.", { position: toast.POSITION.TOP_RIGHT, });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <DialogActions>
                                    <div className="gender_btn btn_active" >
                                    <h6 onClick={() => { handleSubmit() }}>Upload</h6>
                                    </div>
                                    <div className="gender_btn" onClick={() => { onClose(false); }}>
                                        <h6>Cancel</h6>
                                    </div>
                                </DialogActions>
                            </Form>

                        )}
                    </Formik>
                </DialogContentText>
            </DialogContent>
        </Dialog>


    )
}
