import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Images from "../../constant/Images";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "../Assignment/Assignment.css";
import Plus from "../../assets/icons/Plus.svg";
import AssignmentReview from '../Assignment/AssignmentReview/AssignmentReview';
import { useNavigate } from "react-router-dom";
import AssignmentList from "./AssignmentList/AssignmentList";
import { axiosToken } from "../../services/api";
import {useSelector} from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";



export default function Assignment() {
  //const { state } = Route;
   const {reducer} = useSelector(state=>state);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [data,setData]= useState([]);
  const [dashboardCount, setDashbardCount] = useState([])

  
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    getAssigentListData(newValue)
  };
  useEffect(()=>{
    getAssigentListData(value)
   
   //console.log(reducer.assignmentReload,'store data');
  },[])
  useEffect(( )=>{getAssigentListData(value)},[reducer.assignmentReload])
  const getAssigentListData =(value)=>{
    axiosToken.get('tutor/tutorassignment?status_id='+value)
    .then((res)=>{ 
    setData(res.data.details);
    setDashbardCount(res.data.dashboard);
    console.log(res.data.dashboard)
    console.log('data loaded')})
    .catch((err)=>{console.log(err)})
  }
  
  return (
    <div>
      <Header />
      <div className="container-fluid" id="assignment__container_a">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-10">
                <div className="assignmentInfo">
                  <h4 className="user_name">Assignments</h4>
                  <div className="col-md-6 mt-5 assignment_box_container">
                    <div className="assignment_box emptyassign">
                      <div className="">
                        <img
                          src={Images.UploadAssign}
                          alt="assign"
                          className="assignment__icon"
                        />
                        <p className="numbersize">{dashboardCount?.created_count}</p>
                    
                        <p>Created</p>
                      </div>
                      <div className="vl"></div>
                      <div>
                        <img
                          src={Images.Time}
                          alt="assign"
                          className="assignment__icon"
                        />
                        <p className="numbersize">{dashboardCount?.inprogress}</p>
                        <p>Inprogress</p>
                      </div>
                      <div className="vl"></div>
                      <div>
                        <img
                          src={Images.Eyeicon}
                          alt="assign"
                          className="assignment__icon"
                        />
                        <p className="numbersize">{dashboardCount?.submit}</p>
                        <p>Yet to Review</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="boxe__add">
                  <h6
                    onClick={() => navigate("/assignment/create-assignment")}
                    className="assign_btns btn_active"
                  >
                    Create Assignment
                    <img src={Plus} className="Plus__icon" />
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 emptyassign">
                <div className="list_box courses">
                  <TabContext value={value}>
                    <div className="list_box_header">
                      <h4 className="assignment">Assignments </h4>
                      <TabList
                        TabIndicatorProps={{ style: { backgroundColor: "black", } }}
                        onChange={handleTabChange}
                      >
                        <Tab style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}
                          label="Published"
                          value="1"
                          className="tab__black"
                        />
                        <Tab style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}
                          label="Inprogress"
                          value="0"
                          className="tab__black"
                        />
                      </TabList>
                    </div>
                    <AssignmentList data={data} value={value} />
                   
                  </TabContext>
                </div>
              </div>
              <div className="col-md-6 emptyassign ">
                <div className="list_box courses ">
                  <TabContext value={value}>
                    <AssignmentReview />
                   
                  </TabContext>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
              <Calendar onChange={onChange} value={values} />
            </div> */}
          {/*  </div> */}
        </div>
      </div>
       <div className="mb-100"></div>  
      <Footer />  
    </div>
  );
}
