import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
//   const user = { loggedIn: false };
  return JSON.parse(localStorage.getItem('user'))
};
const isAdmin = () =>{
  return localStorage.getItem('role');
};


const ProtectedRoutes = ({allowedRoles}) => {
  const isAuth = useAuth();
  const isAdminUser = isAdmin();
  useEffect(()=>{},[])
  //return isAuth ? <Outlet />  :  <Navigate to="/login" />;
  return isAuth?.token ?  <Outlet /> :  <Navigate to="/login" />;
};

 //return isAuth  ? isAdminUser == 4?<Outlet /> : alert('admin user found')   : <Navigate to="/login" />;
export default ProtectedRoutes;