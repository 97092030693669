import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from "@mui/material/";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export default function EditInfo({openDialogProp, onClose,editData,onCancel}) {
    const SchoolInfoSchema  = Yup.object({
        academy_name:Yup.string().required(constant.addSchoolInfo.academy_name).trim(),
       // academy_info:Yup.string().required(constant.addSchoolInfo.academy_info).trim(),
         academy_board_master:Yup.string().required(constant.addSchoolInfo.academy_board_master).trim(),
         academy_branch_master:Yup.string().required(constant.addSchoolInfo.academy_branch_master).trim()  
    })
    const initialValues = {
        id:"",
        academy_name:'',
        academy_info:'',
        academy_board_master:'',
         academy_branch_master:'' 
    }
    useEffect(() => {
        axiosToken.get("/admin/academymasterinfo").then((res) => {
            setBoard(res.data.board)
            setBranch(res.data.branch)
            console.log(res.data)
        })
    }, []) 
    useEffect(() => {}, []);
    const [open, setOpen] = React.useState(false);
    const [academicData, setAcademicData] = useState()
    const [branch,setBranch] = useState([]);
    const [board, setBoard] = useState([]);
   
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => { setOpen(openDialogProp);

        console.log(editData,'ssssssss')
        setAcademicData(editData) 
        initialValues.id = academicData?.id
        initialValues.academy_name = academicData?.academy_name
        initialValues.academy_info = academicData?.academy_info
        //initialValues.academy_branch_city = academicData?.academy_branch_city
        initialValues.academy_branch_master = academicData?.academy_branch_master
        initialValues.academy_board_master = academicData?.academy_board_master
       /*  initialValues.state = academicData?.state_id
        initialValues.country = academicData?.country_id */
        console.log(initialValues)
    }, [openDialogProp,academicData])
 
    
    return (
        <div>
             <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Edit Institution  </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                        <Formik
                                initialValues={initialValues}
                                validationSchema={SchoolInfoSchema}
                                onSubmit={(values) => {
                                   axiosToken.put('/admin/academymasterinfo',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                      console.log(err,'errrrrr')
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                      {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="academy_name"
                                                                label="Academy Name"
                                                                 error={Boolean(touched.academy_name && errors.academy_name)}
                                                                helperText={touched.academy_name && errors.academy_name} 
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.academy_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="academy_info"
                                                                label="Academy Info"
                                                                /*  error={Boolean(touched.academy_info && errors.academy_info)}
                                                                helperText={touched.academy_info && errors.academy_info}  */
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.academy_info}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                        
                                                        <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Branch
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='academy_branch_master'
                                                                    label="Select Branch"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.academy_branch_master && errors.academy_branch_master)}
                                                                    helperText={touched.academy_branch_master && errors.academy_branch_master}
                                                                    value={values.academy_branch_master}
                                                                >
                                                                     {branch.map((e, key) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={key}
                                                                                value={e.id}
                                                                            >
                                                                                {e.branch_location_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })} 
                                                                </Select>
                                                            </FormControl>
                                                     
                                                        </div>
                                                        <div className='col-md-6'>
                                                        
                                                        <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Board
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='academy_board_master'
                                                                    label="Select Board"
                                                                    onChange={handleChange}
                                                                    value={values.academy_board_master}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.academy_board_master && errors.academy_board_master)}
                                                                    helperText={touched.academy_board_master && errors.academy_board_master}
                                                                    
                                                                >
                                                                       {board.map((e, key) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={key}
                                                                                value={e.id}
                                                                            >
                                                                                {e.board_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })} 
                                                                </Select>
                                                            </FormControl>

                                                        </div>
                                                        </div>  
                                                      </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit() }}

                                                    >
                                                        <h6 >Update Details</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
                       

}