import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, TextField } from "@mui/material";
import Images from "../../constant/Images.js";
import axios from "axios";
import constant from "../../constant/Validation.js";
import "../CreatePassword/CreatePassword.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { loading } from "../../store/actions.js";
import { API_ENDPOINT } from "../../services/api.js";
import { useDispatch } from "react-redux";
const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(constant.CreatePassword.passwordErrMsg)
    .matches(
      /* /^.((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})*(?=.{8,}).*$/, */
      /*  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, */
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
      /*  "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$", */
      constant.CreatePassword.passwordOnenoMsg
      /*  constant.CreatePassword.passwordMinLen */
    ),

  confirmpassword: Yup.string()
    .required(constant.CreatePassword.passwordErrMsg)
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        constant.CreatePassword.matchErrMsg
      ),
    }),
});

function CreatePassword() {
  const navigate = useNavigate();
  const [role] = useState("tutor");
  const [success, setSuccess] = useState(true);
  const [values, setValues] = React.useState({
    showPassword: false,
    showconfirmpwd: false,
  });

  const initialValues = { password: "", confirmpassword: "", role: "tutor" };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showconfirmpwd: !values.showconfirmpwd,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false));
  }, []);

  return (
    <div className="container__pwd" id="password__container">
      <img src={Images.Bg} alt="black" className="bgimgg" />
      <div className="centered">
        {success ? (
          <Formik
            initialValues={initialValues}
            validationSchema={PasswordSchema}
            onSubmit={(values) => {
              console.log(values);
              var name = localStorage.getItem("name");
              var email = localStorage.getItem("email");
              var new_password = values.password;
              var confirm_pass = values.confirmpassword;
              const payload = {
                role: role,
                name: name,
                email: email,
                password: new_password,
                confirmpassword: confirm_pass,
              };
              axios
                .post(API_ENDPOINT + "admin/signup", payload)
                .then((res) => {
                  console.log("signup res", res.data.token);
                  if (res.status === 201) {
                    setSuccess(false);
                    localStorage.setItem("user", JSON.stringify(res.data));
                    //localStorage.clear();
                    setTimeout(() => {
                      navigate("/ProfileRegisteration");
                    }, 2000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            {({ errors, handleBlur, handleChange, touched, isValid }) => (
              <div className="card createpwd ">
                <Form>
                  <div className="textspace ">
                    <h5 className="textpwdbg pb-4">Create Your Password</h5>
                    <div className="pwd__form">
                      <FormControl variant="outlined" className="pb-4">
                        <InputLabel htmlFor="standard-adornment-password">
                          Enter Password
                        </InputLabel>
                        <Field
                          as={OutlinedInput}
                          id="textfieldpwd"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange("password")}
                          onBlur={handleBlur}
                          className={
                            "input " +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                          name="password"
                          label="Enter Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="password_feedback"
                        />
                      </FormControl>
                    </div>

                    <div className="pwd__form py-4">
                      <FormControl variant="outlined" className="pb-1">
                        <InputLabel htmlFor="standard-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <Field
                          as={OutlinedInput}
                          id="textfieldpwd"
                          type={values.showconfirmpwd ? "text" : "password"}
                          value={values.confirmpassword}
                          onChange={handleChange("confirmpassword")}
                          className={
                            "input " +
                            (errors.confirmpassword && touched.confirmpassword
                              ? " is-invalid"
                              : "")
                          }
                          name="confirmpassword"
                          label="Confirm Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showconfirmpwd ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <ErrorMessage
                          name="confirmpassword"
                          component="div"
                          className="password_feedbacke"
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="btnverifye">
                    <Button type="submit" id="pwdbgcolor">
                      Submit
                    </Button>

                    <Button
                      variant="contained"
                      type="button"
                      className="btn btn-light"
                      id="pwdwhitecolor"
                      onClick={() => navigate("/otpverify")}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        ) : (
          <div className="container">
            <div className="Appd col-md-12 d-flex justify-content-center">
              <div className="card_successe centere">
                <img src={Images.OTPSuccess} alt="otpsuccess" />
                <p className="mobile">Your account has been verified</p>
                <h5>
                  <b>Successfully!</b>
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default CreatePassword;
