import React, { useEffect, useState } from "react";
import "../Courses/Courses.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LinearProgress from "@mui/material/LinearProgress";
import { axiosToken } from "../../../../services/api";
import Images from "../../../../constant/Images";
import ScheduleDialog from "../ScheduleDialog/ScheduleDialog";
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Tooltip } from "@mui/material";


export default function AssignmentDashboard({ date }) {
  const navigate = useNavigate();

  useEffect(() => {
    getAssignmentData("1");
  }, []);

  const [value, setValue] = React.useState("1");
  const [InprogressData, setProgressData] = useState(null);
  const [publishedData, setPublishedData] = useState(null);
  const [publishBefore, setPublishBefore] = useState(null);
  const [InprogressBefore, setInprogressBefore] = useState(null);
  const [pubShowView, setpubShowView] = useState(true);
  const [InProShowView, setInProShowView] = useState(true);
  const [InProghide, setInProgHide] = useState(true);
  const [hide, setHide] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getAssignmentData(newValue);
  };
  const getAssignmentData = (id) => {
    axiosToken
      .get(`tutor/tutorassignment?status_id=${id}&date=[${date}]`)
      .then((res) => {
      
          if(id == '0'){
           let ss = res?.data?.details?.map((d)=>{
              if(d.inprogress >= 75){
                d.color = '#a5cc3f'
              } else if(d.inprogress >= 51){
                d.color='#f88214'
              } else{
                d.color='#e22a4b'
              }
              })
              setProgressData(res.data.details)
          } else{
            let ss =  res?.data?.details?.map((d)=>{
              if(d.inprogress >= 75){
                d.color = '#a5cc3f'
              } else if(d.inprogress >= 51){
                d.color='#f88214'
              } else{
                d.color='#e22a4b'
              }
              })
              setPublishedData(res.data.details);
          }
        
        console.log(publishedData,'ssssss')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setPublishBefore(publishedData?.slice(0, 5))
    publishedData?.length >= 5 ? setHide(false) : setHide(true)
  }, [publishedData])
  useEffect(() => {
    setInprogressBefore(InprogressData?.slice(0, 5))
    InprogressData?.length >= 5 ? setInProgHide(false) : setInProgHide(true)
  }, [InprogressData])
  return (
    <>
      <div className="list_box courses">
        <TabContext value={value}>
          <div className="list_box_header">
            <h4>Assignments</h4>
            <TabList   TabIndicatorProps={{ style: { backgroundColor: "black", } }} onChange={handleChange}>
              <Tab label="Published" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} value="1" />
              <Tab label="Inprogress" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} value="0" />
            </TabList>
          </div>
          <div className="tab__body">
            <TabPanel value="0">
              <div className="tabs_table">
                {InprogressData ? (
                  InprogressBefore?.map((data, i) => (
                    <div className="table_list" key={i}>   
                      <div className="course__info">
                        <h4 style={{fontSize:'14px', textTransform: 'capitalize'}}>{data.course_name}</h4>
                        <h4 style={{ fontSize: '14px', color: '#757575', marginTop: '5px' }}>{data.assignmentduedate}</h4>
                      </div >
                    
                        <Tooltip title={data.blob_name} onClick={()=>{window.open(data.blob)}}>
                      <div className="file_name" >
                        <img src={require('../../../../assets/img/pdf_icon.png')} style={{width:'20px',height:'20px',margin:'5px'}} />
                        {data.blob_name.replace('assignments/tutor-assignments/', '')}
                      </div>
                      </Tooltip>
                      <div className="duration">
                        <span>{data.class} </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <h6>No data found</h6>
                )}
              </div>

              {!InProghide ?
              <div className="table__footer">
                {InProShowView ? <p className="view_all_btn" onClick={() => {
                  setInprogressBefore(InprogressData);
                  setInProShowView(false)
                }}>View All</p> :
                  <p className="view_all_btn" onClick={() => {
                    setInprogressBefore(InprogressData?.slice(0, 5))
                    setInProShowView(true)
                  }}>View Less</p>
                } 
              </div> : null}
            </TabPanel>
            <TabPanel value="1">
              {publishedData ? (
                publishBefore?.map((data, i) => (
                  <>
                    <div className="table_list" key={i}>
                
                      <div className="course__info">
                        <h4 style={{fontSize:'14px'}}>{data.course_name}</h4>
                        <h4 style={{ fontSize: '14px', color: '#757575', marginTop: '5px' }}>{data.assignmentduedate}</h4>
                      </div>
                  
                     <Tooltip title={data.blob_name} onClick={()=>{window.open(data.blob)}}>
                      <div className="file_name" >
                        <img src={require('../../../../assets/img/pdf_icon.png')} style={{width:'20px',height:'20px',margin:'5px'}} />
                        {data.blob_name.replace('assignments/tutor-assignments/', '')}
                      </div>
                      </Tooltip>
                      
                      <div className="progress_container" style={{fontSize:'12px'}}>
                                        <div className="progress_box">
                                        <span className="progress_bar" style={{ width: data.inprogress + '%',backgroundColor:data.color}}></span>    
                                        <span className="per_value" style={{float:'right',marginTop:'-23px'}}>{data.inprogress + '%'}</span>
                                    </div> 
                                    </div>
                      
                      <div className="progress">
                        <LinearProgress value={20} />
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <h6>No data found</h6>
              )}
                 {!hide ?
              <div className="table__footer">
                {pubShowView ? <p className="view_all_btn" onClick={() => {
                  setPublishBefore(publishedData);
                  setpubShowView(false)
                }}>View All</p> :
                  <p className="view_all_btn" onClick={() => {
                    setPublishBefore(publishedData?.slice(0, 5))
                    setpubShowView(true)
                  }}>View Less</p>
                }
              </div> : null}
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );
}