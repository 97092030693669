import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import Checkbox from '@mui/material/Checkbox';
import * as Yup from "yup";
import { axiosToken } from '../../../services/api';
import constant from '../../../constant/Validation';
import { toast } from "react-toastify";


export default function AddNewFaq({ openDialogProp, onClose,onCancel }) {
    const StudentSchema = Yup.object({
        question: Yup.string().required(constant.addFaq.question).trim(),
        answer: Yup.string().required(constant.addFaq.answer).trim(),

    })
    const [open, setOpen] = useState(false);
    const handleClose = () => { onClose(false); setOpen(false); };
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const initialValues = {
        question: '',
        answer: '',
        faq_type: '',
        web: true,
        mobile: true,
        check: null
    }

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New FAQ</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={StudentSchema}
                                onSubmit={(values) => {
                                    console.log(values, 'faq values')
                                    axiosToken.post('admin/faq', { detail: [values] })
                                        .then((res) => {
                                            toast.success(res.data.message, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                            handleClose(false)
                                        })
                                        .catch((err) => { console.log(err.response) })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-12 mb-3'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="question"
                                                                label="Question"
                                                                error={Boolean(touched.question && errors.question)}
                                                                helperText={touched.question && errors.question}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.question}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="answer"
                                                                label="Answer"
                                                                error={Boolean(touched.answer && errors.answer)}
                                                                helperText={touched.answer && errors.answer}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.answer}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <span>
                                                                        <Checkbox
                                                                            name='web'
                                                                            checked={values.web}
                                                                            value={values.web}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <h6>Web</h6>
                                                                    </span>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <span>
                                                                        <Checkbox
                                                                            checked={values.mobile}
                                                                            value={values.mobile}
                                                                            name='mobile'
                                                                            onChange={handleChange}
                                                                        />
                                                                        <h6>Mobile</h6>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                    >
                                                        <h6 onClick={() => { handleSubmit() }}>Add FAQ</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5">
                                                        <h6 onClick={() => { onCancel(false) }}>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
