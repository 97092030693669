import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { axiosToken } from '../../../services/api';
import StarRatings from 'react-star-ratings';
export default function FeedbackReview({ openDialogProp, onClose, courseid, studentid, }) {
  const [open, setOpen] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const handleClose = () => {
    onClose(false)
    setOpen(false);
  };
  useEffect(() => {
    console.log(courseid, studentid);
  }, [courseid, studentid])

  const initialValues = {
    course: '',
    feedback: '',
    star_rating: '',
    remarks: '',
    feedbacker: '',
    feedback_given_by: '',
    additional_info: ''
  }
  useEffect(() => {
    setOpen(openDialogProp)
    feedbackreviewstatus()
  }, [openDialogProp])

  const feedbackreviewstatus = () => {
    axiosToken.get("tutor/getstudentfeedback?course_id=" + courseid + "&student_id=" + studentid)
      .then((res) => {
        setFeedbackList(res.data)
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div >
      <Dialog
        open={open}
        keepMounted={false}
        aria-describedby="alert-dialog-slide-description"
        className='assignment__dialog rating__dialog'
      >
        <DialogTitle>Feedback </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            <div >
              Rating :
              <StarRatings
                rating={feedbackList.star_rating}
                numberOfStars={5}
                name='rating'
                starRatedColor='#e22a4b' />
            </div>
            <div>Remarks : {feedbackList.remarks} </div>
            <div>Feedback Given By : {feedbackList.feedback_given_by} </div>
            <DialogActions>
              <div className="gender_btn">
                <h6 onClick={() => { handleClose(false); }}>Close</h6>
              </div>
            </DialogActions>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
} 