import axios from "axios";
import { store } from "../store";
import { loading } from "../store/actions";
import { API_ENDPOINT } from "./api";

export const DownloadReports = (body,name) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  store.dispatch(loading(true));
  axios
    .post(`${API_ENDPOINT}admin/export_data`, body, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      store.dispatch(loading(false));
      var file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var fileURL = URL.createObjectURL(file);
      var a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank";
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
    })
    .catch((err) => {
      store.dispatch(loading(false));
      console.log(err);
    });
};
