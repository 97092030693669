import React from 'react';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import '../ManageBanners/ManageBanners.css'
import BannerList from './BannerList';

export default function ManageBanners() {
  return (
    <div>
        <Header />
        <Sidebar />
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <BannerList />
                </div>
            </div>
        </div>
    </div>
  )
}
