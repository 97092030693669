import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StarRatings from 'react-star-ratings';
import { TextField } from "@mui/material/";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Images from '../../../constant/Images';
import { axiosToken } from '../../../services/api';
import { setQuarter } from 'date-fns';

export default function FeedbackEdit({ openDialogProp, onClose, courseid, studentid, editData }) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editfeedback, setEditFeedback] = useState([]);
  const [rate, setRate] = useState(1);
 useEffect(()=>{console.log(editData,'editData')},[editData])

  const initialValues = {
    id: '',
    course_id: '',
    remarks: '',
    rating:rate
  }
  useEffect(() => {
    setEditFeedback(editData)
  }, [courseid, studentid,rate])

  useEffect(() => {
    setOpen(openDialogProp)
    setEditFeedback(editData)
     setTimeout(() => {
      initialValues.id = editfeedback?.id
      initialValues.course_id = editfeedback?.course
      initialValues.remarks = editfeedback?.remarks;
     }, 1000);
    setRate(editfeedback?.ratings)
    /* feedbackEdit() */
    /* handleClickOpen() */
    console.log(initialValues,'ss')
  }, [openDialogProp])

  const handleClose = () => {
    onClose(false)
    setOpen(false);
  };


  return (
    <div>
      <Dialog
        open={open}
        keepMounted={false}
        aria-describedby="alert-dialog-slide-description"
        className='assignment__dialog rating__dialog'
      >
        <DialogTitle>Please rate this student</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                axiosToken.put('/tutor/liststudentsubcribedinfo?course_id=' + courseid, values)
                  .then((res) => {
                    setTimeout(() => {
                      handleClose(false)
                    }, 500);
                    console.log(res);
                    toast.warning(res.data.detail, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  })
                  .catch((err) => {
                    toast.warning(err.data.detail, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    console.log(err)
                  })
              }}

            >

              {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                <>
                  <Form>
                    <><div >
                      <> <StarRatings className="mt-2"
                        //rating={rate}
                        rating={editData?.ratings}
                        //changeRating={(e) => { setRate(e) }}
                        numberOfStars={5}
                        name='ratings'
                        starRatedColor='#e22a4b'
                      />
                      <p style={{marginTop:'10px'}}>FeedBack: {editData?.remarks}</p>
                        {/* <TextField
                          className="fieldtext feedback__input mb-2"
                          type="text"
                          name="remarks"
                          label="Feedback"
                          multiline={true}
                          defaultValue={editData?.remarks} 
                          onBlur={handleBlur}
                          onChange={handleChange}
                         //value={values.remarks}   
                          variant="outlined" /></> */}
                        </>

                    </div>
                      <DialogActions>
                        {/* <div
                          className="gender_btn btn_active"
                        >
                          <h6 onClick={() => { handleSubmit(); }}>Yes</h6>
                        </div> */}
                        <div className="gender_btn">
                          <h6 onClick={() => { handleClose(false); }}>Close</h6>
                        </div>
                      </DialogActions></>
                  </Form>
                </>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div>
  )
}