import React, { useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import '../ManageBoard/ManageBoard.css'
import { useDispatch } from 'react-redux';
import { loading } from '../../../store/actions';
import ManageBoardListTable from './components/ManageBoardListTable';
export default function ManageBoard() {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(loading(false)) }, [])

  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid px-5'>
        <div className='row'>
          <div className='col-md-12'>
            <ManageBoardListTable />
          </div>
        </div>
      </div>
    </div>
  )
} 