import React, { useEffect, useState, useRef } from 'react'
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken, s3config } from '../../../services/api';
import { toast } from "react-toastify";
import S3 from "react-aws-s3";
import SelectForm from '../../../components/Forms/select/Select';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';



export default function ManageNotification({ openDialogProp, onClose, onCancel }) {
    const BannerSchema = Yup.object({
        type: Yup.string().required('Please Select Image Position'),
        course: Yup.string().required("Please choose a Image").trim(),
        grade:Yup.string().required(),
        content:Yup.string().required()
        /*  text_url: Yup.string().required('Please Enter the Link').nullable() */
    })
    
    const [course,setCourse] = useState([])
    const [grade,setGrade] = useState([]);
    useEffect(() => {
        axiosToken.get("course/getcoursebasicdetails").then((res) => {
            setGrade(res.data.grade)
        })
        axiosToken.get('admin/admin_notify').then((res)=>{
              console.log(res.data)
              setCourse(res?.data?.courses)
              setGrade(res?.data?.grades)
        })
    }, [])
    const initialValues = { type: '', course: '', grade: '',content:'' }
    function checkValidFile(inputID, exts, size) {
        if (inputID.size <= size) {
            return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
                inputID.name
            );
        }
    }
    let config = { ...s3config, dirName: "banner", };
    const positionData = [
        { id: 'parent', name: 'Parent' },
        { id: 'student', name: 'Student' },
        
    ]
    const ReactS3Client = new S3(config);
    const uploadFile = (file, name, setFieldValue, field_name) => {
        ReactS3Client.uploadFile(file, 'i2global-' +
            Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + '-' + name)
            .then((data) => { console.log(data); setFieldValue(field_name, data.key); })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Header />
            <Sidebar />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={BannerSchema}
                                onSubmit={(values) => {
                                    axiosToken.post('admin/admin_notify',values)
                                        .then((res) => {
                                            if (res) {
                                                toast.success(res.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            //console.log(err,'errrrrr')
                                            toast.error(err.response.data.detail, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                                    <>
                                        <div className="row no-gutters">
                                            {console.log(errors, 'errors banner', values)}
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <SelectForm
                                                                values={values.display_text}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                dorpdownValue={positionData}
                                                                error={Boolean(touched.display_text && errors.display_text)}
                                                                helperText={touched.display_text && errors.display_text}
                                                                label="Notification Type"
                                                                name="type"
                                                                dropwndownValueName="name"
                                                            />
                                                            {errors.type ? <span className='custom_err'>Please select notification type</span> : null}
                                                        </div>
                                                        <div className="col-md-6  position-relative banner">
                                                            
                                                        <SelectForm
                                                                values={values.display_text}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                dorpdownValue={course}
                                                                error={Boolean(touched.display_text && errors.display_text)}
                                                                helperText={touched.display_text && errors.display_text}
                                                                label="Select Course"
                                                                name="course"
                                                                dropwndownValueName="course_name"
                                                            />
                                                            {errors.course ? <span className='custom_err'>Please select course</span> : null}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-md-6'>
                                                        <SelectForm
                                                                values={values.display_text}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                dorpdownValue={grade}
                                                                error={Boolean(touched.display_text && errors.display_text)}
                                                                helperText={touched.display_text && errors.display_text}
                                                                label="Select Grade"
                                                                name="grade"
                                                                dropwndownValueName="grade_name"
                                                            />
                                                            {errors.grade ? <span className='custom_err'>Please select grade</span> : null}
                                                            
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="content"
                                                                label="Notification Content"
                                                                /*  error={Boolean(touched.text_url && errors.text_url)}
                                                                 helperText={touched.text_url && errors.text_url} */
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.text_url}
                                                                variant="outlined"
                                                            />
                                                             {errors.content ? <span className='custom_err'>Please enter some content</span> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='btn_container' style={{display:'flex',paddingLeft:25}}>
                                                <div className="gender_btn btn_active mt-5" onClick={() => { handleSubmit() }} style={{ maxWidth: '150px' }}>
                                                    <h6 >Send </h6>
                                                </div>
                                                <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                    <h6 >Cancel</h6>
                                                </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}
