import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../services/api';
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import '../ManageCourses/ManageCourses.css'
import constant from '../../../constant/Validation';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, IconButton } from '@mui/material';
import { dateFormat } from '../../../helpers/utils';
import ScheduleDialog from '../../Dashboard/components/ScheduleDialog/ScheduleDialog';

export default function ManageCourseList() {

    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const searchRef = useRef();
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [status, setStatus] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const checkLocation = location.pathname === '/admin/manage-courses/pending' ? true : false;

    const handleClose = () => { setShowDialog(false); setOpen(false); setEdit(false); }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`admin/listcourseviastatus?name=&status=${status}&page=${newPage}&item=${rowPerPage}`)
            .then((res) => { setTotal(res.data.total_count); setRows(res.data.data) })
            .catch((err) => { console.log(err) })
    };
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`admin/listcourseviastatus?name=&status=${status}&page=${currentPage}&item=${event.target.value}`)
            .then((res) => { setTotal(res.data.total_count); setRows(res.data.data) })
            .catch((err) => { console.log(err) })
    };
    const getCourseList = () => {
        axiosToken.get(`admin/listcourseviastatus?name=&status=${status}&page=${currentPage}&item=${rowPerPage}`)
            .then((res) => { setTotal(res.data.total_count); setRows(res.data.data) })
            .catch((err) => { console.log(err) })
    }

    const approveCourse = (id) => {
        axiosToken.post('admin/courseapprove', { course: [id] })
            .then((res) => {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate('/admin/manage-courses/')
            })
            .catch((err) => { console.log(err) })
    }

    const getSingleCourseData = (id) => {
        axiosToken.get('/tutor/updatecoursedetails?course_id=' + id)
            .then((res) => {
                console.log(res.data)
                setTimeout(() => {
                    navigate('/edit-course', { state: { data: res.data } })
                }, 1000);
            })
            .catch((err) => { console.log(err.response) })
    }
    useEffect(() => {
        console.log(checkLocation)
        if (checkLocation) {
            setStatus(0)
        } else {
            setStatus(1)
        }
    }, [location.pathname])
    useEffect(() => {
        if (checkLocation) {
            setStatus(0)
        } else {
            setStatus(1)
        }

        getCourseList();

    }, []);
    useEffect(() => { getCourseList() }, [add_std, status])

    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [edit_std, setEdit_std] = useState(false);

    useEffect(() => { console.log(edit_std, 'edit') }, [edit_std])
    const searchCourse = (val) => {
        axiosToken.get(`admin/listcourseviastatus?name=${val}&status=${status}&page=${currentPage}&item=${rowPerPage}`)
            .then((res) => { setTotal(res.data.total_count); setRows(res.data.data) })
            .catch((err) => { console.log(err) })
    }

    return (
        <>
            <Dialog
                open={showDialog}
                fullWidth={'md'}
                maxWidth={'md'}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Course Intro Video</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span onClick={() => { setShowDialog(false) }}><CloseIcon className='video_close' /></span>
                        <div className="video_container">
                            {/* <video controls={true} src={constant.common.bidBucket + videoUrl} /> */}
                            <video controls={true} src={videoUrl} />
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>

            <div className='student_table_container' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Courses
                        </li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { navigate('/admin/manage-courses/create-course') }}
                >
                    <h6>Add New Course</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Courses ({total}) </h6>
                    </div>
                    <div className='right__side student_search course_cont'>
                        <h6 className={status == 1 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate('/admin/manage-courses/'); searchRef.current.reset() }}>Approved</h6>
                        <h6 className={status == 0 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate('/admin/manage-courses/pending'); searchRef.current.reset() }}>Pending</h6>
                        <form ref={searchRef}>
                            <TextField
                                className="fieldtext"
                                type="text"
                                name="search"
                                label="Search"
                                variant="outlined"

                                onChange={(e) => { searchCourse(e.target.value) }}
                            />
                        </form>
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>Tags</TableCell>
                                <TableCell>Tutor</TableCell>
                                <TableCell className='class_des'>Description</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Course Image</TableCell>
                                <TableCell>Course Intro</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"> {row.course_name}</TableCell>
                                    <TableCell>{row?.interest}</TableCell>
                                    <TableCell>{row?.tutor_name}</TableCell>
                                    <TableCell className='class_des'>{row?.description}</TableCell>
                                    <TableCell>{dateFormat(row?.created_at)}</TableCell>
                                    <TableCell>{row?.course_grade}</TableCell>
                                    <TableCell><img className='course_img' src={row?.course_image} /></TableCell>
                                    <TableCell>
                                        <Tooltip title="Course Intro"
                                            onClick={() => { setVideoUrl(row?.course_intro); setShowDialog(true) }}
                                        >
                                            <LiveTvIcon style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>

                                        <ScheduleDialog data={row} />
                                        {status == 0 ? <Tooltip title="Approve This Course"
                                            onClick={() => { navigate('/admin/manage-courses/'); approveCourse(row?.id); }}
                                        >
                                            <CheckIcon style={{ cursor: 'pointer' }} />
                                        </Tooltip> : null}
                                        <Tooltip title="Edit Course" arrow>
                                            <IconButton><EditIcon onClick={() => {
                                                getSingleCourseData(row.id)
                                            }} /></IconButton></Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
