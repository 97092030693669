import React, { useEffect, useState,useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../services/api';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';

export default function AssignmentListTable() {
    const searchRef = useRef();
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const navigate = useNavigate();
    useEffect(() => { }, [add_std])
    useEffect(() => { getAssignmentData(); }, [])

    /*  const getAssignmentData =() =>{
         axiosToken.get(`admin/add_assignment?page=${currentPage}&item=${rowPerPage}`)
         .then((res)=>{console.log(res)})
         .catch((err)=>{console.log(err)})
     } */


    const handleChangePage = (event, newPage) => {
        console.log(newPage, 'pagess')
        setCurrentPage(newPage)
        axiosToken.get(`admin/add_assignment?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getAssignmentData = () => {
        axiosToken.get(`admin/add_assignment?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/add_assignment?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const dateFormat = (date) => {
        const d = new Date(date);
        return d.getDate() + '-' + d.toString().substr(4, 3) + '-' + d.getFullYear();
    }
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('admin/add_assignment?id=' + id + '&status=' + value)
            .then((res) => {
                if(res.data.status){
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => { console.log(err) })
    }
    /*  useEffect(() => { getStudentData() }, []) */
    const approveAssignment = (id) => {
        axiosToken.post('admin/approveassignment', { course: [id] })
            .then((res) => {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getAssignmentData();
            })
            .catch((err) => { console.log(err) })
    }

   /*  const searchCourse =(val) =>{
        axiosToken.get(`admin/listcourseviastatus?name=${val}&status=${status}&page=${currentPage}&item=${rowPerPage}`)
        .then((res) => { setTotal(res.data.total_count); setRows(res.data.data) })
        .catch((err) => { console.log(err) })
    } */

    return (
        <>

            <div className='student_table_container manage_assignment' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Assignments
                        </li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { navigate('/admin/manage-assignments/create-assignment') }}
                >
                    <h6>Add New Assignment</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Assignments ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                   {/*  <h6 className={status == 1 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate('/admin/manage-assignments/');searchRef.current.reset() }}>Approved</h6>
                        <h6 className={status == 0 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate ('/admin/manage-assignments/pending');searchRef.current.reset() }}>Pending</h6> */}
                         <form  ref={searchRef}>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                            //onChange={(e)=>{searchCourse(e.target.value)}}
                        /></form>
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>Due Date On</TableCell>
                                <TableCell>Tutor</TableCell>
                                {/* <TableCell>Course Enrolled</TableCell> */}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.course_name}
                                    </TableCell>
                                    <TableCell>{row?.class}</TableCell>
                                    <TableCell>{row?.assignment_score}</TableCell>
                                    <TableCell>{dateFormat(row?.created_at)}</TableCell>
                                    <TableCell>{row?.assignment_due_date}</TableCell>
                                    <TableCell>{row?.tutor_name}</TableCell>
                                    {/* <TableCell>ss</TableCell> */}
                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />

                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                            <Tooltip title="Edit Assignment" arrow>
                                                <IconButton><EditIcon
                                                    onClick={() => {
                                                     
                                                        axiosToken.get('tutor/updateassignment?id=' + row.id)
                                                            .then((res) => {
                                                                navigate('/edit-assignemnt', { state: { data: res.data[0] } })
                                                            })
                                                            .catch((err)=>{
                                                                toast.error(err.response.data.detail, {
                                                                    position: toast.POSITION.TOP_RIGHT,
                                                                });
                                                            })
                                                  /*   setEditData(row.id)
                                                    setEdit_std(true) */}} /></IconButton></Tooltip>
                                            </span>


                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
