import React,{useState,useEffect} from 'react'
import { axiosToken } from '../../../services/api'
import { useParams } from "react-router-dom";
export default function CountBox() {
    const [count,setCount] = useState('');
    const [courseid,setCourseid] = useState();
    const { id } = useParams();
    useEffect(() => {
        console.log(id, "ids");
      }, []);

    useEffect(()=>{
        axiosToken.get('tutor/tutordetailcount?course_id=' + id)
        .then((res)=>{
            setCount(res.data)
            console.log(res);
        }).catch((err)=>{
            console.log(err)
        })
    },[])

    return (
        <div className='count_box_container'>
            <div className='count_box'>
                <img src={require('../../../assets/icons/total_students.png')} />
                <h5>{count?.mystudents}</h5>
                <p>My Students</p>
            </div>
            <div className='count_box'>
            <img src={require('../../../assets/icons/feedback_given.png')} />
                <h5>{count?.feedback_given_count}</h5>
                <p>Feedback Given</p>
            </div>
            <div className='count_box'>
            <img src={require('../../../assets/icons/feedback_received.png')} />
                <h5>{count?.feedback_received_count}</h5>
                <p>Feedback Received </p>
            </div>
            <div className='count_box'>
            <img src={require('../../../assets/icons/assements.png')} />
                <h5>{count?.assessments}</h5>
                <p>Assessment</p>
            </div>
        </div>
    )
}
