import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import '../../AdminPages/ManageTeachers/ManageTeachers.css';
import ManageTeacherListTable from './components/ManageTeacherListTable';


export default function ManageTeachersAdmin() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <ManageTeacherListTable />
          </div>
        </div>
      </div>
    </div>
  )
}
