 import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from "@mui/material/";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function EditGrade({ openDialogProp, onClose,editData,onCancel }) {
    const StudentSchema  = Yup.object({
         grade_name:Yup.string().required(constant.addGrades.grade_name).trim()
    })

    const initialValues = {
        id:'',
        grade_name: '',
        student_grade:'',
        is_added_by:''
    } 
    const [open, setOpen] = useState(false);
   
    const [gradeData, setGradeData] = useState()
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => { 
        setOpen(openDialogProp);
        setGradeData(editData) 
        initialValues.id = gradeData?.id
        initialValues.grade_name = gradeData?.grade_name
        initialValues.student_grade = gradeData?.student_grade
        initialValues.is_added_by = gradeData?.is_added_by
       
    }, [openDialogProp,gradeData]) 

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Edit Grade</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={StudentSchema}
                                onSubmit={(values) => {
                                   axiosToken.put('/course/create_grade',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                      console.log(err,'errrrrr')
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                                 {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                 <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="grade_name"
                                                                label="Grade"
                                                                error={Boolean(touched.grade_name && errors.grade_name)}
                                                                helperText={touched.grade_name && errors.grade_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.grade_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    
                                                    </div>
                                                    </div> 
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => {handleSubmit()}}
                                                    >
                                                        <h6 >Update Grade</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5"  onClick={() => { onCancel(false) }}>
                                                        <h6>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )

    
}