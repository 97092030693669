import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import Checkbox from '@mui/material/Checkbox';
import * as Yup from "yup";
import { axiosToken } from '../../../services/api';
import constant from '../../../constant/Validation';
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";



export default function AddNewCms({ openDialogProp, onClose, onCancel }) {
  

    const StudentSchema = Yup.object({
        cms_key: Yup.string().required(constant.addCms.key).trim(),
        cms_value: Yup.string().required(constant.addCms.value),

    })
    const [open, setOpen] = useState(false);
    const handleClose = () => { onClose(false); setOpen(false); };
  
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const initialValues = {
        cms_key: '',
        cms_value: '',

    }
    const dorpdownValue =[
        {id:'about_us',values:'About us'},
       { id:'support_web',values:'Support Web'},
        {id:'terms_web',values:'Terms and Condtions'}
    ]
    const editorConfiguration = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                '|',
                'alignment',
                'outdent',
                'indent',
            ]
        }
    };

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New CMS</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={StudentSchema}
                                onSubmit={(values) => {
                                    axiosToken.post('/admin/cms_details_api', values)
                                        .then((res) => {
                                            toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT,});
                                            handleClose(false)
                                        })
                                        .catch((err) => { console.log(err.response) })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                  
                                                        <div className='col-md-6 mb-3'>
                                                        <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select 
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='cms_key'
                                                                    label="Select "
                                                                    onChange={handleChange}
                                                                   /*  error={Boolean(touched.grade && errors.grade)}
                                                                    helperText={touched.grade && errors.grade} */
                                                                    onBlur={handleBlur}
                                                                    value={values.cms_key}

                                                                >
                                                                    {dorpdownValue && dorpdownValue.map((e, key) => {
                                                                        return (
                                                                           <MenuItem key={key} value={e.id} >{e.values}</MenuItem>
                                                                        );
                                                                    })} 
                                                                </Select>
                                                            </FormControl>
                                                           
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data="<p>Hello</p>"
                                                                onBlur={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setFieldValue("cms_value", data);
                                                                }}
                                                            />
                                                           {/*  {errors.cms_value && touched.cms_value ? (
                                                                <Alert variant="filled" severity="error"><p>{errors.cms_value}</p></Alert>
                                                            ) : null} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit();}}
                                                    >
                                                        <h6>Add CMS</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5"
                                                    onClick={() => { onCancel(false);}}
                                                    >
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
