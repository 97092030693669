

const intialState = {
  loading: false,
  loggedIn: false,
  error: false,
  data: "",
  userInfo:[],
  assignmentReload : false,
  isSidebarOpen:false,
  isMastermenuOpen:false,
  userRole:2,
  profileData:[]

};

export default  (state = intialState, action) => {
  switch (action.type) {
      case 'ASSIGNMENT_RELOAD':
        return{
          ...state,
          assignmentReload:action.payload
        }
      case 'LOADING':
        return{...state,loading:action.payload}
      case 'SIDEBAR':
        return {...state,isSidebarOpen:action.payload}
      case 'MASTER_MENU':
        return {...state,isMastermenuOpen:action.payload}
      case 'USER_ROLE':
        return {...state,userRole:action.payload}
      case 'USERPROFILE':
        return {...state,profileData:action.payload}
    default:
      return state;
  }
};
