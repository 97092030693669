
export const reloadAssignmentData =(status) =>{
  return{
    type:'ASSIGNMENT_RELOAD',
    payload:status
  }
}
export const loading =(status) =>{
  return{
    type:'LOADING',
    payload:status
  }
}
export const toggleSidebar =(value) =>{
  return{
    type:'SIDEBAR',
    payload:value
  }
}
export const toggleMaster = (value)=>{
  return{
    type:'MASTER_MENU',
    payload:value
  }
}
export const updateUserRole = (role)=>{
  return{
    type:'USER_ROLE',
    payload:role
  }
}
export const userProfile = (data)=>{
  return{
    type:'USERPROFILE',
    payload:data
  }
}