import axios from "axios";
import { store } from "../store";
import { loading } from "../store/actions";

export const API_ENDPOINT = process.env.REACT_APP_BASER_URL;

export const s3config = {
  bucketName: process.env.REACT_APP_S3_BUCKETNAME,
  region: process.env.REACT_APP_S3_REGION,  
  accessKeyId: process.env.REACT_APP_S3_ACCESSKEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRETKEY,
};

export const axiosToken = axios.create({
  baseURL: API_ENDPOINT,
});
axiosToken.interceptors.request.use(store.dispatch(loading(true)))
axiosToken.interceptors.request.use(
  (config) => {
    store.dispatch(loading(false))
    const token = JSON.parse(localStorage.getItem("user")).token;
    if (token) {
      config.headers.authorization = `Bearer ` + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosToken.interceptors.response.use(

  (response) => {
    store.dispatch(loading(true))
    setTimeout(() => {
      store.dispatch(loading(false))
    }, 1000);
    return response;
   
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }

    return Promise.reject(error);
   
  }

);
export const apiCall = axios.create({
  baseURL: API_ENDPOINT,
});
