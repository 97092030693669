import React, { useState, useEffect, useRef } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert, } from "@mui/material";
import Header from "../../components/Header/Header";
import Images from "../../constant/Images";
import { loading } from "../../store/actions";
import { useDispatch } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import SelectForm from "../../components/Forms/select/Select";
import { toast } from "react-toastify";
import constant from "../../constant/Validation";
import * as Yup from "yup";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Accordion, AccordionDetails, AccordionSummary, Typography, } from "@mui/material";
import { axiosToken, s3config } from "../../services/api";
import S3 from "react-aws-s3";
import { Formik, Form, Field, FieldArray } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import "../TutorProfile/TutorProfile.css";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from '../../components/Sidebar/Sidebar';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Footer from "../../components/Footer/Footer";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const TutorProfileSchema = Yup.object().shape({
  // mail: Yup.string().required(constant.TutorProfile.mailErrMsg).trim(),
  dob: Yup.string().required(constant.ProfileRegistration.dobErrMsg).nullable(),
  doorno: Yup.string().required(constant.TutorProfile.doorErrMsg).nullable(),
  address_1: Yup.string().required("Please enter address").nullable()/* .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ") */,
  city: Yup.string().required("Please select city").nullable(),
  country: Yup.string().required("Please select country").nullable(),
  name: Yup.string().required("Please Enter the Name").trim(),
  area: Yup.string().required("Please Enter the Area").nullable()
  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  pincode: Yup.string()
  .required().nullable()
  .min(6, 'Pincode allows upto 6 digits')
  .max(6, 'Pincode allows upto 6 digits'),
  /* Yup.string().required(constant.ProfileRegistration.pincodeErrMsg)
  .matches(/^[1-9][0-9]{5}$/,"Pincode allows only numbers"), */
  info: Yup.string().required("Please Enter the About Yourself").nullable(),
  
  //profile_pic: Yup.string().required(constant.TutorProfile.picsizeErrMsg),
  workexperience: Yup.array(
    Yup.object().shape({
      tutor_role: Yup.string().required(constant.TutorProfile.roleErrMsg).nullable(),
      organization: Yup.string().required(
        constant.TutorProfile.organizationErrMsg
      ).nullable().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      /*  from_date: Yup.date().required("Please enter from date"),
       to_date: Yup.date().required("Please enter to date"), */
    })
  ),

  educationdetails: Yup.array(
    Yup.object().shape({
      degree: Yup.string().required(constant.TutorProfile.degreeErrMsg),
      grade: Yup.string().required(constant.TutorProfile.gradeErrMsg),
      major: Yup.string().required(constant.TutorProfile.majorErrMsg),
      college_name: Yup.string().nullable().required(constant.TutorProfile.collegeErrMsg)
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      /*  from_date: Yup.date().required("Please enter from date"),
       to_date: Yup.date().required("Please enter to date"), */
    })
  ),
  /* accomplishment: Yup.array(
    Yup.object().shape({
      name: Yup.string().required(constant.ProfileRegistration.accErrMsg)
        .trim()
        .matches(/^[aA-zZ\s]+$/, constant.Register.validatenumErrMsg),
      certificate: Yup.string().required(constant.ProfileRegistration.certificateErrMsg)
    })
  ), */
});

export default function TutorProfile() {
 
  const navigate = useNavigate();
  const upload = useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  const [city, getCity] = useState([]);
  const [country, getCountry] = useState([]);
  const [degree, getDegree] = useState([]);
  const [major, getMajor] = useState([]);
  const [roles, getRoles] = useState([]);
  const [edit, setEdit] = React.useState(false);
  const { state } = useLocation();
  const { data } = state || {};
  const editValue = (editData) => { return setEdit(editData); };
  const accomplishmentsImg = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [detect,setDetect] = useState(new Date());
  const [showDialog, setShowDialog] = useState(false);
  const handleClose = () => { setShowDialog(false); }
  let initialValues = {
    email: data?.email,
    mobile_no: "",
    gender: data?.gender,
    profile_pic: data?.profile_pic,
    doorno: data?.doorno,
    address_1: data?.address_1,
    address_2: data?.address_2,
    area: data?.area,
    city: data?.city,
    country: data?.country,
    pincode: data?.pincode,
    dob: data?.dob,
    id: data?.id,
    workexperience: data?.workexperience,
    educationdetails: data?.educationdetails,
    certification: data?.certification,
    accomplishment: data?.accomplishment,
    from_date: data?.from_date,
    to_date: data?.to_date,
    /* intro: data?.intro, */
    info: data?.info,
    name: data?.name

  };
  useEffect(() => {
    console.log(data)
  }, [])
  const getAllValues = async () => {
    axiosToken.get("tutor/tutor_update_details").then((response) => {
      getCity(response.data.city);
      getCountry(response.data.country);
      getDegree(response.data.degree);
      getMajor(response.data.major);
      getRoles(response.data.role);
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false));
    getAllValues();
    console.log(data, 'tttt')
  }, []);
  useEffect(() => {
    //alert('ok')
  }, [detect])

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }
  let config = { ...s3config, dirName: "tutor-profile", };
  const ReactS3Client = new S3(config);
  const uploadFile = (file, name, setFieldValue, field_name) => {
    ReactS3Client.uploadFile(
      file, "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name
    )
      .then((data) => {
        setFieldValue(field_name, data.key);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Dialog
        open={showDialog}
        fullWidth={'md'}
        maxWidth={'md'}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className='assignment__dialog add_new_std'
      >
        <DialogTitle> Intro Video</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span onClick={() => { setShowDialog(false) }}><CloseIcon className='video_close' /></span>
            <div className="video_container">
              {data?.intro_video ? <video controls={true} src={constant.common.bidBucket + data?.intro_video} /> : null}
              {/*  <video controls={true} src={videoUrl} />  */}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Header />
      {/*   <Sidebar /> */}
      <Formik
        enableReinitialize={true}
        validationSchema={TutorProfileSchema}
        initialValues={initialValues}
        //dirty={true}
        onSubmit={(values) => {
          console.log(values);
          axiosToken.post('/tutor/profileupdate', values)
            .then((res) => {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              axiosToken.get('/tutor/gettutordetails')
                .then((res) => {
                  console.log(res.data)
                  navigate('/profile', { state: { data: res.data } })
                  //window.location.reload();
                })
                .catch((err) => { console.log(err.response) })
            })
            .catch((err) => {
              toast.warning(err.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });

              console.log(err.response)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          values,
          touched,
          setFieldValue,
          setValue,
        }) => (
          <Form>
            <div
              className="container-fluid pt-5 px-5"
              id="dashboardds__container"
            >
              <div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div
                      className="card tutor__profile"
                      style={{ borderRadius: "8px" }}
                    >
                      <div className="tutor_prof_pic">
                        {initialValues?.profile_pic ? <img src={constant.common.bidBucket + initialValues?.profile_pic} id="profile" className="user_img_tutor__profile" />
                          : <img src={Images.RoundImg} id="profile" className="user_img_tutor__profile" />}

                        <h6 onClick={() => { upload.current.click(); }} className="profile__cameraicon_tutor">
                          <PhotoCameraIcon />
                        </h6>
                        <input type="file" accept="image/*" id="file" ref={upload} style={{ display: "none" }}
                          onChange={(e) => {
                            if (
                              checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)
                            ) {
                              //setFieldValue( "profile_pic", e.target.files[0].name);
                              uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "profile_pic");
                              var output = document.getElementById("profile");
                              output.src = URL.createObjectURL(e.target.files[0]);
                              output.onload = function () { URL.revokeObjectURL(output.src); };
                            } else {
                              toast.error("Please Select Valid Image. or Check image Size", { position: toast.POSITION.TOP_RIGHT, });
                            }
                          }}
                        />
                      </div>
                      {data?.intro_video ?
                        <div className="user__info_tutor_profile">
                          <Tooltip title="Profile Intro"
                            onClick={() => { setVideoUrl(data?.intro_video); setShowDialog(true) }}
                          >
                            <LiveTvIcon style={{ cursor: 'pointer' }} />
                          </Tooltip></div> : null}
                      <h4 className="title_tutor_profile" style={{textTransform:'capitalize'}}>{values?.name}</h4>
                      <h6 className="title_tutor_profile">{initialValues?.email}</h6>

                      <h6 className="graduate__name_detials  mt-1">
                        <p>{data?.info}</p>
                        {/*  <p>{data?.intro}</p> */}
                      </h6>

                      <div className="submit_btn_container btn___update">
                        <div className="gender_btn btn_active"  >
                          <h6 onClick={() => { handleSubmit(); console.log(errors) }}>Update</h6>
                        </div>
                        <div className="gender_btn" onClick={() => { navigate('/') }}>
                          <h6>Cancel</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="err_container">
                      {errors && (
                        <FormHelperText error>
                          {errors.dob && touched.dob ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.dob}</p>
                            </Alert>
                          ) : null}
                          {errors.doorno && touched.doorno ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.doorno}</p>
                            </Alert>
                          ) : null}
                          {errors.info && touched.info ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.info}</p>
                            </Alert>
                          ) : null}
                          {errors.address_1 && touched.address_1 ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.address_1}</p>
                            </Alert>
                          ) : null}

                          {errors.name && touched.name ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.name}</p>
                            </Alert>
                          ) : null}
                          {errors.city && touched.city ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.city}</p>
                            </Alert>
                          ) : null}
                          {errors.area && touched.area ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.area}</p>
                            </Alert>
                          ) : null}

                          {errors.pincode && touched.pincode ? (
                            <Alert variant="filled" severity="error">
                              <p>{errors.pincode}</p>
                            </Alert>
                          ) : null}

                          {errors.workexperience && touched.workexperience
                            ? errors.workexperience?.map((data, i) => (
                              <>
                                {errors.workexperience[i]?.tutor_role ?
                                  <Alert variant="filled" severity="error">
                                    <p>{errors.workexperience[i]?.tutor_role}</p>
                                  </Alert> : null}
                                {errors.workexperience[i]?.organization ? <Alert variant="filled" severity="error">
                                  <p>{errors.workexperience[i]?.organization}</p>
                                </Alert> : null}
                                {/*  {errors.workexperience[i]?.from_date ? <Alert variant="filled" severity="error">
                                  <p>{errors.workexperience[i]?.from_date} </p>
                                </Alert> : null}
                                {errors.workexperience[i]?.to_date ? <Alert variant="filled" severity="error">
                                  <p>{errors.workexperience[i]?.to_date} </p>
                                </Alert> : null} */}
                              </>
                            ))
                            : null}

                          {errors.educationdetails && touched.educationdetails
                            ? errors.educationdetails?.map((data, i) => (
                              <>
                                {errors.educationdetails[i]?.degree ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].degree}</p>
                                </Alert> : null}
                                {errors.educationdetails[i]?.major ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].major}</p>
                                </Alert> : null}
                                {errors.educationdetails[i]?.grade ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].grade}</p>
                                </Alert> : null}
                                {errors.educationdetails[i]?.college_name ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].college_name}</p>
                                </Alert> : null}
                                {/* {errors.educationdetails[i]?.from_date ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].from_date}</p>
                                </Alert> : null}
                                {errors.educationdetails[i]?.to_date ? <Alert variant="filled" severity="error">
                                  <p>{errors.educationdetails[i].to_date}</p>
                                </Alert> : null} */}
                              </>
                            ))
                            : null}

                          {/* {errors.accomplishment && touched.accomplishment
                            ? errors.accomplishment?.map((data, i) => (
                              <>
                                 <Alert variant="filled" severity="error"><p>{errors.accomplishment[i]?.name}</p></Alert> 
                                <Alert variant="filled" severity="error"><p>{errors.accomplishment[i]?.certificate}</p></Alert>
                              </>
                            ))
                            : null}  */}
                        </FormHelperText>
                      )}
                    </div>
                    <div>
                      <Accordion
                        style={{ boxShadow: "none" }}
                        expanded={expanded === "panel1"}
                        onChange={handleChangeExpand("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <h6>Personal Details</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">

                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Name"
                                  variant="outlined"
                                  name="name"
                                  defaultValue={values.name}
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                />
                              </div>

                              <div className="mb-3">
                                <div className="form_tutor_fieldex position-relative">
                                  <LocalizationProvider dateAdapter={DateAdapter}>
                                    <DesktopDatePicker
                                      error={Boolean(touched.dob && errors.dob)}
                                      helperText={touched.dob && errors.dob}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        setFieldValue("dob", formatDate(e));
                                      }}
                                      label="DOB"  
                                      value={values.dob}
                                      maxDate={new Date()}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>


                              </div>
                              <div className="mb-3">

                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Door No"
                                  variant="outlined"
                                  name="doorno"
                                  defaultValue={values.doorno}
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                />
                              </div>

                              <div className="mb-3">
                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Address Line 1"
                                  variant="outlined"
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                  name="address_1"
                                  defaultValue={values.address_1}
                                />
                              </div>

                              <div className="mb-3">
                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Area"
                                  variant="outlined"
                                  name="area"
                                  defaultValue={values.area}
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                />
                              </div>

                              <div className="mb-3">
                                <div className=" form_tutor_field position-relative">
                                  <FormControl fullWidth>
                                    <InputLabel id="city-label">  Country</InputLabel>
                                    <Field as={Select} id="city" name="country" label="country"
                                      defaultValue={values.country}
                                      onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                    >
                                      {country.map((e, key) => {
                                        return (<MenuItem key={key} value={e.id}>{e.country_name}</MenuItem>);
                                      })}
                                    </Field>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">

                              <div className="mb-3">
                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Email"
                                  variant="outlined"
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                  name="email"
                                  disabled
                                  defaultValue={values.email}
                                />

                              </div>
                              <div className="mb-3">
                    

                              {/*   <div className="about_textarea_assign form_field position-relative"> */}
                              <div className="about_textarea_assignss form_fieldes  position-relative">
                                  <TextField name="info" defaultValue={values.info} /* style={{height:'84px'}} */
                                     id="outlined-multiline-static"  rows={4} label="About Yourself"
                                    variant="outlined" multiline
                                    onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                  />
                                </div>

                              </div>
                              <div className="mb-3">
                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Address Line 2"
                                  variant="outlined"
                                  name="address_2"
                                  defaultValue={values.address_2}
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                />
                              </div>


                              <div className="mb-3">
                                <div className=" form_tutor_field position-relative">
                                  <FormControl fullWidth>
                                    <InputLabel id="city-label">  City</InputLabel>
                                    <Field as={Select} id="city" name="city" label="City"
                                      defaultValue={values.city}
                                      onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                    >
                                      {city.map((e, key) => {
                                        return (<MenuItem key={key} value={e.id}>{e.city_name}</MenuItem>);
                                      })}
                                    </Field>
                                  </FormControl>
                                </div>

                              </div>
                              <div className="mb-3">
                                <TextField
                                  className="form_field position-relative"
                                  id="outlined-basic"
                                  label="Pincode"
                                  variant="outlined"
                                  name="pincode"
                                  defaultValue={values.pincode}
                                  onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                />

                              </div>
                            </div>

                            <div className="col-md-12">

                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="mt-4">
                      <Accordion
                        style={{ boxShadow: "none" }}
                        expanded={expanded === "panel2"}
                        onChange={handleChangeExpand("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <h6 sx={{ width: "33%", flexShrink: 0 }}> Work Experience</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="row">

                            <FieldArray name="workexperience">
                              {({ push, remove }) => (
                                <>
                                  {values.workexperience?.map((_, index) => (
                                    <>
                                      <div className="col-md-6">
                                        <div className="tut_exp mb-3" key={index} >
                                          <div className="mb-3 mt-3" >
                                            <div className="form_tutor_field position-relative">
                                              <FormControl fullWidth>
                                                <InputLabel id="city-label"> Select role </InputLabel>
                                                <Field as={Select} labelId="city-label" id="ciry-select"
                                                  name={`workexperience[${index}].tutor_role`}
                                                  onChange={handleChange}
                                                  label="Select Role"
                                                  defaultValue={values.workexperience[index].tutor_role}
                                                >
                                                  {roles.map((e, key) => {
                                                    return (
                                                      <MenuItem key={key} value={e.id} >{e.role_name}</MenuItem>
                                                    );
                                                  })}
                                                </Field>
                                              </FormControl>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <div className=" form_tutor_field position-relative">
                                            <TextField
                                              onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                              id="outlined-basic"
                                              label="Enter Organization / Institute Name"
                                              variant="outlined"
                                              name={`workexperience[${index}].organization`}
                                              defaultValue={values.workexperience[index].organization}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <div className="row g-0 two_div_row">
                                            <div className="col-md-6 address_field">
                                              <div className="dob_field form_tutor_field">
                                                <LocalizationProvider dateAdapter={DateAdapter}>
                                                  <DesktopDatePicker label="From"
                                                    value={values.workexperience[
                                                      index
                                                    ].from_date}
                                                    onBlur={handleBlur}
                                                    maxDate ={new Date()} 
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `workexperience[${index}].from_date`,
                                                        formatDate(e)
                                                      );
                                                    }}
                                                    renderInput={(
                                                      params
                                                    ) => (
                                                      <TextField {...params} />)}
                                                  />
                                                </LocalizationProvider>
                                              </div>
                                            </div>
                                            <div className="col-md-6 address_field">
                                              <div className="dob_field form_tutor_field">
                                                <LocalizationProvider dateAdapter={DateAdapter}>
                                                  <DesktopDatePicker
                                                    label="To"
                                                    value={
                                                      values.workexperience
                                                      [index].to_date}
                                                    onBlur={handleBlur}
                                                    maxDate={new Date()}
                                                    onChange={(e) => {
                                                      setFieldValue(`workexperience[${index}].to_date`, formatDate(e));
                                                    }}
                                                    renderInput={(params) => (<TextField {...params} />)}
                                                  />
                                                </LocalizationProvider>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="add_new_contianers mb-4">

                                        {
                                          values.workexperience.length >= 2 && index != 0 ? (
                                            <img style={{ float: 'right', marginRight: '31px', marginLeft: '-37px' }}
                                              src={Images.Minus}
                                              onClick={() => {
                                                values.workexperience.splice(index, 1);
                                                console.log(values.workexperience, 'sssss')
                                                setDetect(new Date());
                                                //remove({ tutor_role: "", organization: "", from_date: new Date(), to_date: new Date(), });
                                              }}
                                              alt="imgg"
                                              className="add_icon"
                                            />
                                          ) : null
                                        }

                                        {index ===
                                          values.workexperience.length - 1 ? (
                                          <img style={{/* float: 'right',
                                            margin: '-12px -8px' */marginRight: '50px'
                                          }}
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({ tutor_role: '', organization: '', from_date: "" /* new Date() */, to_date: ""/* new Date() */, });
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null}
                                      </div>
                                      {/*  </div> */}
                                    </>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>

                        </AccordionDetails>

                      </Accordion>
                    </div>

                    <div className="mt-4">
                      <Accordion style={{ boxShadow: "none" }} expanded={expanded === "panel3"} onChange={handleChangeExpand("panel3")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                          <h6 sx={{ width: "33%", flexShrink: 0 }}> Educational Details</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="row">
                            <FieldArray name="educationdetails">
                              {({ push, remove }) => (
                                <>
                                  {values.educationdetails?.map((_, index) => (
                                    <>
                                      {/*  <div key={index}> */}
                                      <div className="col-md-6">
                                        <div className="mb-3" key={index}>
                                          <div className="form_tutor_field position-relative">
                                            <FormControl fullWidth>
                                              <InputLabel id="city-label"> Select Degree</InputLabel>
                                              <Select labelId="city-label" id="ciry-select"
                                                name={`educationdetails[${index}].degree`}
                                                label="Select Degree"
                                                value={values.educationdetails[index].degree}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              >
                                                {degree.map((e, key) => {
                                                  return (<MenuItem key={key} value={e.id} > {e.degree} </MenuItem>);
                                                })}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div className="form_tutor_field position-relative">
                                            <FormControl fullWidth>
                                              <InputLabel id="city-label">
                                                Select Major
                                              </InputLabel>
                                              <Select labelId="city-label" id="ciry-select" name={`educationdetails[${index}].major`}
                                                label="Select Major"
                                                value={values.educationdetails[index].major}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              >
                                                {major.map((e, key) => { return (<MenuItem key={key} value={e.id}>{e.major}</MenuItem>); })}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-3">
                                        <TextField className="form_field position-relative"
                                            id="outlined-basic"
                                            label="Enter Grade"
                                            variant="outlined"
                                            name={`educationdetails[${index}].grade`}
                                            defaultValue={values.educationdetails[index].grade}
                                            onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-3">
                                        <TextField className="form_field position-relative"
                                            id="outlined-basic"
                                            label="College Name"
                                            //name="college_name"
                                            name={`educationdetails[${index}].college_name`}
                                            variant="outlined"
                                            defaultValue={values.educationdetails[index].college_name}
                                            onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                          />

                                        
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div className="row g-0 two_div_row">
                                            <div className="col-md-5 address_field">
                                              <div className="dob_field form_tutor_field">
                                                <LocalizationProvider
                                                  dateAdapter={DateAdapter}
                                                >
                                                  <DesktopDatePicker
                                                    label="From"
                                                    value={values.educationdetails[index].from_date}
                                                    onBlur={handleBlur}
                                                    maxDate={new Date()}
                                                    onChange={(e) => {
                                                      setFieldValue(`educationdetails[${index}].from_date`, formatDate(e));
                                                    }}
                                                    renderInput={(params) => (<TextField {...params} />)}
                                                  />
                                                </LocalizationProvider>
                                              </div>
                                            </div>
                                            <div className="col-md-5 address_field">
                                              <div className="dob_field form_tutor_field">
                                                <LocalizationProvider dateAdapter={DateAdapter}>
                                                  <DesktopDatePicker
                                                    label="To"
                                                    value={values.educationdetails[index].to_date}
                                                    onBlur={handleBlur}
                                                    maxDate={new Date()}
                                                    onChange={(e) => {
                                                      setFieldValue(`educationdetails[${index}].to_date`, formatDate(e));
                                                    }}
                                                    renderInput={(params) => (<TextField  {...params} />)}
                                                  />
                                                </LocalizationProvider>

                                              </div>
                                            </div>
                                          </div>
                                        </div></div>
                                      {/*   add_new_contianers_educations */}

                                      <div className="add_new_contianers mb-4">


                                        {values.educationdetails.length >= 2 && index != 0 ? (

                                          <img style={{ float: 'right', marginRight: '31px', marginLeft: '-37px' }}
                                            src={Images.Minus}
                                            onClick={() => {
                                              values.educationdetails.splice(index, 1);
                                              setDetect(new Date());

                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />

                                        ) : null}

                                        {index ===
                                          values.educationdetails.length - 1 ? (
                                          <img style={{ /* float: 'right', margin: '-12px -8px' */ marginRight: '50px' }}
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({ degree: "", major: "", grade: "", college_name: "", from_date: "" /* formatDate(new Date()) */, to_date: "" /* formatDate(new Date()) */, });
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null}
                                      </div>

                                    </>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="mt-4">
                      <Accordion
                        style={{ boxShadow: "none" }}
                        expanded={expanded === "panel4"}
                        onChange={handleChangeExpand("panel4")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <h6 sx={{ width: "33%", flexShrink: 0 }}>
                            Accomplishments
                          </h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="row" >

                            <FieldArray name="accomplishment">
                              {({ push, remove }) => (
                                <>
                                  {values.accomplishment?.map((_, index) => (
                                    <>
                                      <div className="col-md-6 mt-1">
                                        <div className="tut_exp" key={index}>
                                          <div className="mb-4">
                                            <div className="form_tutor_field  position-relative">
                                              <TextField
                                                className="form_field position-relative"
                                                id="outlined-basic"
                                                label="Accomplishment Name"
                                                variant="outlined"
                                                name={`accomplishment[${index}].name`}
                                                onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                                defaultValue={values.accomplishment[index].name}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-2">
                                          <div className="form_tutor_field  position-relative">
                                            <TextField  /*  className="form_field position-relative" */
                                              id="outlined-basic"
                                              label="Upload Your Certificate"
                                              variant="outlined"
                                              onBlur={handleBlur}
                                              name={`accomplishment[${index}].certificate`}
                                              value={values.accomplishment[index].certificate}
                                            />
                                            {errors.accomplishment ? (
                                              <FormHelperText error>
                                                {errors.accomplishment[`${index}`]?.certificate}
                                              </FormHelperText>
                                            ) : null}
                                            <span
                                              className="upload__iconse w-100"
                                              onClick={() => { accomplishmentsImg.current.click(); }}
                                            >
                                              <img src={Images.upload_icon} alt="upload_icon" />
                                            </span>

                                            <div
                                              className={"preview " + (values.accomplishment[index].certificate ? "d-block" : "d-none")}>

                                              <Tooltip title={'Remove'}
                                                onClick={() => {
                                                  setFieldValue(`accomplishment[${index}].certificate`, "")
                                                  setFieldValue(`accomplishment[${index}].pic`, "")
                                                  //accomplishmentsImg.current.value = "";
                                                }}
                                                style={{ position: 'absolute', right: '-6px', top: '50px', background: 'snow', objectFit: 'cover' ,height:"20px",width:"20px"}}>
                                                <IconButton>
                                                  <CloseIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <img src={constant.common.bidBucket + values.accomplishment[index].certificate} id='output'
                                                style={{ width: '100px', height: '100px', borderRadius: '20px', marginTop: '20px', marginLeft: 'auto', display: 'block' }} />
                                            </div>
                                            {/*    <img id={`outputaccomplishments${index}`} src="#" className="image_preview" alt="certificate" />
                                                  <h6
                                                    onClick={() => {
                                                      setFieldValue(`accomplishment[${index}].certificate`, "");
                                                      setFieldValue(`accomplishment[${index}].pic`, "");
                                                      accomplishmentsImg.current.value = "";
                                                    }} style={{ cursor: "pointer", }}>
                                                    Delete Image
                                                  </h6> */}

                                            <input type="file" multiple
                                              accept="image/*" id="file"
                                              ref={accomplishmentsImg}
                                              style={{ display: "none" }}
                                              onChange={(e) => {
                                                if (
                                                  checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 2000000)
                                                ) {
                                                  setFieldValue(`accomplishment[${index}].pic`, e.target.files[0].name);
                                                  uploadFile(e.target.files[0],
                                                    e.target.files[0].name,
                                                    setFieldValue, `accomplishment[${index}].certificate`);
                                                  var output = document.getElementById("outputaccomplishments" + index);
                                                  output.src = URL.createObjectURL(e.target.files[0]);
                                                  output.onload = function () {
                                                    URL.revokeObjectURL(output.src);
                                                  };

                                                } else {
                                                  toast.error("Please Select Valid file.", { position: toast.POSITION.TOP_RIGHT, });
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/*  add_new_contianers_educations  */}
                                      <div className="add_new_contianers mb-3">
                                        {
                                          values.accomplishment.length >= 2 && index != 0 ? (
                                            <img style={{ position: 'absolute', top: '2px', right: '120px' }}
                                              src={Images.Minus}
                                              onClick={() => {
                                                values.accomplishment.splice(index, 1);
                                                console.log(values.accomplishment, 'sssss')
                                                setDetect(new Date());

                                              }}
                                              alt="imgg"
                                              className="add_icon"
                                            />
                                          ) : null
                                        }

                                        {index ===
                                          values.accomplishment.length - 1 ? (
                                          <img
                                            style={{ position: 'absolute', top: '20px', right: '78px' }}
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({ name: "", certificate: "", pic: "" });
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null}

                                      </div>

                                      {/*  </div> */}
                                    </>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                            {/*  </div> */}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="mb-100"></div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Footer />
    </>
  );
}
