
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, Button, IconButton } from "@mui/material/";
import { axiosToken } from '../../../services/api';
import AddNewBanner from './AddNewBanner';
import constant from '../../../constant/Validation';
import EditBanner from './EditBanner';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './ManageBanners.css'

export default function BannerList() {
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    const [dlt_id,setDlt_id] = useState(null);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const [id, setId] = useState(null);
    useEffect(() => { console.log(add_std, 'sss') }, [add_std])
    useEffect(() => { console.log(edit_std, 'edit') }, [edit_std])
    const handleChangePage = (event, newPage) => {
        console.log(newPage, 'pagess')
        setCurrentPage(newPage)
        axiosToken.get(`admin/adminbanner?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getBannerData = () => {
        axiosToken.get(`/admin/adminbanner?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/adminbanner?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('/admin/adminbanner?id=' + id + '&status=' + value)
            .then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((err) => { console.log(err) })
    }

    const deleteBanner = () => {
        axiosToken.delete('/admin/deletebanner?id=' + dlt_id)
            .then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getBannerData();
                    handleClose(false);
                }
                else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
    }


    useEffect(() => { getBannerData() }, [])

    return (
        <>
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontFamily:"'Ubuntu', sans-serif"}}>
         Are you sure want to Delete the Banner?
        </DialogTitle>
        <DialogActions className='dialog__action'>
        <div
                        className="gender_btn btn_active"
                        onClick={() => {deleteBanner();}}
                    >
                        <h6>Yes</h6>
                    </div>
                    <div className="gender_btn" >
                        <h6 onClick={() => { handleClose(false) }}>No</h6>
                    </div>
        </DialogActions>
      </Dialog>

            <AddNewBanner openDialogProp={add_std} onClose={() => { setAdd_std(false); getBannerData(); }} onCancel={() => { setAdd_std(false) }} />
            <EditBanner openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getBannerData() }} onCancel={() => { setEdit_std(false); }} />
            <div className='student_table_container' id='manage_student_table'>
               
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Banners
                        </li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Banner</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Banners ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Banner Image</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Link</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <img src={constant.common.bidBucket + row.blob} className="banner__image" />
                                    </TableCell>
                                    <TableCell>
                                        {row?.display_text == 1 ? 'Center'
                                            : row?.display_text == 2 ? 'Bottom'
                                                : row?.display_text == 0 ? 'Top' : null}</TableCell>

                                    <TableCell> {row.text_url}</TableCell>

                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />

                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                                <Tooltip title="Edit Banner" arrow>
                                                    <IconButton><EditIcon onClick={() => {
                                                        axiosToken.get('/admin/get_banner_details?id=' + row.id)
                                                            .then((res) => {
                                                                setEditData(res.data[0])
                                                                setEdit_std(true)
                                                            })
                                                            .catch((err) => { console.log(err.response) })
                                                    }} /></IconButton>
                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                                <Tooltip title="Delete Banner" arrow>
                                                    <IconButton><DeleteIcon onClick={() => {
                                                       handleClickOpen()
                                                       
                                                        /*   deleteBanner(row.id) */
                                                       // getBannerData();
                                                       setDlt_id(row.id);
                                                       
                                                    }} /></IconButton>
                                                </Tooltip>
                                            </span>

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </div>

        </>

    )
}
