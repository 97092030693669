import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import "../Auth/Auth.css";
import axios from "axios";
import { API_ENDPOINT } from "../../../services/api";
import { TextField, Button, IconButton } from "@mui/material/";
import constant from "../../../constant/Validation";
import Images from "../../../constant/Images";
import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { loading, updateUserRole } from "../../../store/actions";
import { useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import EyeTrue from "../../../assets/icons/eyein.svg";
import EyeFalse from "../../../assets/icons/eye.svg";

import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

export default function Auth() {
  const dispatch = useDispatch();
  const location = useLocation();
  const adminCheck = location.pathname === "/admin" ? true : false;
  const [password, setPassword] = useState(true);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))?.token) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 1);
    }
    dispatch(loading(false));
  }, []);

  const navigate = useNavigate();

  /*  const [values, setValues] = React.useState({
    showPassword: false, 
  }); */
  const initialValues = {
    email: "",
    password: "",
    role_type: location.pathname === "/admin" ? 4 : 2,
    acceptTerms: adminCheck,
  };

  const clientId =
    "580178913727-gg41gk9b71hgng6fecbtg0nsh2a20i1t.apps.googleusercontent.com";
  const LoginSchema = Yup.object({
    email: Yup.string()
      .required(constant.Login.emailErrMsg)
      .email(constant.Login.emailErrMail),

    password: Yup.string().required(constant.Login.passwordErrMsg),
    // acceptTerms: Yup.bool().oneOf([true], constant.Login.acceptErrMsg),
  });
  const submitLogin = (payload) => {
    dispatch(loading(true));
    axios
      .post(API_ENDPOINT + "admin/signin", payload)
      .then((res) => {
        dispatch(updateUserRole(res.data.role[0]));
        setTimeout(() => {
          dispatch(loading(false));
        }, 1000);
        toast.success("Logged in Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("user", JSON.stringify(res?.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          dispatch(loading(false));
        }, 1000);
      });
  };
  const responseGoogle = (response) => {
    console.log("responseGoogle", response);
  
   let payload = {
      "id":response?.profileObj?.googleId,
      "email":response?.profileObj?.email,
      'profile':response?.profileObj?.imageUrl,
      "socail_type":"google"
      }
    axios
      .post(API_ENDPOINT + "admin/socaillogin", payload)
      .then((res) => {
        toast.success("Logged in Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("user", JSON.stringify(res?.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const responseFacebook = (res) => {
    console.log(res);
    let payload = {
      id: res.userID,
      email: res.email,
      socail_type: "facebook",
    };
    axios
      .post(API_ENDPOINT + "admin/socaillogin", payload)
      .then((res) => {
        toast.success("Logged in Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("user", JSON.stringify(res?.data));
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  /*   const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }; */

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-sm-12 login_bg"></div>
        <div className="col-md-6 col-sm-12 ">
          <img className="school__logo" src={Images.Logo} alt="img" />
          <div className="login__container mt-5">
            {adminCheck ? (
              <h4 className="get_started mb-4">Welcome Admin !</h4>
            ) : (
              <h4 className="get_started">Get Started</h4>
            )}
            {!adminCheck ? (
              <p className="newUser_list">
                New User ? &nbsp;
                <Link to="/register" className="link">
                  Register
                </Link>{" "}
              </p>
            ) : null}
            <div className="login_form">
              <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  submitLogin(values);
                }}
              >
                {({ errors, handleBlur, handleChange, values, touched }) => (
                  <>
                    <div className="row no-gutters">
                      <Form>
                        <div className="spacetext">
                          <TextField
                            className="fieldtext"
                            type="text"
                            name="email"
                            label="Email"
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                          />
                        </div>
                        <div className="spacetext" style={{position:'relative'}}>
                          {/*  <FormControl variant="outlined" >
                        <InputLabel htmlFor="standard-adornment-password">
                           Password
                        </InputLabel>
                          <Field
                          as={OutlinedInput}
                            className="fieldtext"
                           
                            type={values.showPassword ? "text" : "password"} */}
                          <TextField
                            //value={values.password}
                            name="password"
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            //onChange={handleChange("password")}
                            type={password ? "password" : "text"}
                            label="Password"
                            variant="outlined"
                          />
                          <img
                            src={!password ? EyeFalse : EyeTrue}
                            className="EyeIcon"
                            onClick={() =>
                              setPassword((prevState) => !prevState)
                            }
                            alt=""
                          />

                          {/*   endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          
                          </FormControl>  */}
                        </div>
                        {!adminCheck ? (
                          <div className="accept_term row">
                            <div className="col-md-7 ">
                              {/* <Field
                              type="checkbox"
                              name="acceptTerms"
                              id="accept__condition"
                              className={
                                "form-check-input " +
                                (errors.acceptTerms && touched.acceptTerms
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <span className="terms__cond"></span>I agree to the{" "}
                            <span className="term__color">
                              Terms & Conditions
                            </span>
                            <ErrorMessage
                              name="acceptTerms"
                              component="div"
                              className="invalid-feedback"
                            /> */}
                            </div>
                            <div className="col-md-5">
                              <Link
                                to="/forgot-password"
                                className="forgot_link"
                              >
                                Forgot Password ?
                              </Link>
                            </div>
                          </div>
                        ) : null}

                        <Button
                          id="btnclase"
                          variant="outlined"
                          color="error"
                          type="submit"
                        >
                          {adminCheck ? "Admin Login" : "Login"}
                        </Button>

                        {!adminCheck ? (
                          <>
                            <span className="divider"></span>
                            <div className="social_login ">
                              <GoogleLogin
                              //autoLoad={false}
                              className="google_btn_logine"
                              clientId={clientId}
                              onSuccess={responseGoogle}
                              //onFailure={responseGoogle}
                              cookiePolicy={"single_host_origin"}
                              isSignedIn={true}
                          >
                            <span className="g_textclr">Login with Google</span>
                          </GoogleLogin>

                              {/* <LoginSocialGoogle
                                client_id={clientId}
                                onResolve={({ provider, data }) => {
                                  responseGoogle(provider, data);
                                }}
                                onReject={(err) => {
                                  console.log(err);
                                }}
                              >
                                <span className="g_textclr">
                                  Login with Google
                                </span>
                              </LoginSocialGoogle> */}

                              <FacebookLogin
                                appId="1298478504066314"
                                //type="button"
                                //autoLoad
                                fields="name,email,picture"
                                callback={responseFacebook}
                                className="loginfb_color"
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    type="button"
                                    className="register_fbcolor py-2"
                                  >
                                    <img
                                      className="loginfb_color"
                                      src={Images.Fbimg}
                                      alt="Facebook"
                                    />
                                    <span className="fb_btn_text">
                                      {" "}
                                      Log In With Facebook{" "}
                                    </span>
                                  </button>
                                )}
                              />
                            </div>
                          </>
                        ) : null}
                      </Form>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
