import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import AssignmentDialog from '../../../components/AssignmentDialog/AssignmentDialog';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { axiosToken } from '../../../services/api';
import { useNavigate } from 'react-router-dom';



export default function AssignmentList({ data, value }) {
    useEffect(() => { }, [data])
    const navigate = useNavigate();
    const [openDialog, setOpenDiglog] = useState(false);
    const [id, setId] = useState(null);
    useEffect(() => { }, [openDialog])

    const [tempData, setTempData] = useState([])
    const [hide, setHide] = useState(true);
    const [detect, setDetect] = useState(new Date());
    const [assignView, setAssignView] = useState(null)
    const dialogData = {
        title: 'Are You sure want to Publish?',
        content: 'Please click on yes if you want to publish this assignment to students or click cancel to close this.'
    }

    useEffect(() => {
        setTempData(data?.slice(0, 5))
        data?.length >= 5 ? setAssignView(true) : setAssignView(false)
        setHide(false)
    }, [data])

    useEffect(() => { }, [detect])

    useEffect(() => {

        data.map((d) => {
            if (d.inprogress >= 75) {
                d.color = '#a5cc3f'
            } else if (d.inprogress >= 51) {
                d.color = '#f88214'
            } else {
                d.color = '#e22a4b'
            }
        })
    })
    function viewLessMore() {
        setHide(true)
        setDetect(new Date())
        setTempData(data)
    }
    function viewAll() {
        setDetect(new Date())
        setTempData(data?.slice(0, 5))
        setHide(false)
    }
    return (
        <div>
            <AssignmentDialog openDialogProp={openDialog} id={id} dialogData={dialogData} type={'publish_assignment'} o />
            <div className="table_list__assign review">
                <table class="table assign__table">
                    <thead>
                        <tr>
                            <th className="table__color">Title</th>
                            <th className="table__color">Course</th>
                            <th className="table__color">Class</th>
                            {value == 1 ? <th className="table__color" style={{whiteSpace:'noWrap'}}>Published Date</th> : <th className="table__color"style={{whiteSpace:'noWrap'}}>Draft Date</th>}
                            {value == 1 ? <th className="table__color" align="center">InProgress</th> : null}
                            {value == 0 ? <th className="table__color" align="center">Publish</th> : null}
                            <th className='table__color'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempData.map((d, i) => (
                            <tr key={i}>
                                <td className="py-3" style={{ textTransform: 'capitalize' }}>{d.question}</td>
                                <td className="py-3" style={{ textTransform: 'capitalize' }}>{d.course_name}</td>
                                <td className="py-3" style={{ textTransform: 'capitalize' }}>{d.class}</td>
                                {value == 1 ? <td className="py-3">{d.created_at}</td> : <td className="py-3">{d.draft_date}</td>}
                                {value == 1 ? <td>
                                    <div className="progress_container" style={{ marginTop: '15px' }}>
                                        <div className="progress_box">
                                            <span className="per_value" style={{ float: 'right', marginTop: '-20px' }}>{d.inprogress + '%'}</span>
                                            <span className="progress_bar" style={{ width: d.inprogress + '%', backgroundColor: d.color }}></span>
                                        </div>
                                    </div>
                                </td> : null}

                                {value == 0 ? <td className="py-3">
                                    <Tooltip title="Publish Assignment"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setOpenDiglog(false);
                                            setId(d.id);
                                            setTimeout(() => {
                                                setOpenDiglog(true);
                                            }, 100);

                                        }}>
                                        <CheckIcon />
                                    </Tooltip>

                                </td> : null}
                                <td>
                                    <Tooltip title="Edit"
                                        style={{ cursor: 'pointer', marginTop: '6px' }}
                                        onClick={() => {
                                            axiosToken.get('tutor/updateassignment?id=' + d.id)
                                                .then((res) => {
                                                    navigate('/edit-assignemnt', { state: { data: res.data[0] } })
                                                    console.log(res)
                                                })
                                                .catch((err) => { console.log(err.response) })
                                        }}
                                    >
                                        <EditIcon />
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {assignView ?
                <>
                    {hide ?
                        <p className="view_all_btn" onClick={() => {
                            viewAll()
                            /* setTempData(data) */
                        }} >View Less</p> :
                        <p className="view_all_btn" onClick={() => {
                            viewLessMore()
                            /* setTempData(data?.slice(0, 4)) */
                        }} >View All</p>}  </> : null}
        </div>

    )
}
