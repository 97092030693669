import { drawerClasses, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field } from 'formik';
import React from 'react'


export default function SelectForm({values,handleBlur,handleChange,dorpdownValue,label,name,dropwndownValueName,disabled}) {
    return (
        <FormControl fullWidth disabled={disabled}>
            <InputLabel id="city-label">
             {label}
            </InputLabel>
            <Field
                as={Select}
                id="city-label"
                disable={true}
                name={name}
                label={label}
                value={values}
                onBlur={(e) => {
                    handleBlur(e);
                    handleChange(e);
                   
                }}
            >
                {dorpdownValue.map((e, key) => {
                    return (
                        <MenuItem key={key} value={e.id}>
                            {e[dropwndownValueName]}
                        </MenuItem>
                    );
                })}
            </Field>
        </FormControl>
    )
}
