import React, { useRef, useState, useEffect } from "react";
import "../CreateCourse/Createcourse.css";
import Header from "../../../components/Header/Header";
import Scrollspy from "react-scrollspy";
import TextField from "@mui/material/TextField";
import Images from "../../../constant/Images";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import constant from "../../../constant/Validation";
import { Formik, Form, Field, FieldArray } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { API_ENDPOINT, s3config, axiosToken } from "../../../services/api";
import S3 from "react-aws-s3";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectForm from "../../../components/Forms/select/Select";
import ClassSchedule from "./components/ClassSchedule/ClassSchedule";
import Modal from "react-bootstrap/Modal";
import CoursePreview from "../../CoursePreview/CoursePreview";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useLocation } from 'react-router-dom';
import { checkTimeandDate } from "../../../helpers/utils";
import Footer from "../../../components/Footer/Footer";
//import Editor from 'ckeditor5-custom-build/build/ckeditor';


const CourseSchema = Yup.object().shape({
  course_name: Yup.string().required(constant.Createcourse.titleErrMsg).trim().matches(/^[aA-zZ\s]+$/, constant.Register.validatenumErrMsg),
  interest: Yup.string().required(constant.Createcourse.categoryErrMsg),
  grade_id: Yup.string().required(constant.Createcourse.gradesErrMsgs),
  description: Yup.string().required(constant.Createcourse.describeErrMsg).trim(),
  class_name: Yup.string().required('Please enter Classname'),
  class_date: Yup.array().required('Please select class date'),
  class_start_on: Yup.string().required('Please select class start time'),
  class_ends_on: Yup.string().required('Please select class end time'),
  tutor_id: Yup.string().required('Please select tutor'),
  tags: Yup.array().required(constant.Createcourse.tagErrMsg).min(1),
  course_image: Yup.string().required(constant.Createcourse.picsizeErrMsg),
  course_intro: Yup.string().required(constant.Createcourse.videoSizeErrMsg),
  content: Yup.string().required('Please add some content').trim(),
  reference: Yup.array(
    Yup.object().shape({ 
      course_reference: Yup.string() })
  ),
});
var todayDate = new Date();
const yyyy = todayDate.getFullYear();
let mm = todayDate.getMonth() + 1; // Months start at 0!
let dd = todayDate.getDate();
if (dd < 10) dd = "0" + dd;
if (mm < 10) mm = "0" + mm;
var todayDate = dd + "/" + mm + "/" + yyyy;


export default function Createcourse() {
  const courseDetails = useRef(null);
  const add_content = useRef(null);
  const class_section = useRef(null);
  const educational_details = useRef(null);
  const accomplishments = useRef(null);
  const preview = useRef(null);
  const upload = useRef(null);
  const uploadVideo = useRef(null);
  const [tags, setTags] = useState([]);
  const [tempTag, setTempTag] = useState("");
  const [showAutoTag, setShowAutoTag] = useState(false);
  const [autoTags, setAutoTags] = useState([]);
  const [grade, getGrade] = useState([]);
  const [category, getCategory] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [previewvalues, setPreviewValues] = useState([]);
  const [tutor, setTutor] = useState([]);
  const [detect, setDetect] = useState(new Date());
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('user'))?.role[0])
  const navigate = useNavigate();
  const location = useLocation();
  const checkLocation = location.pathname === '/admin/manage-courses/create-course' ? true : false;
  const initialValues = {
    course_name: "",
    interest: "",
    grade_id: "",
    course_intro: "",
    course_image_name: "",
    video_name: "",
    description: "",
    duration: "",
    amount: "100",
    discount_percentage: "10",
    class_name: "",
    class_start_on: '',
    class_ends_on: '',
    class_date: "",
    course_image: "",
    content: "",
    days: 5,
    tags: "",
    tutor_id: checkLocation ? '' : 'null',
    course_reference: [{ link: "", },],
    status: 1
  };

  const ScrolltoContent = (activeDiv) => {
    switch (activeDiv) {
      case "courseDetails":
        courseDetails.current.scrollIntoView();
        break;
      case "add_content":
        add_content.current.scrollIntoView();
        break;
      case "educational_details":
        educational_details.current.scrollIntoView()
        break;
      case "preview":
        preview.current.scrollIntoView()
        break;
      case "accomplishments":
        accomplishments.current.scrollIntoView()
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getBasicDetails();
    getTutorDetails();
  }, []);
  useEffect(() => { }, [autoTags]);
  useEffect(() => { }, [detect])

  const handleCourse = (loader) => {
    axiosToken
      .post("tutor/create_course", previewvalues)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.messsage, { position: toast.POSITION.TOP_RIGHT, });
          setTimeout(() => { navigate(-1); }, 1000);
        }
      })
      .catch((err) => {
        //console.log(err.response)
        toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT, });
        setModalShow(false);

      });
  };

  const handleCancel = () => { setModalShow(false); };
  function CoursePreviewDialogModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CoursePreview data={previewvalues} />
        <div className="row check">
          <div className="submit_btn_container flex_course">
            <div className="gender_btn btn_active">
              <h6 onClick={() => { handleCourse(); }}>Publish</h6>
            </div>
            <div className="gender_btn">
              <h6 onClick={handleCancel}>Cancel</h6>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  let config = { ...s3config, dirName: "course_icon", };
  const ReactS3Client = new S3(config);
  const uploadFile = (file, name, setFieldValue, field_name) => {
    ReactS3Client.uploadFile(
      file, "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name
    )
      .then((data) => {
        setFieldValue(field_name, data.key);
        console.log(data.location);
      })
      .catch((err) => console.log(err));
  };
  const getTutorDetails = async () => {
    axiosToken.get('/admin/tutordetailsadmin')
      .then((res) => {
        setTutor(res.data)
      })
  }
  const getBasicDetails = async () => {
    axiosToken.get("course/getcoursebasicdetails").then((res) => {
      setAutoTags(res.data.tags);
      getGrade(res.data.grade);
      getCategory(res.data.interest);
    });
  };
  const tagAutoSearch = async (val) => {
    axiosToken.get("course/filter_tag?search=" + val).then((res) => {
      setAutoTags(res.data);
    });
  };
  function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

  return (
    <>
      <Header />
      {/*  <Sidebar /> */}
      <div className="container-fluid px-5" id="profile_regsiter">
        <Formik
          initialValues={initialValues}
          validationSchema={CourseSchema}
          onSubmit={(values) => {
            setPreviewValues(values);
            setModalShow(true);
            // checkTimeandDate(values.class_date,values.class_start_on,values.class_ends_on);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            isValid,
            setFieldValue,
          }) => (
            <Form>
              <div className="row">
                <div className="col-md-3 ">
                  <div className="userInfo">
                    <h5 className="regster_description" style={{ color: '#666666' }}>
                      Create Your Course Now
                    </h5>
                  </div>
                  <div className="stepperContainer top_container" style={{ marginLeft: '-29px' }}>
                    <ul className="stepper_ul p-0 position-relative">
                      <Scrollspy style={{ color: '#666666' }}
                        items={[
                          "courseDetails",
                          "add_content",
                          "educational_details",
                          "accomplishments",
                          "preview"
                        ]}
                        currentClassName="active"
                      >
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("courseDetails");
                          }}
                        >
                          <h6 className="stepper_text">Course Details</h6>
                        </li>
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("add_content");
                          }}
                        >
                          <h6 className="stepper_text">Add Content</h6>
                        </li>

                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("educational_details");
                          }}
                        >
                          <h6 className="stepper_text">Add Tags</h6>
                        </li>
                        {/* <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("accomplishments");
                          }}
                        >
                          <h6 className="stepper_text">Add Course Materials</h6>
                        </li> */}
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("accomplishments");
                          }}
                        >
                          <h6 className="stepper_text">
                            Add Course References
                          </h6>
                        </li>
                        {/* <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("accomplishments");
                          }}
                        >
                          <h6 className="stepper_text">Add Quiz</h6>
                        </li> */}
                       
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("preview");
                          }}
                        >
                          <h6 className="stepper_text">Preview & Publish</h6>
                        </li>
                      </Scrollspy>
                    </ul>
                  </div>
                </div>
                <div className="col-md-9 top_container">
                  <div className="err_container">
                    {errors && (
                      <FormHelperText error>
                        {errors.title && touched.title ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.title}</p>
                          </Alert>
                        ) : null}
                        {errors.interest && touched.interest ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.interest}</p>
                          </Alert>
                        ) : null}
                        {errors.content && touched.content ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.content}</p>
                          </Alert>
                        ) : null}
                        {errors.tutor_id && touched.tutor_id ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.tutor_id}</p>
                          </Alert>
                        ) : null}
                        {errors.course_name && touched.course_name ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.course_name}</p>
                          </Alert>
                        ) : null}
                        {errors.description && touched.description ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.description}</p>
                          </Alert>
                        ) : null}

                        {errors.class_name && touched.class_name ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.class_name}</p>
                          </Alert>
                        ) : null}
                        {errors.class_date && touched.class_date ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.class_date}</p>
                          </Alert>
                        ) : null}
                        {errors.grade_id && touched.grade_id ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.grade_id}</p>
                          </Alert>
                        ) : null}
                        {errors.class_start_on && touched.class_start_on ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.class_start_on}</p>
                          </Alert>
                        ) : null}
                        {errors.class_ends_on && touched.class_ends_on ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.class_ends_on}</p>
                          </Alert>
                        ) : null}
                        {errors.tags && touched.tags ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.tags}</p>
                          </Alert>
                        ) : null}
                        {errors.link && touched.link ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.link}</p>
                          </Alert>
                        ) : null}
                        {errors.course_image && touched.course_image ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.course_image}</p>
                          </Alert>
                        ) : null}


                        {errors.course_intro && touched.course_intro ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.course_intro}</p>
                          </Alert>
                        ) : null}
                      </FormHelperText>
                    )}
                  </div>

                  <div className="col-10">
                    {/* START COURSE DETAILS */}
                    <div
                      className="courseDetails stepper_div"
                      ref={courseDetails}
                      id="courseDetails"
                    >
                      <div className="boxContainer">
                        <h5 className="box_header_title">
                          Course Details
                          <span className="required_field">*</span>
                        </h5>
                        <span className="mandatory__text">
                          <span className="required_field">*</span> Fields are
                          Mandatory
                        </span>
                        <div className="form_container">
                          <div className="course_title form_field">
                            <TextField
                              id="outlined-basic"
                              label="Course Title"
                              variant="outlined"
                              name="course_name"
                              onBlur={(e) => {
                                handleBlur(e);
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="container p-0">
                          <div className="row">
                            <div className={`${checkLocation ? "col-md-4" : "col-md-6"} course_date`}>
                              <div className=" form_field position-relative">
                                <SelectForm
                                  values={values.interest}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  dorpdownValue={category}
                                  label="Course Category"
                                  name="interest"
                                  dropwndownValueName="tags"
                                />
                              </div>
                            </div>
                            <div className={`${checkLocation ? "col-md-4" : "col-md-6"}`}>
                              <div className=" form_field position-relative">
                                <SelectForm
                                  values={values.grade_id}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  dorpdownValue={grade}
                                  dropwndownValueName="grade_name"
                                  label="Select Grade"
                                  name="grade_id"
                                />
                              </div>
                            </div>
                            <div className={`${checkLocation ? "col-md-4" : "d-none"}`}>
                              <div className=" form_field position-relative">
                                <SelectForm
                                  values={values.tutor_id}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  dorpdownValue={tutor}
                                  dropwndownValueName="email"
                                  label="Select Tutor"
                                  name="tutor_id"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="about_textarea form_field position-relative">
                            <TextField
                              id="outlined-multiline-static"
                              rows={4}
                              label="Course Description"
                              variant="outlined"
                              name="description"
                              multiline
                              onBlur={(e) => { handleBlur(e); handleChange(e); }}
                            />
                          </div>
                          <div className="photo_upload form_field position-relative">
                            <TextField
                              id="outlined-basic"
                              label="Course Banner"
                              variant="outlined"
                              value={values.course_image_name}
                            />
                            <span
                              className="upload__icon"
                              onClick={() => { upload.current.click(); }}
                            >
                              <img src={Images.upload_icon} alt="upload_icon" />
                            </span>
                            <span className="form_des_info">(jpg/png files not more than 200kb)</span>
                            <div
                              className={"profile_image " + (values.course_image_name ? "d-block" : "d-none")}
                            >
                              <img id="output" src="#" className="image_preview" />
                              <h6
                                onClick={() => {
                                  setFieldValue("course_image_name", ""); setFieldValue("course_image", "");
                                  upload.current.value = "";
                                }}
                                className="close__icon"
                              >
                                <CloseIcon />
                              </h6>
                            </div>
                            <input type="file" id="file" ref={upload} style={{ display: "none" }}
                              onChange={(e) => {
                                if (checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)
                                ) {
                                  setFieldValue("course_image_name", e.target.files[0].name);
                                  uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "course_image");
                                  var output =
                                    document.getElementById("output");
                                  output.src = URL.createObjectURL(e.target.files[0]);
                                  output.onload = function () { URL.revokeObjectURL(output.src); };
                                } else {
                                  toast.error("Please Select Valid Image. or Check image Size",
                                    { position: toast.POSITION.TOP_RIGHT, }
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="photo_upload form_field position-relative">
                            <TextField id="outlined-basic" label="Course Overview Video" variant="outlined" name="course_intro" value={values.video_name} />
                            <span
                              className="upload__icon"
                              onClick={() => { uploadVideo.current.click(); }}
                            >
                              <img src={Images.upload_icon} alt="upload_icon" />
                            </span>
                            <span className="form_des_info">
                              (MP4 File not more than 20 Mb)
                            </span>
                            <div className={"profile_image " + (values.video_name ? "d-block" : "d-none")}>
                              <video id="videoPreview" width="320" height="240" controls > Your browser does not support the video tag. </video>
                              <h6
                                onClick={() => { setFieldValue("course_intro", ""); setFieldValue("video_name", ""); uploadVideo.current.value = ""; }}
                                className="close__icon"
                                style={{ left: "60%", top: "21px" }}
                              >
                                <CloseIcon />
                              </h6>
                            </div>
                            <input
                              type="file"
                              id="file"
                              accept="video/*"
                              ref={uploadVideo}
                              style={{ display: "none" }}
                              onChange={(e) => {
                                if (
                                  checkValidFile(
                                    e.target.files[0],
                                    [".mp4"],
                                    20000000
                                  )
                                ) {
                                  setFieldValue("video_name", e.target.files[0].name);
                                  uploadFile(
                                    e.target.files[0],
                                    e.target.files[0].name,
                                    setFieldValue,
                                    "course_intro"
                                  );
                                  var output =
                                    document.getElementById("videoPreview");
                                  output.src = URL.createObjectURL(
                                    e.target.files[0]
                                  );
                                  output.onload = function () {
                                    URL.revokeObjectURL(output.src); // free memory
                                  };
                                } else {
                                  toast.error(
                                    "Please Select Valid Video file or Size.",
                                    {
                                      position: toast.POSITION.TOP_RIGHT,
                                    }
                                  );
                                }
                              }}
                            />
                          </div>
                          <div
                            className="class stepper_div"
                            ref={add_content}
                            id="class"
                          >
                            <div className="boxContainer">
                              <h5 className="box_header_title">
                                Class <span className="required_field">*</span>
                              </h5>
                              <ClassSchedule
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                isedit={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END COURSE DETAILS */}

                    {/* START CONTENT SECTION */}
                    <div
                      className="courseDetails stepper_div"
                      ref={add_content}
                      id="add_content"
                    >
                      <div className="boxContainer">
                        <h5 className="box_header_title">
                          Add Content <span className="required_field">*</span>
                        </h5>
                        <div className="form_container">

                          <CKEditor /* config={{extraPlugins :[uploadPlugin]}} */
                            editor={ClassicEditor}
                            //editor={Editor}
                            data="<p>Hello</p>"
                           /*  name="content"
                            value={values.content}
                            */ onBlur={(event, editor) => {
                              //onReady={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("content", data);

                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="courseDetails stepper_div"
                      ref={educational_details}
                      id="educational_details"
                    >
                      <div className="boxContainer">
                        <h5 className="box_header_title">
                          Add Tags <span className="required_field">*</span>
                        </h5>
                        <div className="form_container">
                          <div className="row g-0 two_div_row">
                            <div className="col-md-6 address_field">
                              <div className=" form_field position-relative add_tag_container">
                                <TextField
                                  id="outlined-basic"
                                  autoComplete="off"
                                  label="Tags"
                                  variant="outlined"
                                  onBlur={() => {
                                    setTimeout(() => {
                                      setShowAutoTag(false);
                                    }, 300);
                                  }}
                                  value={tempTag}
                                  onChange={(e) => { setTempTag(e.target.value); tagAutoSearch(e.target.value); }}
                                  onFocus={() => { setShowAutoTag(true); }}
                                />
                                {showAutoTag ? (
                                  <div className="autoTags">
                                    {autoTags.map((data, i) => (
                                      <>
                                        <h6
                                          key={i}
                                          onClick={() => {
                                            setTags((pre) => [...pre, data.tags,]);
                                            setTempTag("");
                                          }}
                                        >
                                          {data.tags}
                                        </h6>
                                      </>
                                    ))}
                                  </div>
                                ) : null}
                                <h6
                                  onClick={() => {
                                    if (tempTag != "") {
                                      setTags((pre) => [...pre, tempTag]);
                                      setTempTag("");
                                    }
                                  }}
                                  className="btn_secondary add_btn"
                                >
                                  Add
                                </h6>
                              </div>
                              <div className="tags__list d-flex">
                                {tags.map((res, i) => (
                                  <div className="pills" key={i}>
                                    <h5>{res}</h5>
                                    <p onClick={() => { tags.pop(i); setTags((pre) => [...pre]); }}>
                                      <CloseIcon sx={{ fontSize: 15 }} />
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="class stepper_div" ref={accomplishments} id="accomplishments" >
                          <div className="boxContainer">
                            <h5 className="box_header_title">   Add Course References <span className="required_field"></span></h5>
                            <div className="form_container">
                              <div className="form_field position-relative">
                                <FieldArray name="course_reference">
                                  {({ push, remove }) => (
                                    <>
                                      {values.course_reference.map(
                                        (_, index) => (
                                          <div className="position-keeper" key={index}>
                                            <div className="dynamicclass">
                                              <div className="col-md-6 address_field">
                                                <div className="course_title form_field position-relative">
                                                  <TextField
                                                    id="outlined-basic"
                                                    label="Ref Link"
                                                    variant="outlined"
                                                    onBlur={(e) => { handleBlur(e); handleChange(e); }}
                                                    name={`course_reference[${index}].link`}
                                                  />
                                                </div>
                                                {/* </div> */}
                                              </div>
                                            </div>

                                            <div className="add_new_contianers">
                                              {
                                                values.course_reference.length >= 2 && index != 0 ? (
                                                  <img style={{ position: 'absolute', top: '2px', right: '120px' }}
                                                    src={Images.Minus}
                                                    onClick={() => {
                                                      values.course_reference.splice(index, 1);
                                                      console.log(values.course_reference, 'sssss')
                                                      setDetect(new Date());

                                                    }}
                                                    alt="imgg"
                                                    className="add_icon"
                                                  />
                                                ) : null
                                              }

                                              {index ===
                                                values.course_reference.length - 1 ? (
                                               
                                                <img   style={{ position: 'absolute', top: '2px', right: '78px' }}
                                                src={Images.add_icon} onClick={() => {push({link: "", }); }}
                                               
                                                alt="imgg"
                                                className="add_icon"
                                              />
                                              ) : null}
                                            </div>

                                            {/*  {index ===
                                              values.course_reference.length -
                                              1 ? (
                                              <div className="add_new_contianer">
                                                <img src={Images.add_icon} onClick={() => {push({link: "", }); }}
                                                  //onClick={handleAddFields}
                                                  alt="imgg"
                                                  className="add_icon"
                                                />
                                              </div>
                                            ) : (
                                              <div className="add_new_contianer">
                                                <RemoveCircleOutlineIcon
                                                  style={{ color: "red" }}
                                                  onClick={() => {
                                                    remove({ link: "" });
                                                  }}
                                                  alt="imge"
                                                  className="add_icon"
                                                />
                                              </div>
                                            )} */}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="submit_btn_container" ref={preview} id="preview">
                          <div className="gender_btn__cc btn_active"
                            style={{ maxWidth: "200px" }}
                            onClick={() => {
                              console.log(errors, values)
                              setFieldValue("tags", tags);
                              setTimeout(() => { handleSubmit(); }, 500);
                            }}>
                            <h6>Preview & Publish</h6>
                          </div>
                          {!checkLocation ?
                            <div className="gender_btn__cc"
                              onClick={() => {
                                setFieldValue("tags", tags); setFieldValue("status", 0);
                                setTimeout(() => { handleSubmit(); }, 500);
                              }}
                            >
                              <h6>Save as Draft</h6>
                            </div>
                            : null}
                          <div className="gender_btn__cc" onClick={() => navigate(-1)}>
                            <h6 >Cancel</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-100"></div>
      <CoursePreviewDialogModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {role == 2 ? <Footer /> : null}
    </>
  );
}
