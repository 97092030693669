import React, { useEffect, useState } from "react";
import "../StudentsList/StudentList.css";
import { axiosToken } from "../../../services/api";
export default function StudentsList({ studentData }) {

  const [tempData, setTempData] = useState([])
  const [hide, setHide] = useState(true);
  const [detect, setDetect] = useState(true);
  const [studView, setStudView] = useState(null)
  useEffect(() => {
    setTempData(studentData?.slice(0, 5))
    studentData?.length >= 5 ? setStudView(true) : setStudView(false)
  }, [studentData])

  useEffect(() => { }, [detect])

  function viewLessMore() {
    setHide(true)
    setDetect()
    setTempData(studentData)
  }
  function viewAll() {
    setDetect()
    setTempData(studentData?.slice(0, 5))
    setHide(false)
  }

  return (
    <>
      <div className="table_list__assign review">
        <table class="table assign__table">
          <thead>
            <tr>
              <th className="table__color">Course Name</th>
              <th className="table__color">Student Name</th>
              <th className="table__color">Quiz Name</th>
              <th className="table__color">Total marks</th>
            </tr>
          </thead>
          <tbody>
            {tempData?.map((d, i) => (
              <tr key={i}>
                <td className="py-3" style={{ textTransform: 'capitalize' }}>{studentData[i]?.course_name}</td>
                <td className="py-3" style={{ textTransform: 'capitalize' }}>{studentData[i]?.student_name}</td>
                <td className="py-3">{studentData[i]?.quiz_set_name}</td>
                <td className="py-3">{studentData[i]?.total_marks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {studView ?
        <>
          {hide ?
            <p className="view_all_btn" onClick={() => {
              viewAll()
              setTempData(studentData)
            }} >View All</p> :
            <p className="view_all_btn" onClick={() => {
              viewLessMore()
              setTempData(studentData?.slice(0, 5))
            }} >View Less</p>}
        </> : null}

    </>
  );
}
