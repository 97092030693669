import React, { useEffect, useState } from 'react';
import { TextField,IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../../services/api';
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AddLocation from './AddLocation';
import EditLocation from './EditLocation';
import EditIcon from '@mui/icons-material/Edit';
export default function LocationListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    useEffect(() => { console.log(add_std, 'sss') }, [add_std])
    useEffect(() => { console.log(edit_std, 'edit') }, [edit_std])
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`/admin/get_academyMaster?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getListAcademyData = () => {
        axiosToken.get(`/admin/get_academyMaster?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/get_academyMaster?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('admin/create_academymaster?id=' + id+ '&status='+value )
            .then((res) => {
                if(res.data.status){
                console.log(res)
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => { console.log(err) })
    }
   
    useEffect(() => { getListAcademyData() }, [])
    return (
        <>
         <AddLocation openDialogProp={add_std} onClose={() => { setAdd_std(false); getListAcademyData(); }} onCancel={()=>{setAdd_std(false)}}/>
         <EditLocation openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getListAcademyData();setEditData([]) }}  onCancel={()=>{setEdit_std(false);setEditData([])}}/>
        <div className='student_table_container' id='manage_student_table'>

<nav aria-label="breadcrumb ">
    <ol className="breadcrumb empty">
        <li className="breadcrumb-item">
            <NavLink to="/dashboard" activeClassName="activeRoute">
                Dashboard
            </NavLink>
        </li>
        <li
            className="breadcrumb-item active"
            aria-current="page"
        >
            Manage Location
        </li>
    </ol>
</nav>
<div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Location</h6>
                    </div>
                    <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Locations ({total}) </h6>
                    </div> 
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                 
                            <TableCell> Location Name</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                     
                                    
                                     <TableCell style={{textTransform:'capitalize'}}>{row?.branch_location_name}</TableCell>
                                    <TableCell component="th" scope="row" style={{textTransform:'capitalize'}}>
                                        {row.academy_branch_city}
                                    </TableCell>
                                   {/*  <TableCell>{row?.city_id}</TableCell> */}
                                     <TableCell style={{textTransform:'capitalize'}}>{row?.state}</TableCell>
                                     <TableCell style={{textTransform:'capitalize'}}>{row?.country}</TableCell>
                                   
                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />

                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                            <Tooltip title="Edit " arrow>
                                                <IconButton><EditIcon   onClick={() => {
                                                    setEditData(row)
                                                    setEdit_std(true)}}/></IconButton></Tooltip>
                                                </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </div>
        </>
    )
}