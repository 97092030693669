import React, { useState, useEffect } from 'react';
import Header from "../../../components/Header/Header";
import Images from '../../../constant/Images';
import { axiosToken } from '../../../services/api'
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import './About.css'
import Footer from "../../../components/Footer/Footer";
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ReactDOMServer = require("react-dom/server");
const HtmlToReactParser = require("html-to-react").Parser;

export default function About() {
    const [cmsDatas, setCmsDatas] = useState([])

    const htmlInput = "cmsDatas";
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(cmsDatas);
    const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);


    useEffect(() => {
        axiosToken.get("/admin/get_cms_details?cms_key=about_us").then((res) => {
            setCmsDatas(res.data.about_app.cms_value)
            console.log(res.data.about_app.cms_value);

        })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <>
            <Header />

            <div className="container-fluid about___new">
                <div className="row">
                    <div className="col-md-6 p-5">
                        <div className="row">
                            
                               {/*  <h2 style={{ fontSize: '20px' }}>About Us</h2> */}
                               <h4 className="user_name" style={{ fontSize: '20px' }}>About Us</h4>
                                <h4 className="text-md-start mb-3" >
                                    <span style={{ color: '#e22c4c' }}>Every Child</span> Is Inspired To Learn And Empowered To Excel.
                                </h4>
                              
                                <p className="text-md-start mt-4 bannerdesc" style={{ marginRight: '150px', fontSize: '15px' }}>
                                    {reactElement}
                                
                                </p>
                            </div>
                        
                    </div>

                    <div className="col-md-6 slidercont">
                        <div className='about__bg'>
                        </div>
                        
                        <div className='carousel_cont ' >
                            <OwlCarousel className='owl-theme ' items={1}>
                                <div class='item  '>
                                    <img src={Images.About} alt="about"  /* className="img-fluid d-block w-100" */ />
                                </div>
                                <div class='item '>
                                    <img src={Images.About} alt="about"/*  className="img-fluid d-block w-100" */ />
                                </div>
                                <div class='item '>
                                    <img src={Images.About} alt="about" /* className="img-fluid d-block w-100" */ />
                                </div>
                            </OwlCarousel>
                        </div>
                      
                    </div>


                 
                </div>
                <div className="row  p-4" style={{ backgroundColor: '#081d3c' }}>
                    <div className="col-md-3 box__about" ><p className="about__content"><span className="about__number" >100+</span><br></br>Course</p></div>
                    <div className="col-md-3 box__about" ><p className="about__content" ><span className="about__number" >40+</span><br></br>Tutors</p></div>
                    <div className="col-md-3 box__about" ><p className="about__content" ><span className="about__number" >25k+</span><br></br>Students</p></div>
                    <div className="col-md-3"><p className="about__content"><span className="about__number" >4000+</span><br></br>Hours of Learning</p></div></div>
                {/*   <div class="col-md-12 d-flex justify-content-evenly p-4" style={{backgroundColor: '#081d3c', margin: '0 10px 0 10px'}}>
                <div><p className="about__content" ><span className="about__number">100+</span><br></br>Course</p></div><div className="about__subititle" ></div>
                <div><p className="about__content"><span className="about__number" >40+</span><br></br>Tutors</p></div><div className="about__subititle" ></div>
                <div><p className="about__content" ><span className="about__number" >25k+</span><br></br>Students</p></div><div className="about__subititle"></div>
                <div><p className="about__content" ><span className="about__number" >4000+</span><br></br>Hours of Learning</p></div>
            </div>  */}
            </div>
            <div className="container contact">
                <div className="row mt-5 ">

                    <div className="supp__us" >

                        <div className="round supports__us" >
                            <span><EmailIcon style={{ color: 'white' }} /></span>
                            <p className="mail" style={{ color: '#081d3c' }} >admin@i2globalschool.com</p>
                        </div>

                        <div className="round supports__us">
                            <span> <LocalPhoneIcon style={{ color: 'white' }} /></span>
                            <p className="mail" style={{ color: '#081d3c' }}>+919500064104</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about__bottom"></div>
            <Footer />
        </>

    )
}