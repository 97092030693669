import React from 'react'
import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import AssignmentListTable from './AssignmentListTable'

export default function ManageAssignment() {
  return (
    <div>
        <Header />
        <Sidebar />
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <AssignmentListTable />
                </div>
            </div>
        </div>
    </div>
  )
}
