import React, { useEffect, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken, s3config } from '../../../services/api';
import constant from '../../../constant/Validation';
import { toast } from "react-toastify";
import Images from '../../../constant/Images';
import S3 from "react-aws-s3";
import SelectForm from '../../../components/Forms/select/Select';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';



export default function AddNewBanner({ openDialogProp, onClose, onCancel }) {
    const BannerSchema = Yup.object({
        display_text: Yup.string().required('Please Select Image Position'),
        blob: Yup.string().required("Please choose a Image").trim(),
       /*  text_url: Yup.string().required('Please Enter the Link').nullable() */
    })
    const [open, setOpen] = useState(false);
    const [grade, setGrade] = useState([]);
    const blob_img = useRef(null);
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => {
        axiosToken.get("course/getcoursebasicdetails").then((res) => {
            setGrade(res.data.grade)
        })
    }, [])
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const initialValues = { display_text: '', blob: '',text_url:'' }
    function checkValidFile(inputID, exts, size) {
        if (inputID.size <= size) {
            return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
                inputID.name
            );
        }
    }
    let config = { ...s3config, dirName: "banner", };
    const positionData = [
        { id: 0, name: 'Top' },
        { id: 1, name: 'Center' },
        { id: 2, name: 'Bottom' }
    ]
    const ReactS3Client = new S3(config);
    const uploadFile = (file, name, setFieldValue, field_name) => {
        ReactS3Client.uploadFile(file, 'i2global-' +
            Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + '-' + name)
            .then((data) => { console.log(data); setFieldValue(field_name, data.key); })
            .catch((err) => console.log(err));
    };

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New Banner</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={BannerSchema}
                                onSubmit={(values) => {
                                    axiosToken.post('/admin/adminbanner', { details: [values] })
                                        .then((res) => {
                                            if (res) {
                                                toast.success(res.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                });
                                                handleClose();
                                            }
                                        })
                                        .catch((err) => {
                                            //console.log(err,'errrrrr')
                                            toast.error(err.response.data.detail, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                                    <>
                                        <div className="row no-gutters">
                                            {console.log(errors, 'errors banner', values)}
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <SelectForm
                                                                values={values.display_text}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                dorpdownValue={positionData}
                                                                error={Boolean(touched.display_text && errors.display_text)}
                                                                helperText={touched.display_text && errors.display_text}
                                                                label="Banner Position"
                                                                name="display_text"
                                                                dropwndownValueName="name"
                                                            />
                                                            {errors.display_text ? <span className='custom_err'>Please select banner position</span> : null}
                                                        </div>
                                                        <div className="col-md-6  position-relative banner">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Upload Banner"
                                                                variant="outlined"
                                                                error={Boolean(touched.blob && errors.blob)}
                                                                helperText={touched.blob && errors.blob}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                name="blob"
                                                                value={values.blob}
                                                            />
                                                            <span
                                                                className="upload__icones upload__icon"
                                                                style={{ marginRight: '0px !important' }}
                                                                onClick={() => { blob_img.current.click(); }}
                                                            >
                                                                <img src={Images.upload_icon} alt="upload" />
                                                            </span>
                                                            <span style={{ fontSize: '14px' }}>800X400 for better resolution</span>
                                                            <div className={"preview " + (values.blob ? "d-block" : "d-none")
                                                            }style={{position:'absolute', right:0}}>
                                                                <Tooltip title={'Remove'}
                                                                    onClick={() => { setFieldValue('blob', '');blob_img.current.value='' }}
                                                                    style={{ position: 'absolute', right: '-6px', top: '6px', background: 'snow', objectFit: 'cover' }}>
                                                                    <IconButton>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <img id='output' style={{ width: '100px', height: '100px', borderRadius: '20px', marginTop: '20px', marginLeft: 'auto', display: 'block' }} />
                                                            </div>
                                                            <input type="file" multiple id="file" ref={blob_img}
                                                                style={{ display: "none" }}
                                                                onChange={(e) => {
                                                                    if (checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)) {
                                                                        uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "blob");
                                                                        var output = document.getElementById("output");
                                                                        output.src = URL.createObjectURL(e.target.files[0]);
                                                                        output.onload = function () { URL.revokeObjectURL(output.src); };
                                                                    } else {
                                                                        toast.error("Please Select Image file.", { position: toast.POSITION.TOP_RIGHT, });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="text_url"
                                                                label="URL"
                                                               /*  error={Boolean(touched.text_url && errors.text_url)}
                                                                helperText={touched.text_url && errors.text_url} */
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.text_url}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div className="gender_btn btn_active mt-5" onClick={() => { handleSubmit() }} style={{ maxWidth: '150px' }}>
                                                        <h6 >Add Banner</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
