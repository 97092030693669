import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/Header/Header";
import { loading } from "../../store/actions";
import "../Quiz/ManageQuiz.css";
import QuizList from "./QuizLists/QuizList";
import StudentsList from "./StudentsList/StudentsList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { axiosToken } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Plus from "../../assets/icons/Plus.svg";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

export default function ManageQuiz({ data, handleClickOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [valuess, setValuess] = React.useState("1");
  const [value, setValue] = React.useState("1");
  const [quizData, setQuizdata] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [studentStatus, setStudentStatus] = useState(1);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    getQuizdata(newValue);
  };

  const handleStudentTabChange = (event, studentQuizValue) => {
    setValuess(studentQuizValue);
    setStudentStatus(studentQuizValue);
  };

  useEffect(() => {
    console.log(studentData);
  }, [studentData]);
  const getQuizdata = (val) => {
    axiosToken
      .get("tutor/tutorcreatequiz?status_id=" + val)
      .then((res) => {
        setQuizdata(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    getQuizdata(value);
    setTimeout(() => {
      dispatch(loading(false));
    }, 1000);
  }, []);

  /*  useEffect(() => {
    handleClickOpen(value);
    setTimeout(() => {
      dispatch(loading(false));
    }, 1000);
  }, []); */

  return (
    <div>
      <Header />
      <div className="container-fluid px-5 mt-5 mx-2" id="assignment__container_quiz">
        <div className="row">
          <div className="col-md-6 mt-3">            
          <h4 style={{fontSize:'20px', fontWeight:700,marginBottom: "20px"}} >Quiz</h4>
          </div>
          <div className="col-md-6 ">
            <div className="boxe__add ">
           
              <h6
                onClick={() => navigate("/manage-quiz/create-quiz")}
                className="assign_btns btn_active ml-auto"
                style={{ marginLeft: "auto", marginBottom: "20px" }}
              >
                Create Quiz
                <img src={Plus} className="Plus__icon" />
              </h6>
            </div>
          </div>
          <div className="col-md-7">
            <TabContext value={value}>
              <div className="list_box_header">
                <h4 className="assignment">Quizzes</h4>
                <TabList style={{minHeight:'22px'}}
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                  onChange={handleTabChange}
                >
                  <Tab label="Published" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} value="1" className="tab__black" />
                  <Tab label="Inprogress" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}value="0" className="tab__black" />
                </TabList>
              </div>
              <QuizList
                data={quizData}
                studentStats={studentStatus}
                tabValue ={value}
                onDataChange={(data) => {
                  setStudentData(data);
                }}
              />
            </TabContext>
          </div>
          <div className="col-md-5 ">
            <TabContext value={studentStatus}>
              <div className="list_box_header">
                <h4 className="assignment">Students List</h4>
                <TabList style={{minHeight:'22px'}}
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                  onChange={handleStudentTabChange}
                >
                  
                   <Tab label="Inprogress" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} values="1" className="tab__black" />
                   <Tab label="Completed" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} values="0" className="tab__black" />
                </TabList>
              </div>
              <StudentsList studentData={studentData} />
            </TabContext>
          </div>
        </div>
      </div>
      <div className="mb-100"></div>
       <Footer /> 
    </div>
  );
}
