import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import "../Support/Support.css";
import {
  Accordion,
  Box,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Images from "../../../constant/Images";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MessageIcon from "@mui/icons-material/Message";
import { Link } from "react-router-dom";
import { axiosToken } from '../../../services/api'
import Footer from "../../../components/Footer/Footer";
const ReactDOMServer = require("react-dom/server");
const HtmlToReactParser = require("html-to-react").Parser;
const CustomExpandIcon = () => {

  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <RemoveCircleOutlineIcon />
      </div>
      <div className="collapsIconWrapper">
        <img src={Images.add_icon} alt="imgg" className="add_icon" />
      </div>
    </Box>
  );
};

export default function Support() {

  const [faqData, setfaqData] = useState([])
  const [cmsDatas, setCmsDatas] = useState([])
  const htmlInput = "cmsDatas";
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(cmsDatas);
  const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
  useEffect(() => {
    axiosToken.get("student/getwebfaq").then((res) => {
      setfaqData(res.data.details);
    })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    axiosToken.get("/admin/get_cms_details?cms_key=support_web").then((res) => {
      setCmsDatas(res.data.about_app.cms_value)
      console.log(res.data.about_app.cms_value);

    })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <>
      <Header />
      <div className="container mt-5 mx-5 " id="assignment__container_quiz" style={{minHeight:"70vh"}}>
        {/*   <h4 className="supportheader">Support</h4> */}
        <h4 className="user_name">Support</h4>
        <p className="supportcontent">
          {reactElement}
        </p>

        <h4 className="user_name">FAQ</h4>

        <div className="supportcontent">
          {faqData.map((data, i) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>
                  {data.question}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  {data.answer}
                </p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div className="container">
          {/* <h5 className="contact">Contact Us</h5> */}
          <div className="row mt-5 ">
            <h4 className="contact___us">CONTACT US</h4>
            {/*    <div className="supp__us " style={{display: 'flex', alignItems:'center', textAlign:'center',justifyContent:'space-evenly',width:'100%',marginTop:'30px' }}> */}

            <div className="supp__us mt-3" >

              <div className="round supports__us" >
                <span><EmailIcon style={{ color: 'white' }} /></span>
                <p className="mail" style={{ color: '#081d3c' }} >admin@i2globalschool.com</p>
              </div>

              <div className="round supports__us">
                <span> <LocalPhoneIcon style={{ color: 'white' }} /></span>
                <p className="mail" style={{ color: '#081d3c' }}>+919500064104</p>
              </div>

              <div className="round supports__us">
                <span> <MessageIcon style={{ color: 'white' }} /></span>
                <p className="mail" style={{ width: '100px' }}>Chat with us</p>
              </div>

            </div>


            {/*    </div> */}
            <div className="about__bottom">   </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}
