import React, { useEffect, useState } from "react";
import Images from "../../constant/Images";
import "../Header/Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loading, toggleSidebar, userProfile } from "../../store/actions";
import MenuIcon from "@mui/icons-material/Menu";
import { axiosToken } from '../../services/api';
import constant from '../../constant/Validation';
import { isAdmin } from '../../helpers/utils';
import AdminProfile from "./AdminProfile";
import { useParams, NavLink } from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login'


function Header() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [menuText, setMenuText] = useState('');
  const [user, setUser] = useState(null);
  const [showAdmin, setshowAdmin] = useState(false);
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('user'))?.role[0])
  const menu = [
    { name: "Dashboard", value: "/dashboard" },
    { name: "Create Course", value: "/create-course" },
    { name: "Assignment", value: "/assignment" },
    { name: "Assignment", value: "/assignment/create-assignment" },
    { name: "Quiz", value: "/manage-quiz" },
    { name: "Quiz", value: "/manage-quiz/create-quiz" },
    { name: "Manage Students", value: "/manage-students" + id },
  ];
  const clientId ="580178913727-gg41gk9b71hgng6fecbtg0nsh2a20i1t.apps.googleusercontent.com";
  useEffect(() => {
    menu.map((d, i) => {
      if (d.value === location.pathname) {
        setMenuText(d.name);
      }
    });
  }, [location.pathname]);

  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    if (isAdmin() != 4) {
      axiosToken.get('/tutor/gettutordetails').then((res) => {
        setUser(res.data)
        dispatch(userProfile(res.data))
      }).catch((err) => { console.log(err.response, 'dddd') })
    } else {
      axiosToken.get('/admin/createsuperadmin')
        .then((res) => { setUser(res.data[0]); dispatch(userProfile(res.data[0])); console.log(res.data[0], 'admin profile data') })
        .catch((err) => { console.log(err.response) })
    }

  }, [])
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector("#header");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };
  const getProfileData = () => {
    axiosToken.get('/tutor/gettutordetails')
      .then((res) => {
        console.log(res.data)
        navigate('/profile', { state: { data: res.data } })
      })
      .catch((err) => { console.log(err.response) })
  }
  const getAdminProfile = () => {
    axiosToken.get('/admin/createsuperadmin')
      .then((res) => {
        setUser(res.data[0])
        setshowAdmin(true)
        console.log(res.data[0], 'admin profile')

      })
  }
  const onLogoutSuccess =()=>{
    console.log('ok')
  }
  // const { signOut, loaded } = useGoogleLogout({
   
  //   clientId,

  // });
 
  return (
    <div className="container-fluid" id="header">
      <AdminProfile openDialogProp={showAdmin} data={user} onCancel={() => { setshowAdmin(false) }} />
      <div className="row g-0">
        <div className="col-12">
          <div className="container-fluid px-4">
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <div className="header__left d-flex align-item-center">
                  {role == 4 ? <span style={{ marginTop: '25px', cursor: 'pointer' }}
                    onClick={() => { setMenuOpen(!menuOpen); dispatch(toggleSidebar(menuOpen)); }}
                  ><MenuIcon /></span> : null}
                  <img src={Images.Logo} alt="i2global-logo" className="logo" style={{ cursor: 'pointer', marginRight: '45px' }} onClick={() => { navigate('/') }} />
                  {role == 2 ? <div className="top_menu">
                    <div className="menu_container" style={{marginRight:'45px'}}>
                      <h4 className="active_menu">{menuText.length >=1 ?menuText:'Select'} <img src={require('../../assets/icons/Downarrow.png')} /></h4>
                      <div className="other_menus">
                        <a className="d-block mr-4" style={{ cursor: 'pointer' }} onClick={() => { navigate('/dashboard') }}>Dashboard</a>
                        <a className="d-block mr-4" style={{ cursor: 'pointer' }} onClick={() => { navigate('/create-course') }}>Create Course</a>
                        <a className="d-block mr-4" style={{ cursor: 'pointer' }} onClick={() => { navigate("/assignment"); }} >Assignment </a>
                        <a className="d-block mr-4" style={{ cursor: 'pointer' }} onClick={() => { navigate("/manage-quiz"); }} >Quiz</a> 
                      </div>
                    </div>
                  </div> : null}
                  {role == 2 ? <nav class="navbar navbar-expand-lg navbar-light ">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav mr-auto" style={{ width: '300px', marginTop: '8px' }} >

                        <li style={{ marginRight: '35px' }}>
                          <NavLink to="/about" className="nav-item nav-link" activeclassname="selected">
                            About Us</NavLink>
                        </li>
                     
                        <li style={{ marginRight: '35px' }}>
                          <NavLink to="/support" className="nav-item nav-link" activeclassname="selected">
                            Support</NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav> : null}


                </div>
                <div className="header__right">
                  <div className="d-flex">
                    <div className="header_user_profile">
                      <div className="profile__container">
                        {user?.profile_pic ? <img src={constant.common.bidBucket + user?.profile_pic} className="tutor_pic" /> : <img src={require('../../assets/img/img5.png')} className="tutor_pic" />}
                        <div className="header_tutor_info">
                          <h6 style={{textTransform:'capitalize'}}>{user?.name}</h6>
                          <img src={require('../../assets/icons/Downarrow.png')} />
                        </div>
                      </div>
                      <div className="profile_content_container">
                        <div className="menu_container">
                          <a className="link_header" onClick={() => {

                            isAdmin() == 2 ? getProfileData() : getAdminProfile()
                          }}> My Profile </a>
                          <a className="link_header"
                            onClick={() => {
                              dispatch(loading(true));
                              // signOut();
                              localStorage.clear();
                              setTimeout(() => { dispatch(loading(false)); navigate("/login"); }, 1000);
                            }}
                          > Logout </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
