import React from 'react';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
//import '../../AdminPages/ManageTeachers/ManageTeachers.css';
import ManageGradeListTable from './components/ManageGradeListTable';


export default function ManageGrade() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <ManageGradeListTable />
          </div>
        </div>
      </div>
    </div>
  )
}