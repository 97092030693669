import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from "@mui/material/";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function EditBoard({ openDialogProp, onClose,editData,onCancel }) {
    const BoardSchema  = Yup.object({
        board_name:Yup.string().required(constant.editBoards.board_name).trim(),
        sorting_order:Yup.number().typeError('Must be a numeric value').required(constant.addnewBoard.sorting_order)
    })

    const initialValues = {
        id:'',
        board_name:'',
        /* sorting_order:'' */
    }

    const [open, setOpen] = useState(false);
    const [stdData, setStdData] = useState()
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    
    useEffect(() => { 
        setOpen(openDialogProp);
        setStdData(editData) 
        initialValues.id = stdData?.id
        initialValues.board_name = stdData?.board_name
        initialValues.sorting_order = stdData?.sorting_order
        //initialValues.auth = stdData?.auth
    }, [openDialogProp,stdData])

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Edit Board</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={BoardSchema}
                                onSubmit={(values) => {
                                   axiosToken.put('/admin/create_board',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                      console.log(err,'errrrrr')
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                 <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="board_name"
                                                                label="Board Name"
                                                                error={Boolean(touched.board_name && errors.board_name)}
                                                                helperText={touched.board_name && errors.board_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.board_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="sorting_order"
                                                                label="Sorting Order"
                                                                error={Boolean(touched.sorting_order && errors.sorting_order)}
                                                                helperText={touched.sorting_order && errors.sorting_order}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.sorting_order}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>

                                                </div> 
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit()  }}

                                                    >
                                                        <h6 >Update Board</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5"  onClick={() => { onCancel(false) }}>
                                                        <h6>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )

    
}