import React, { useEffect, useState } from "react";
import "../QuizLists/QuizList.css";
import Images from "../../../constant/Images";
import { axiosToken } from "../../../services/api";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../../helpers/utils";
import { toast } from "react-toastify";
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';



export default function QuizList({ data, onDataChange, studentStats, tabValue }) {
  const navigation = useNavigate()
  useEffect(() => { }, [data]);
  useEffect(() => { handleClickOpen(studID); }, [studentStats]);

  const [tempData, setTempData] = useState([])
  const [hide, setHide] = useState(true);
  const [detect,setDetect] = useState(new Date());
  const [quizView, setquizView] = useState(null)
  const [open, setOpen] = React.useState(false);
  const [studID, setStudID] = useState(data);
  const handleClickOpen = (val) => {
    setOpen(true);
    axiosToken
      .get("tutor/tutorquizreport?quiz_set=" + val + "&status=" + studentStats)
      .then((res) => { let data = res.data; onDataChange(data); })
      .catch((err) => { console.log(err); });
  };

  useEffect(() => {
    setTempData(data?.slice(0, 5))
    data?.length >= 5 ? setquizView(true) : setquizView(false)
    setHide(false)    
}, [data])

useEffect(()=>{},[detect])

function viewLessMore() {
  setHide(true)
  setDetect(new Date())
setTempData(data)
}
function viewAll(){
  setDetect(new Date())
  setTempData(data?.slice(0, 5))
  setHide(false)
}

  const updateQuizStatus = (id) => {
    axiosToken.put('tutor/tutorcreatequiz?quiz_set=' + id)
      .then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload();
      })
      .catch((err) => { console.log(err) })
  }

  return (
    <>
    <div className="table_list__assign review">
      <table class="table assign__table">
        <thead>
          <tr>
            <th className="table__color">Title</th>
            <th className="table__color">Course</th>
            <th className="table__color">Class</th>
            <th className="table__color">Date</th>
            {/* <th className="table__color" align="center">
              Progress
            </th> */}
            <th className="table__color">Action  </th>
          </tr>
        </thead>
        <tbody>
          {tempData.map((d, i) => (
            <tr key={i}>
              <td className="py-3" style={{textTransform:'capitalize'}}>{d?.quiz_name}</td>
              <td className="py-3" style={{textTransform:'capitalize'}}>{d.course_name}</td>
              <td className="py-3">{d.course_grade}</td>
              <td className="py-3">{dateFormat(d.date)}</td>
              {/* <td>
                <div
                  className="progress_container"
                  style={{ marginTop: "15px" }}
                >
                  <div className="progress_box">
                    <span className="progress_bar"></span>
                  </div>
                </div>
              </td> */}
              <td>
                <div className="progress_container">

                  {tabValue == 1 ? <Tooltip title={'View Result'}
                    onClick={() => { handleClickOpen(d.id); setStudID(d.id); }}
                    style={{ cursor: 'pointer' }}>
                    <IconButton>
                      <AssignmentIcon />
                    </IconButton>
                  </Tooltip> :
                    <Tooltip title={'Publish Quiz'}
                      onClick={() => { updateQuizStatus(d.id) }}
                      style={{ cursor: 'pointer' }}>
                      <IconButton>
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>}

                  <Tooltip title="Edit" style={{ cursor: 'pointer' }}
                    onClick={() => {
                      axiosToken.get('tutor/updatequizset?id=' + d.id)
                        .then((res) => {
                          navigation('/manage-quiz/edit-quiz', { state: { data: res.data[0] } })
                        })
                        .catch((err) => {
                          //console.log(err.response.data,'ss')
                          toast.error(err.response.data.detail, {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        })
                    }}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>


                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    
    </div>
    {quizView ?
        <>
  {hide ? 
   <p className="view_all_btn" onClick={()=>viewAll()} >View Less</p>
    :  <p className="view_all_btn" onClick={()=>viewLessMore()} >View All</p>} </> : null}
    </>
    
  );
}
