const Register = {
  emailErrMsg: "Email is required",
  emialOnecharRegex: "^.*[a-zA-Z].*$",
  emailErrMail: "Email is not valid",
  nameErrMsg: "Name is required",
  passwordMinLen: 8,
  passwordOnenoRegex: "^.*[0-9].*$",
  passwordOnenoMsg: "Password should mix of letters, numbers & symbols",
  passwordOnecharRegex: "^.*[a-zA-Z].*$",
  passwordOnecharMsg: "Atleast one letter required",
  passwordErrMsg: "Password is required",
  matchErrMsg: "Passwords must match",
  acceptErrMsg: "Accept Terms & Conditions is required",
  validatenumErrMsg: "Only alphabets are allowed for this field",
};

const Login = {
  emailErrMail: "Email is not valid",
  emailErrMsg: "Email is required",
  passwordErrMsg: "Password is required",
  passwordMinLen: 8,
  passwordOnenoRegex: "^.*[0-9].*$",
  passwordOnenoMsg: "Password should mix of letters, numbers & symbols",
  passwordOnecharRegex: "^.*[a-zA-Z].*$",
  passwordOnecharMsg: "Atleast one letter required",
  acceptErrMsg: "Accept Terms & Conditions is required",
};

const OTPScreen = {
  emailErrMsg: "Email is required",
  passwordMinLen: 8,
  passwordOnenoRegex: "^.*[0-9].*$",
  passwordOnenoMsg:
    "Password must contain at least 8 characters, one uppercase, one number and one special case character",
  passwordOnecharRegex: "^.*[a-zA-Z].*$",
  passwordOnecharMsg: "Atleast one letter required",
  passwordErrMsg: "Password is required",
};

const OTPVerify = {
  otpErrMsg: "OTP is required",
  emailErrMail: "Email is not valid",
  emailErrMsg: "Email is required",
};

const CreatePassword = {
  passwordMinLen: 8,
  passwordOnenoRegex: "^.*[0-9][a-zA-Z].*$",
  passwordOnenoMsg: "Combine lowercase,uppercase letters,numbers & symbols",
  passwordOnecharRegex: "^.*[a-zA-Z].*$",
  passwordOnecharMsg: "Atleast one letter required",
  passwordErrMsg: "Password is required",
  matchErrMsg: "Passwords must match",
};

const ResetPassword = {
  emailErrMsg: "Email is required",
  passwordMinLen: 8,
  passwordOnenoRegex: "^.*[0-9].*$",
  passwordOnenoMsg: "Combine lowercase,uppercase letters,numbers & symbols",
  /*  passwordOnenoMsg:
    "Minimum 8 characters required mix of uppercase & lowercase letter, number & special character ", */
  passwordOnecharRegex: "^.*[a-zA-Z].*$",
  passwordOnecharMsg: "Atleast one letter required",
  passwordErrMsg: "Password is required",
  matchErrMsg: "Passwords must match",
};

const ProfileRegistration = {
  dobErrMsg: "Select the Date of Birth",
  genderErrMssg: "Choose the Gender",
  picErrMsg: "Need to provide a image",
  introErrMsg: "About yourself is required",
  doorErrMsg: "Please Enter the Door no ",
  addressErrMsg: "Please Enter the Address Line 1",
  addresssErrMsg: "Please Enter the Address Line 2",
  areaErrMsg: "Please Enter the Area",
  cityErrMsg: " Select the city is required",
  countryErrMsg: "Select the country is required",
  pincodeErrMsg: "Does not allow alphabets & special characters, only numbers are allowed",
  roleErrMsg: "Select the role is required",
  organizationErrMsg: "Please Enter the Organization",
  startdateErrMsg: "Please Enter the Date ",
  degreeErrMsg: "Select the degree is required",
  majorErrMsg: "Select the Major is required",
  gradeErrMsg: "Select the grade is required",
  collegeErrMsg: "Please enter the College Name",
  nameErrMsg: "Please enter the Name ",
/*   accErrMsg: "Accomplishment Name is required",
  certificateErrMsg: "certificate is required", */
  picsizeErrMsg: "Please Upload the Image",
  videoSizeErrMsg: "Please Upload the Video ",
  acceptErrMsg: "Accept Terms & Conditions is required",
};

const TutorProfile = {
  doorErrMsg: "Please Enter the Door no ",
  mailErrMsg: "Please enter the Mail",
  addressErrMsg: "Please Enter the Address",
   //address2ErrMsg: "Please enter the full address",
   cityErrMsg: " Select the city is required",
  dobErrMsg: "Select the Date of Birth",
  roleErrMsg: "Select the role is required",
  organizationErrMsg: "Please Enter the Organization",
  startdateErrMsg: "Please Enter the Date ",
  degreeErrMsg: "Select the degree is required",
  gradeErrMsg: "Enter the grade is required",
  majorErrMsg: "Select the Major is required",
  collegeErrMsg: "College Name is required",
  nameErrMsg: "Please enter the Name ",
  accErrMsg: "Name is required",
  picsizeErrMsg: "Please Upload the Image",
  certificateErrMsg: "certificate is required",
};

const Createcourse = {
  titleErrMsg: "Please enter Course title",
  categoryErrMsg: "Please Select any one Course category ",
  gradesErrMsgs: "Please Select any one grade",
  describeErrMsg: "Course Description is required",
  classtitleErrMsg: "Class title is required",
  dateErrMsg: "Date is required",
  starttimeErrMsg: "Start time is required",
  endtimeErrMsg: "End time is required",
  picsizeErrMsg: "Image is required",
  videoSizeErrMsg: "Video is required",
  contentErrMsg: "field required",
  tagErrMsg: "Tags is required",
  ReferenceErrorMsg: "Link is required",
  classNameErrMsg:'Class Name is required'
};

const CreateAssignment = {
  courseErrMsg: "Select the Course is required",
  introErrMsg: "Please enter title",
  intErrMsg: "Title is required",
  discriptionMsg: "Please enter Description",
  videoSizeErrMsg: "Please choose a Document",
  durationErrMsg: "Please Set Duration",
  assignemt_score:'Please Select Score Value'
};

const CreateQuiz = {
  quiz_name: "Please Enter Quiz Name",
  course_id: "Please Select Course",
  duration: "Please Select Quiz Duration.",
  question: "Please Enter Question Title",
  option1: "Please enter Option 1 Value",
  option2: "Please enter Option 2 Value",
  option3: "Please enter Option 3 Value",
  option4: "Please enter Option 4 Value",
  answer: "Please Choose any one option as a answer",
};

const addParent = {
  name:'Please enter Parent name',
  email:'Please enter Parent email',
  password:'Please enter Parent Password',
  valid_email:'Please enter valid email',
  grade:'Please Select the grade',
  mobile_no:'Please Enter Mobile no'
}

const editParent = {
  name:'Please enter Parent name',
  email:'Please enter Parent email',
  password:'Please enter Parent Password',
  valid_email:'Please enter valid email',
  mobile_no:'Please Enter Mobile no'
}

const addTutor ={
  name:'Please enter Tutor Name',
  email:'Please enter Tutor email',
  password:'Please enter Tutor password',
  grade:'Please Select Grade',
  valid_email:'Please enter valid email'
}

const editTutor ={
  name:'Please enter Tutor Name',
  email:'Please enter Tutor email',
  password:'Please enter Tutor password',
  grade:'Please Select Grade',
  valid_email:'Please enter valid email'
}

const addStudent ={
  name:'Please enter Student Name',
  email:'Please enter student email',
  password:'Please enter student password',
  grade:'Please Select Student Grade',
  valid_email:'Please enter valid email',
  gender:'Please Select the Gender'
}

const addGrades = {
  grade_name:'Please Enter the Grade'
}
const editStudent ={
  name:'Please enter Student Name',
  email:'Please enter student email',
  password:'Please enter student password',
  grade:'Please select student grade',
  valid_email:'Please enter valid email'
}
const addPackage ={
  package_name :"Please Enter Package Name",
  package_amount:"Please Enter Package Amount",
  package_description:"Please enter Package Description",
  package_discount_percentage:"Please Enter Discount",
  course_master:'Please Select Any course',
  duration:'Please Enter Duration',
  display_text:"Please Enter Duration Text"
}
const common = {
  jisti: "https://meet.jit.si/",
  bidBucket: 'https://i2global-w2s.s3-ap-south-1.amazonaws.com/'
};
const addnewBoard = {
  board_name: "Please Enter Board Name",
  sorting_order: "Please Enter the Numeric value"
} 

const addAcademy = {
  branch_location_name:'Please Enter the Location',
  city: 'Please Enter the City',
  state: 'Please Select the State',
  country:'Please Select the Country'
}

const addSchoolInfo = {
  academy_name: 'Please Enter Academy Name',
  academy_info: 'Please Enter Academy Info',
  academy_board_master:'Please Enter board value',
  academy_branch_master:'Please Enter branch value'
}
const addFaq = {
  question :'Please Enter the Question',
  answer:'Please Enter the Answer',
  type:'Please Select Web or Mobile'
}
const addCms ={
  key:'Please enter cms title',
  value:'Please enter cms value'
}

const addnewCategory = {
  tags: 'Please Enter the tags',
  interest_image: 'Please Upload the image'
}

const editBoards = {
  board_name:'Please Enter the Board Name',
  sorting_order: 'Please Enter the Sorting order'
}

const editLocation ={
  branch_location_name:'Please enter Student Name',
  academy_branch_city:'Please Select the City',
  state:'Please Select the State',
  country:'Please Select the country'
}

export default {
  Register,
  OTPScreen,
  OTPVerify,
  CreatePassword,
  Login,
  ProfileRegistration,
  Createcourse,
  ResetPassword,
  CreateAssignment,
  CreateQuiz,
  common,
  TutorProfile,
  addParent,
  addStudent,
  addTutor,
  addPackage,
  addnewBoard,
  addAcademy,
  addSchoolInfo,
  addFaq,
  addnewCategory,
  editStudent,
  editParent,
  editTutor,
  editBoards,
  editLocation,
  addCms,
  addGrades
};
