import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material/";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function AddAcademy({ openDialogProp, onClose, onCancel }) {
    const CitySchema = Yup.object({
        branch_location_name: Yup.string().required(constant.addAcademy.branch_location_name) .trim()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        city: Yup.string().required(constant.addAcademy.city).trim()
        //academic_branch_city:Yup.string().required(constant.addAcademy.academic_branch_city),
        /* state:Yup.string().required(constant.addAcademy.state),
        country:Yup.string().required(constant.addAcademy.country) */
    })

    const [open, setOpen] = useState(false);
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [city, getCity] = useState([])
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => {
        axiosToken.get("/admin/create_academymaster").then((res) => {
            setState(res.data.state)
            setCountry(res.data.country)
            getCity(res.data.city)
        })
    }, [])
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const initialValues = {
        branch_location_name: '',
        city: '',
    }
    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New Location</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={CitySchema}
                                onSubmit={(values) => {
                                    axiosToken.post('/admin/create_academymaster', values)

                                        .then((res) => {
                                            console.log(res)
                                            console.log(res.data.message)
                                            if (res.data.status) {
                                                toast.success(res.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                });
                                                handleClose();
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err, 'errrrrr')
                                            toast.error(err.response.data.detail, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="branch_location_name"
                                                                label="Location Name"
                                                                error={Boolean(touched.branch_location_name && errors.branch_location_name)}
                                                                helperText={touched.branch_location_name && errors.branch_location_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.branch_location_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">

                                                            <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select City
                                                                </InputLabel>
                                                                <Select style={{textTransform:'capitalize'}}
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='city'
                                                                    label="Select City"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.city && errors.city)}
                                                                    helperText={touched.city && errors.city}
                                                                >
                                                                    {city.map((e, key) => {
                                                                        return (
                                                                            <MenuItem key={key} value={e.id}>
                                                                                {e.city_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                            {errors.city?<p style={{color:'#d32f2f',fontSize:'12px',margin:0,textAlign:'left',marginLeft:'10px'}}>Please Select Any City</p>:null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        <h6 >Add Location</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )


}