import { IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../../services/api';
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import AddGrade from './AddGrade'
import EditGrade from './EditGrade'
export default function ManageGradeListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    useEffect(() => { console.log(add_std, 'sss') }, [add_std])
    useEffect(() => { console.log(edit_std, 'edit') }, [edit_std])
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`/course/create_grade?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getListBoardData = () => {
        axiosToken.get(`/course/create_grade?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/course/create_grade?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('course/create_grade?id=' + id + '&status=' + value)
            .then((res) => {
                if(res.data.status){
                console.log(res)
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            } 
            })
            .catch((err) => { console.log(err) })
    }
    useEffect(() => { getListBoardData() }, [])

    return (
        <>
            <AddGrade openDialogProp={add_std} onClose={() => { setAdd_std(false); getListBoardData() }} onCancel={() => { setAdd_std(false); }} />
            <EditGrade openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getListBoardData(); setEditData([]) }} onCancel={() => { setEdit_std(false); setEditData([]) }} />
            <div className='student_table_container' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">Dashboard</NavLink>
                        </li>
                        <li  className="breadcrumb-item active" aria-current="page" > Manage Grades</li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Grade</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Grades ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>

                                <TableCell>Grade Name</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row?.grade_name}</TableCell>

                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />

                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                            <Tooltip title="Edit Grade" arrow>
                                                <IconButton><EditIcon onClick={() => {
                                                    setEditData(row)
                                                    setEdit_std(true)
                                                }} /></IconButton></Tooltip>
                                            </span>

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}
