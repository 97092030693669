import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import constant from '../../../../constant/Validation';



export default function StudentQuizList({data}) {
    const [rows, setRows] = useState(data);
    useEffect(()=>{console.log(data)},[data])
    return (
        <div>
           
            <h6 style={{textAlign:'left',marginTop:'10px',fontFamily:'sans-serif',color:'#0b0b0b',fontWeight:700}}>Quiz Details</h6>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Quiz Name</TableCell>
                            <TableCell>Course</TableCell>
                            <TableCell>No of Questions</TableCell>
                            <TableCell>Total Marks</TableCell>
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                <TableCell>{row?.quiz_name}</TableCell>
                                <TableCell>{row?.course_name}</TableCell>
                                <TableCell>{row?.no_of_questions}</TableCell>
                                <TableCell>{row?.total_marks}</TableCell>
                               
                               
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data == ''? <h5 style={{textAlign:'center',width:'100%'}}>No Data Found</h5>:null }
            </TableContainer>
            {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
        </div>
    )
}
