import React, { useEffect, useState } from "react";
import Images from "../../constant/Images";
import { useNavigate } from "react-router-dom";
import "../Footer/Footer.css";

export default  function Footer() {
  const navigate = useNavigate();
  return (
    <footer id="sticky-footer " className="flex-shrink-0">
<div className="container-fluid" id='footer' >
<div className="row">
  <div className="col-sm-8 ">
    <p className="text_color mx-5 mt-3" style={{cursor:'pointer',margin:'0'}} onClick={() => { navigate('/terms') }}>Terms of use</p>
    <p className="text_color mx-5">© 2023 i2global school. All Rights Reserved</p>
  </div>
  <div className="col-sm-4 foot_play_store" >
  <img src={Images.PlayStore} alt="gplay" className="play___store" />
    <img src={Images.AppStore} alt="aplay" className="play___store" />             
  </div>
</div>

</div>
</footer>
  )
}