import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from "@mui/material/";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function EditStudent({ openDialogProp, onClose,editData,onCancel }) {
    const StudentSchema  = Yup.object({
        name:Yup.string().required(constant.editStudent.name).trim(),
       /*  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), */
        email:Yup.string().required(constant.editStudent.email).email(constant.editStudent.valid_email).trim(),
        //password:Yup.string().required(constant.addStudent.password),
        student_grade:Yup.string().required(constant.editStudent.grade_id),
        gender: Yup.string().required(constant.editStudent.gender),
        mobile:Yup.string().required(constant.addParent.mobile_no).min(10,'Please enter Valid Mobile').max(10,'Mobile No should be 10 digit only').matches(/^[0-9]*$/, "Only numbers are allowed for this field ").nullable(),
    })

    const initialValues = {
        id:'',
        auth:'',
        role: 'Student',
        email: '',
        student_grade: '',
        name: '',
        gender:'',
        mobile:''
    } 
    const [open, setOpen] = useState(false);
    const [grade, setGrade] = useState([]);
    const [stdData, setStdData] = useState()
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    
    const [genders,setGender] = useState([])
     useEffect(() => {
        axiosToken.get("course/getcoursebasicdetails").then((res) => {
            setGrade(res.data.grade)      
        })
       
    }, []) 
    useEffect(() => {
        axiosToken.get("/admin/gender").then((res) => {
            console.log(res.data)
            setGender(res.data)
        })
    }, []) 
    useEffect(() => { 
        setOpen(openDialogProp);
        setStdData(editData) 
        initialValues.name = stdData?.name
        initialValues.email = stdData?.email
        initialValues.student_grade = stdData?.grade_id
        initialValues.auth = stdData?.auth
        initialValues.id = stdData?.id
        initialValues.gender = stdData?.gender
        initialValues.mobile = stdData?.mobile
    }, [openDialogProp,stdData])
   
    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Edit Student</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={StudentSchema}
                                onSubmit={(values) => {
                                   axiosToken.put('/admin/managestudents',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                      console.log(err,'errrrrr')
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                                 {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                 <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="name"
                                                                label="Student Name"
                                                                error={Boolean(touched.name && errors.name)}
                                                                helperText={touched.name && errors.name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="email"
                                                                label="Student Email"
                                                                error={Boolean(touched.email && errors.email)}
                                                                helperText={touched.email && errors.email}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.email}
                                                                disabled={true}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <div className='col-md-6'>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Grade
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='student_grade'
                                                                    label="Select Grade"
                                                                    value={values.student_grade}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.student_grade && errors.student_grade)}
                                                                    helperText={touched.student_grade && errors.student_grade}
                                                                >
                                                                      {grade.map((e, key) => {
                                                                        return (
                                                                            <MenuItem key={key} value={e.id}> {e.grade_name}</MenuItem>
                                                                        );
                                                                    })}  
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                       
                                                       <div className='col-md-6'>
                                                       <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select 
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='gender'
                                                                    label="Select Gender "
                                                                    onChange={handleChange}
                                                                    error={Boolean(touched.gender && errors.gender)}
                                                                    helperText={touched.gender && errors.gender}
                                                                    onBlur={handleBlur}
                                                                    value={values.gender}

                                                                >
                                                                    { genders.map((e, key) => {
                                                                        return (
                                                                           <MenuItem key={key} value={e}>{e}</MenuItem>
                                                                        );
                                                                    })} 
                                                                </Select>
                                                            </FormControl>
                                                       </div>

                                                       <div className='row mt-4'>
                                                    <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text" 
                                                                name="mobile"
                                                                label="Mobile No"
                                                                error={Boolean(touched.mobile && errors.mobile)}
                                                                helperText={touched.mobile && errors.mobile}
                                                                onBlur={handleBlur}  
                                                                onChange={handleChange}
                                                                value={values.mobile}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div> 
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit()}}
                                                    >
                                                        <h6 >Update Student</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false);}}>
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )

    
}