import React,{ useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import "../Terms/Terms.css";
import Footer from "../../../components/Footer/Footer";
import { axiosToken } from '../../../services/api'
const ReactDOMServer = require("react-dom/server");
const HtmlToReactParser = require("html-to-react").Parser;
export default function Terms() {

  const [cmsDatas, setCmsDatas] = useState([])
  const htmlInput = "cmsDatas";
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(cmsDatas);
  const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  useEffect(() => {
    axiosToken.get("admin/get_cms_details?cms_key=terms_web").then((res) => {
      setCmsDatas(res.data.about_app.cms_value)
      console.log(res.data.about_app.cms_value);
    })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <>
      <Header />
      <div className="container mt-5 mx-5" id="assignment__container_term">
       {/*  <h4 className="terms1">Terms of Use</h4> */}
       <h4 className="user_name">Terms of Use</h4>
        <p className="paratext mt-4">
        {reactElement} 
        </p>
      </div>
      <div className="about__bottomss"></div>
        <Footer />  
    </>
  );
}
