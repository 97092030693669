import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import "../ForgotPassword/ForgotPassword.css";
import Images from "../../constant/Images";
import constant from "../../constant/Validation.js";
import { apiCall } from "../../services/api";
import { useDispatch } from "react-redux";
import { loading } from "../../store/actions";
import { toast } from "react-toastify";
const OTPSchema = Yup.object({
  email: Yup.string().email().required(constant.OTPScreen.emailErrMsg),
});

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailid, setEmail] = useState();
  const initialValues = { email: "", role_type: 2 };
  useEffect(() => {
    dispatch(loading(false));
  }, []);
  return (
    <>
      <div className="containere_pwd" id="forgot__container">
        <img className="imglength" src={Images.Bg} alt="black" />
        <div className="centered_pwd">
          <Formik
            initialValues={initialValues}
            validationSchema={OTPSchema}
            onSubmit={(values) => {
              //setEmail(values.email)
              //console.log(values, "values");
              localStorage.setItem("email", values.email);
              const payload = {
                email: values.email,
                role_type: "tutor",
              };

              

                  apiCall.post("admin/otpemailforgottutor", payload).then((res) => {
                    toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT,});
                    navigate("/forgot-otp");
                  }).catch((err)=>{
                    console.log(err.response.data)
                    toast.error(err.response.data.detail, {position: toast.POSITION.TOP_RIGHT,});
                  });
               
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
              values,
              touched,
            }) => (
              <div className="forgot__pwd">
                <Form>
                  <div className="spacebtwn">
                    <div className="forgot_flex">
                      <h5 className="text__mail">Enter your email</h5>
                      <a href="/login" className="close_forgoticon_forgot"></a>
                    </div>
                    <div className="forgot-textfield">
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        //id="otp-email-input"
                        id="forgot-input"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        value={values.email}
                        type="text"
                        name="email"
                        label="Email"
                        variant="outlined"
                      />
                    </div>
                  </div>

                  <Button type="submit" className="btn btn-danger"
                      style={{
                        width:'100%',
                        maxWidth: "310px",
                        background: "rgba(222,62,90,255)",
                        color: "white",
                      }}>
                    Send OTP
                  </Button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
