import React, { useEffect, useRef, useState } from 'react'
import { axiosToken, s3config } from '../../../../services/api';
import S3 from "react-aws-s3";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Images from '../../../../constant/Images';
import { loading } from '../../../../store/actions';
import { useDispatch } from 'react-redux';


export default function UploadRecordVideo({ onClose, data, onCancel, refresh }) {
    const dispatch = useDispatch();
    const upload = useRef(null);
    const [video_name, setVideoName] = useState('');
    const [course_image_name, setcourse_image_name] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [thumbLink, setthumbLink] = useState('');
    const uploadVideo = useRef(null);
    const uploadVideofn = (payload) => {
        axiosToken.post('student/recorded_classes', payload)
            .then((res) => {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                onCancel(false);
                refresh(true)
            })
            .catch((err) => {
                toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT, });
            })
    }
    let config = { ...s3config, dirName: "class_video", };
    useEffect(() => { console.log(data) }, [])
    const ReactS3Client = new S3(config);
    const uploadFile = (file, name, field_name) => {
        dispatch(loading(true))
        ReactS3Client.uploadFile(file,
            "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name
        )
            .then((data) => {
                console.log(data)
                field_name === 'course_image' ? setcourse_image_name(name) : setVideoName(name);
                field_name === 'course_image' ? setthumbLink(data.key) : setVideoLink(data.key)
                dispatch(loading(false))
            })
            .catch((err) => { console.log(err); dispatch(loading(false)) });
    };
    function checkValidFile(inputID, exts, size) {
        if (inputID.size <= size) {
            return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(inputID.name);
        }
    }

    return (
        <div className='upload_container'>
            <div className="photo_upload form_field position-relative">
                <TextField id="outlined-basic" label="Course Recorded Video" variant="outlined" name="course_intro" value={video_name} />
                <span className="upload__icon" onClick={() => { uploadVideo.current.click(); }}>
                    <img src={Images.upload_icon} alt="upload_icon" />
                </span>
                <span className="form_des_info">(Please upload MP4 File )</span>
                {!video_name ? <span className='form_err'>Please Select Video</span> : null}
                <div className={"profile_image " + (video_name ? "d-block" : "d-none")}>
                    <video id="videoPreview" width="320" height="240" controls>
                        Your browser does not support the video tag.
                    </video>
                    <h6 onClick={() => { uploadVideo.current.value = ""; setVideoName(''); setVideoLink('') }}
                        className="close__icon"
                        style={{ left: "60%", top: "21px" }}
                    >
                        <CloseIcon />
                    </h6>
                </div>
                <input type="file" id="file" accept="video/*" ref={uploadVideo}
                    style={{ display: "none" }}
                    onChange={(e) => {
                        if (checkValidFile(e.target.files[0], [".mp4", '.webm'], 2000000000)) {
                            uploadFile(e.target.files[0], e.target.files[0].name, "course_intro");
                            var output = document.getElementById("videoPreview");
                            output.src = URL.createObjectURL(e.target.files[0]);
                            output.onload = function () { URL.revokeObjectURL(output.src); };
                        } else {
                            toast.error(
                                "Please Select Valid Video file or Size.",
                                { position: toast.POSITION.TOP_RIGHT, }
                            );
                        }
                    }}
                />
            </div>
            <div className="photo_upload form_field position-relative" style={{ height: "100px" }}>
                <TextField id="outlined-basic" label="Video Thumbnail"variant="outlined" value={course_image_name} />
                <span className="upload__icon" onClick={() => { upload.current.click(); }}>
                    <img src={Images.upload_icon} alt="upload_icon" />
                </span>
                <span className="form_des_info">(jpg/png files not more than 200kb Preferred resolution : 800X400 )</span>
                {!course_image_name ? <span className='form_err'>Please Select Thumbnail Image</span> : null}
                <div className={"profile_image " + (course_image_name ? "d-block" : "d-none")}>
                    <img id="output" src="#" className="image_preview" />
                    <h6 onClick={() => { upload.current.value = ""; setcourse_image_name(''); setthumbLink('') }} className="close__icon">
                        <CloseIcon />
                    </h6>
                </div>
                <input
                    type="file"
                    id="file"
                    ref={upload}
                    style={{ display: "none" }}
                    onChange={(e) => {
                        if (checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)) {
                            uploadFile(e.target.files[0], e.target.files[0].name, "course_image");
                            var output = document.getElementById("output");
                            output.src = URL.createObjectURL(e.target.files[0]);
                            output.onload = function () { URL.revokeObjectURL(output.src) };
                        } else {
                            toast.error(
                                "Please Select Valid Image. or Check image Size", { position: toast.POSITION.TOP_RIGHT, }
                            );
                        }
                    }}
                />
                <div className='btn__actions text-left'>
                    <div className="submit_btn_container">
                        <div className="gender_btn__cc btn_active" style={{ maxWidth: "200px" }}
                            onClick={() => {
                                let payload = {
                                    "course_master": data.course,
                                    "class_master": data.id,
                                    "tutor": data.tutor,
                                    "blob": videoLink,
                                    "thumb_nail": thumbLink
                                }
                                if (videoLink && thumbLink) {
                                    uploadVideofn(payload)
                                }
                            }}
                        >
                            <h6>Save</h6>
                        </div>
                        <div className="gender_btn__cc">
                            <h6 onClick={() => { onCancel(false) }}>Cancel</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
