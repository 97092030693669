import React, { useState, useEffect } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { axiosToken } from '../../../services/api';
import AssignmentDialog from '../../../components/AssignmentDialog/AssignmentDialog';
import { useSelector } from 'react-redux';
import '../AssignmentReview/AssignmentReview.css'
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { dateFormat } from '../../../helpers/utils'
import Images from '../../../constant/Images'
import constant from '../../../constant/Validation'

export default function AssignmentReview() {
    const { reducer } = useSelector(state => state);
    const [reviewData, setReviewData] = useState([]);
    const [openDialog, setOpenDiglog] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [type, setype] = useState('approve');
    const [id, setId] = useState(null);
    useEffect(() => { })
    const handleChangePage = (event, newPage) => {
        console.log(newPage,'pagessssss')
        setCurrentPage(newPage)
        axiosToken.get(`/tutor/tutorreviewassignment?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setReviewData(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/tutor/tutorreviewassignment?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setReviewData(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getAssignmentData = () => {
        axiosToken.get(`/tutor/tutorreviewassignment?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => { 
                setTotal(res.data.total_count);
                setReviewData(res.data.data);
             })
            .catch((err) => { console.log(err); });
    };
    useEffect(() => { getAssignmentData() }, [reducer.assignmentReload])
    const ApproveMsg = {
        title: 'Are You sure want to Approve This?',
        content: 'Please click on yes if you want to approve this assignment or click cancel to close this.'
    }
    const RejectMsg = {
        title: 'Are You sure want to Reject This?',
        content: 'Please click on yes if you want to reject this assignment or click cancel to close this.'
    }
    function downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.target = '_blank'
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();
    }
    useEffect(() => { getAssignmentData(); }, []);
    return (

        <div>
            <AssignmentDialog openDialogProp={openDialog} id={id} type={type} dialogData={dialogData} />
            <div className="list_box_header_assg">
                <h5 className="assignment__text">Assignments Review</h5>
            </div>

            <div className="table_list__assign review">
                <table class="table assign__table">
                    <thead>
                        <tr>
                            <th className="table__color">Name</th>
                            <th className="table__color">Course</th>
                            <th className="table__color">Class</th>
                            <th className="table__color" style={{ width: '156px',whiteSpace:'nowrap' }}> Submitted date</th>
                            <th className="table__color" style={{paddingLeft:'15px'}} >Status</th>
                            <th className="table__color" align="center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviewData.map((d, i) => (
                            <tr key={i}>
                                <td className="py-3" style={{width:'28%',maxWidth:'100px'}}>
                                    {d?.student_profile ? <img src={d?.student_profile} className="studentss_pics" /> : <img src={Images.NoImg} className='tutor_img' />}&nbsp;
                                   {d.student_name}</td>
                                <td className="py-3">{d.course_name}</td>
                                <td className="py-3" style={{width:'15%'}}>{d.class}</td>
                                <td className="py-3">{dateFormat(d.submitted_date)}</td>

                                <td className="py-3">
                                    {d.status == 2 ?
                                        <span className='status pending_assignment'>Pending</span>
                                        : d.status == 3 ?
                                            <span className='status redo_assignment'>Redo</span>
                                            : d.status == 4 ?
                                                <span className='status completed_assingnment'>Completed</span>
                                                : null
                                    }
                                </td>
                                <td>
                                    <div className='action_cont'>
                                        {d.status == 2  ?
                                            <>
                                                <Tooltip title="Approve">
                                                    <span style={{ cursor: 'pointer' }} onClick={() => {
                                                        setDialogData(ApproveMsg);
                                                        setOpenDiglog(false);
                                                        setId(d.id)
                                                        setTimeout(() => { setOpenDiglog(true); }, 100);

                                                    }}> <CheckIcon /></span></Tooltip>
                                                <Tooltip title="Redo">
                                                    <span title="Redo" style={{ cursor: 'pointer' }} onClick={() => {
                                                        setype('redo')
                                                        setOpenDiglog(false);
                                                        setDialogData(RejectMsg);
                                                        setId(d.id)
                                                        setTimeout(() => { setOpenDiglog(true); }, 100);

                                                    }}>  <CloseIcon /></span></Tooltip></>
                                            : null}
                                        <Tooltip title="Download"
                                            style={{ cursor: 'pointer' }} onClick={() => { downloadFile(d.blob) }}>
                                            <FileDownloadIcon />
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

             {/*  </TableContainer>  */}
            </div>
            <TablePagination 
                className='cust_page'
                rowsPerPageOptions={[5, 10, 20]} 
                component="div"
                count={total}
                rowsPerPage={rowPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}
