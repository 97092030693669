import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import DasboardBox from "./components/DashboardBox/DasboardBox";
import Courses from "./components/Courses/Courses";
import Images from "../../constant/Images";
import Sidebar from '../../components/Sidebar/Sidebar'
import "../Dashboard/Dashboard.css";
import { useSelector } from "react-redux";
import Quizes from "./components/Quizes/Quizes";
import AssignmentDashboard from "./components/AssignmentDashboard/AssignmentDashboard";
import { Calendar } from "react-multi-date-picker"
import Footer from "../../components/Footer/Footer";
export default function Dashboard() {
  const showSidebar = useSelector(state=>state.reducer.isSidebarOpen);
  const [date,setDate] = useState([]);
  const [formatedData,setFormatedDate] = useState([]);
  useEffect(()=>{
    let dates =[]
    date.map((d)=>{ dates.push(d.format())})
     setFormatedDate(dates)
  },[date])
  useEffect(()=>{console.log('date changed')},[formatedData])
  
  return (
    <div>
      <Header />
     {/*  <Sidebar /> */}
      <div className="container-fluid" id="dashboard__container" style={{width:showSidebar?'100%':'95%'}}>
        <div className="row">
          <div className="col-lg-10 col-md-9 ">
         
            <DasboardBox />
            {/* TASK LIST BOXSES START */}

            <div className="list_box_container">
              <Courses  date={formatedData}/>

              <div className="list_box assignments">
              <AssignmentDashboard  date={formatedData}/>
                {/*  <div className="list_box_header"> 
                   <h4>Asssignment</h4> 
                 
                 </div>  */}
              </div>
              <div className="list_box assessments">
              <Quizes />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 mt-5 calenderOverFlow">
            <div className="calender">
            <Calendar multiple onChange={(e)=>{setDate(e)}}  format="YYYY-MM-DD"/>
            </div>
          </div>
          {/* TASK LIST BOXSES END */}

          {/* COL-MD-10 END */}
          {/* <div className="col-md-2"></div> */}
        </div>
      </div>
      <div className="mb-100"></div>
      <Footer /> 
    </div>
  );
}
