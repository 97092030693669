import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import StudentClassList from './studentClassList';
import { axiosToken } from '../../../../services/api';
import StudentAssignmentList from './StudentAssignmentList';
import StudentQuizList from './StudentQuizList';
import StudentAttendance from './StudentAttendance';



export default function StudentReport({ openDialogProp, onClose,id }) {
  
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [showAttendance,setShowAttendance] = useState(false);
    const [attData,setAttData] = useState([]);
    const handleClose = () => {
        onClose(false)
        setOpen(false);
        setShowAttendance(false)
    };
  
    useEffect(() => { setOpen(openDialogProp);
        if(id){
            getStudentReport();
        }
    }, [openDialogProp])
    const getStudentReport = () =>{
        axiosToken.get('student/studentreport?id='+id)
        .then((res)=>{
            setData(res.data)    
        })
        .catch((err)=>{console.log(err.response)})
    }

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'lg'}
                maxWidth={'lg'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                 <span className='close__btn'
                onClick={()=>{handleClose()}}
            >
                <CloseIcon />
            </span>
                <DialogTitle>Student Report</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                           {showAttendance?  <StudentAttendance trigger={()=>{setShowAttendance(false)}}  data={attData}/>: 
                           <>
                           <StudentClassList StudentID={id} attendanceData={(e)=>{setAttData(e)}} data={data.course_data} trigger={()=>{setShowAttendance(true)}} />
                           <StudentAssignmentList data={data.assignment_data} />
                            <StudentQuizList data={data.quiz_data} />
                            </>
                           }
                           
                            
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
