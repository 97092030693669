import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isAdmin } from "../../helpers/utils";
import '../Sidebar/Sidebar.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toggleMaster } from "../../store/actions";



const Sidebar = () => {
  const dispatch = useDispatch();
  const [masterSubMenu, setMasterSubMenu] = useState(null);
  const showSidebar = useSelector(state => state.reducer.isSidebarOpen);
  const masterMenu = useSelector(state => state.reducer.isMastermenuOpen);
  const check = localStorage.getItem('master');
  const [detect, setDetect] = useState(new Date());

  useEffect(() => {
    if (localStorage.getItem('master') == 'true') {
      console.log('mmm')
      setMasterSubMenu(true);
    } else {
      setMasterSubMenu(false)
    }
    console.log('tttt', localStorage.getItem('master'))
  }, []);


/*   const TeacherMenu = () => (
    <ul >
      <li>
        <NavLink to="/dashboard" className="nav_link" activeClassName="activeRoute">
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink to="/create-course" className="nav_link" activeClassName="activeRoute">
          Create Course
        </NavLink>
      </li>
      <li>
        <NavLink to="/assignment" className="nav_link" activeClassName="activeRoute">
          Manage Assignment
        </NavLink>
      </li>
      <li>
        <NavLink to="/manage-quiz" className="nav_link" activeClassName="activeRoute">
          Manage Quizzes
        </NavLink>
      </li>
    </ul>
  ) */
  const adminMenu = () => (
    <div >
    <ul>
      <li>
        <NavLink to="/dashboard" className="nav_link" activeClassName="activeRoute">
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-students" className="nav_link" activeClassName="activeRoute">
          Manage Students
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-parents" className="nav_link" activeClassName="activeRoute">
          Manage Parents
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-teachers" className="nav_link" activeClassName="activeRoute">
          Manage Tutors
        </NavLink>
      </li>
      <li className="sub_menu" >
        <a className="nav_link sub_menu_link d-flex justify-content-between"
          onClick={() => {
            if (masterSubMenu) {
              localStorage.setItem('master', false)
              setMasterSubMenu(false);
              setDetect(new Date())
            } else {
              localStorage.setItem('master', true)
              setMasterSubMenu(true);
              setDetect(new Date())
            }
          }}>
          Manage Masters <KeyboardArrowDownIcon />
        </a>
        <div className="">
          {masterSubMenu ? <ul>
            <li>
              <NavLink to="/admin/manage-board" className="nav_link" activeClassName="activeRoute">
                Manage Boards
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/academy-master" className="nav_link" activeClassName="activeRoute">
                Manage Location
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/academy-info" className="nav_link" activeClassName="activeRoute">
                Manage Institution
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/manage-grade" className="nav_link" activeClassName="activeRoute">
                Manage Grades
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/manage-category" className="nav_link" activeClassName="activeRoute">
                Manage Interests
              </NavLink>
            </li>
           
          </ul> : null}
        </div>
      </li>
      {/* <li>
        <NavLink to="/admin/academy-images" className="nav_link" activeClassName="activeRoute">
           Academy Images
        </NavLink>
      </li>  */}
      <li>
        <NavLink to="/admin/manage-courses" className="nav_link" activeClassName="activeRoute">
          Manage Courses
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-package" className="nav_link" activeClassName="activeRoute">
          Manage Packages
        </NavLink>
      </li>
     
      <li>
        <NavLink to="/admin/manage-assignments" className="nav_link" activeClassName="activeRoute">
          Manage Assignments
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-quizes" className="nav_link" activeClassName="activeRoute">
          Manage Quizzes
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-banners" className="nav_link" activeClassName="activeRoute">
          Manage Banners
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-faq" className="nav_link" activeClassName="activeRoute">
          Manage FAQ
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-cms" className="nav_link" activeClassName="activeRoute">
          Manage CMS
        </NavLink>
      </li>
      <li>
        <NavLink to="/admin/manage-notification" className="nav_link" activeClassName="activeRoute">
          Manage Notification
        </NavLink>
      </li>
    </ul>
    </div>
  ) 

  return (
    <div
      id='style-3'
      collapsedWidth="0"
      style={{
        width: showSidebar ? '280px' : '0',
        //  display: showSidebar? 'block': 'none',
        height: "100vh",
        backgroundColor: 'rgb(226 45 75)',
        transition: 'all 0.3s ease-in-out',
        float: "left",
       
      }}
    >
      <div className="side_menu"
        style={{
          display: showSidebar ? 'block' : 'none',
          opacity: showSidebar ? '1' : '0',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {isAdmin() === 4 ? adminMenu() : null}

      </div>
    </div>

  );

};

export default Sidebar;
