import React from 'react'
import './VideoMeet.css';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

export default function WaitingScreen({progress}) {
  return (
    <div className='waitting_screen'>
          <div className='text-center'>
              <h2>Please Wait Your video is Uploading.... {progress}%</h2>
              <LinearProgress variant="determinate"  value={progress} />
              <h5> Don't Close this Page,After completing upload this page will close automatically. </h5>
          </div>
    </div>
  )
}
