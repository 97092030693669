import React from 'react'
import Header from '../../components/Header/Header'
import '../Course/Course.css'

export default function Course() {
  return (
    <div className='page_bg'>
      <Header />
      <div className='container'>
        <div className='view_class_header'>
          {/* <span className='course_header'>Courses</span> */}
          <h4 className='page_title'>Courses</h4>
        </div>
        <div className='course_body'>
          <div className='course_box'>
            <h6 className='course_secondary_title'>InProgress (3)</h6>
            <div className='row'>
                <div className='col-md-3'>
                    <div className='course__box'>
                      
                    </div>  
                </div>  
                <div className='col-md-3'>2</div>  
                <div className='col-md-3'>3</div>  
                <div className='col-md-3'>4</div> 
            </div>
          </div>
          <div className='course_box'>
            <h6 className='course_secondary_title'>Published (4)</h6>
            <div className='row'>
                <div className='col-md-3'>1</div>  
                <div className='col-md-3'>2</div>  
                <div className='col-md-3'>3</div>  
                <div className='col-md-3'>4</div> 
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
