import React from "react";
import "../OTPSuccess/OTPSuccess.css";
import Images from "../../constant/Images";
function OTPSuccess() {
  return (
    <div className="container">
      <div className="Appd col-md-12 d-flex justify-content-center">
        <div className="card_otp_success centere">
          {/*   <img src={Images.Verify} alt="blac" className="bgimgg" /> */}
          <img src={Images.OTPSuccess} alt="otpsuccess" />
          <p className="mobile">Your account has been verified</p>
          <b>Successfully</b>
        </div>
      </div>
    </div>
  );
}

export default OTPSuccess;
