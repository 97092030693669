import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { loading } from '../../../store/actions'
import ContentList from './ContentList'


export default function CmscontentList() {
    const dispatch = useDispatch();
    useEffect(()=>{dispatch(loading(false))},[])
  return (
    <div className='manage_cms'>
        <Header />
        <Sidebar />
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <ContentList />
                </div>
            </div>
        </div>
    </div>
  )
}
