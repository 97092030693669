import React, { useRef, useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import "./ProfileRegisteration.css";
import Scrollspy from "react-scrollspy";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import Images from "../../../constant/Images";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import constant from "../../../constant/Validation";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import S3 from "react-aws-s3";
import FormHelperText from "@mui/material/FormHelperText";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { API_ENDPOINT, axiosToken, s3config } from "../../../services/api";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { loading } from "../../../store/actions";
import Tooltip from "@mui/material/Tooltip";
import Footer from "../../../components/Footer/Footer";
import ProfHeader from "../../../components/Header/ProfHeader"
window.Buffer = window.Buffer || require("buffer").Buffer;
//FORM DEFAULT DATA

const ProfileRegisterSchema = Yup.object().shape({
  dob: Yup.string().required(constant.ProfileRegistration.dobErrMsg),
  info: Yup.string().required(constant.ProfileRegistration.introErrMsg).trim(),
  doorno: Yup.string().required(constant.ProfileRegistration.doorErrMsg).trim(),
  address_1: Yup.string().required(constant.ProfileRegistration.addressErrMsg).trim(),
  //address_2: Yup.string().required(constant.ProfileRegistration.addresssErrMsg).trim(),
  area: Yup.string().required(constant.ProfileRegistration.areaErrMsg).trim().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  city: Yup.string().required(constant.ProfileRegistration.cityErrMsg),
  country: Yup.string().required(constant.ProfileRegistration.countryErrMsg),
  pincode: Yup.string()
  .required().nullable()
  .min(6, 'Pincode allows upto 6 digits')
  .max(6, 'Pincode allows upto 6 digits'),
  profile_pic: Yup.string()
    .required(constant.ProfileRegistration.picsizeErrMsg),
  intro_video: Yup.string()
    .required(constant.ProfileRegistration.videoSizeErrMsg),
  workexperience: Yup.array(
    Yup.object().shape({
      role: Yup.string().required(constant.ProfileRegistration.roleErrMsg),
      organization: Yup.string().trim().required(
        constant.ProfileRegistration.organizationErrMsg
      ),
      /*  from_date: Yup.date().required(
         constant.ProfileRegistration.startdateErrMsg
       ),
       to_date: Yup.date().required(
         constant.ProfileRegistration.startdateErrMsg
       ), */
    })
  ),

  educationdetails: Yup.array(
    Yup.object().shape({
      degree: Yup.string().required("Select a degree "),
      major: Yup.string().required("Select a major "),
      grade: Yup.string().trim().required(constant.ProfileRegistration.gradeErrMsg),
      college_name: Yup.string().trim().required
        (constant.ProfileRegistration.collegeErrMsg)
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      /*  from_date: Yup.date().required
         (constant.ProfileRegistration.startdateErrMsg),
       to_date: Yup.date().required(constant.ProfileRegistration.startdateErrMsg), */
      /* certificate: Yup.string().required(constant.ProfileRegistration.certificateErrMsg)
      .trim()
      .matches(/^[aA-zZ\s]+$/, constant.Register.validatenumErrMsg), */
    })
  ),
  // accomplishment: Yup.array(
  //   Yup.object().shape({
  //     name: Yup.string().required(constant.ProfileRegistration.accErrMsg)
  //     .trim()
  //     .matches(/^[aA-zZ\s]+$/, constant.Register.validatenumErrMsg),
  //   })
  // ),
  acceptTerms: Yup.bool().oneOf([true], constant.Register.acceptErrMsg),
});

const genderBtn = [
  { id: 1, name: "Male", isSelect: true, },
  { id: 2, name: "Female", isSelect: false, },
  { id: 3, name: "Others", isSelect: false, },
];

export default function ProfileRegisteration() {
  const [count, setCount] = useState('');
  useEffect(() => {
    axiosToken.get('tutor/tutor_dashboard_count')
      .then((res) => { setCount(res.data) }).catch((err) => { console.log(err) })
  }, [])

  //form Image Uploads
  const dispatch = useDispatch();
  //form Image Uploads end
  const profile_details = useRef(null);
  const address_details = useRef(null);
  const work_experience = useRef(null);
  const educational_details = useRef(null);
  const accomplishments = useRef(null);
  const navigate = useNavigate();
  const [change, detectChange] = useState(null);
  const [city, getCity] = useState([]);
  const [country, getCountry] = useState([]);
  const [degree, getDegree] = useState([]);
  const [major, getMajor] = useState([]);
  const [roles, getRoles] = useState([]);

  const upload = useRef(null);
  const accomplishmentsImg = useRef(null);
  const profile_videoref = useRef(null);
  const [dob, setDob] = useState(formatDate(new Date()));
  const [fileUpload, setFileUpload] = useState([]);
  const [focus, setUploadLength] = useState(null);
  const [detect, setDetect] = useState(new Date());
  //S3 CONFIG //
  let config = { ...s3config, dirName: "profile_icon", };
  const ReactS3Client = new S3(config);
  const uploadFile = (file, name, setFieldValue, field_name) => {
    ReactS3Client.uploadFile(file, name)
      .then((data) => { setFieldValue(field_name, data.key); })
      .catch((err) => console.log(err));
  };

  const initialValues = {
    dob: "",
    gender: 0,
    profile_pic: "",
    intro_video: "",
    info: "",
    doorno: "",
    address_1: "",
    address_2: "",
    area: "",
    city: "",
    country: "",
    pincode: "",

    workexperience: [
      {
        role: "",
        organization: "",
        from_date: dob,
        to_date: dob,
      },
    ],

    educationdetails: [
      {
        degree: "",
        major: "",
        grade: "",
        college_name: "",
        from_date: dob,
        to_date: dob,
      },
    ],
    certification: [
      {
        name: "",
        from_date: dob,
        to_date: dob,
      },
    ],
    accomplishment: [
      {
        name: "",
        certificate: "",
        pic: "",
      },
    ],
    acceptTerms: false
  };
  const ScrolltoContent = (activeDiv) => {
    switch (activeDiv) {
      case "profile_details":
        profile_details.current.scrollIntoView();
        break;
      case "address_details":
        address_details.current.scrollIntoView();
        break;
      case "work_experience":
        work_experience.current.scrollIntoView();
        break;
      case "educational_details":
        educational_details.current.scrollIntoView();
        break;
      case "accomplishments":
        accomplishments.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getAllValues();
    dispatch(loading(false))
  }, []);
  useEffect(() => {

  }, [detect])
  useEffect(() => { }, [
    change /* profile_pic_name, profile_video */,
    ,
    fileUpload,
  ]);
  var token = JSON.parse(localStorage.getItem("user"))?.token;
  const changeGender = (i) => {
    genderBtn.map((data) => {
      data.isSelect = false;
    });
    genderBtn[i].isSelect = true;
    detectChange(new Date());
  };
  const getAllValues = async () => {
    axios
      .get(API_ENDPOINT + "tutor/tutor_update_details")
      .then((response) => {
        console.log("getAllValues response", response);
        getCity(response.data.city);
        getCountry(response.data.country);
        getDegree(response.data.degree);
        getMajor(response.data.major);
        getRoles(response.data.role);
      });
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const formSubmit = (setFieldValue, values) => {
    fileUpload.map(function (data) {
      uploadFile(data.file, data.name, setFieldValue, data.field);
    });
  };

  function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

  return (
    <>
      <ProfHeader />
      <div className="container mx-5" id="profile_regsiter">
        <Formik
          initialValues={initialValues}
          validationSchema={ProfileRegisterSchema}
          onSubmit={(values) => {
            axios
              .post(API_ENDPOINT + "tutor/updateprofile", values, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                if (res.data.status) {
                  toast.success(res.data, {
                    position: toast.POSITION.TOP_RIGHT,
                  });

                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 1000);
                }
              })
              .catch((err) => {
                toast.error(err.data, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            values,
            touched,
            setFieldValue,
            setValue,
          }) => (
            <Form>
              {/*   <div className="container  my-5"> */}
              <div className="row">
                <div className="col-md-3 ">
                  <div className="userInfo">
                    <h4 className="user_name">Hi {count.tutor_name}</h4>
                    <h5 className="regster_description">
                      Setup your profile today
                    </h5>
                  </div>
                  <div className="stepperContainer top_container" style={{ marginLeft: '-32px' }}>
                    <ul className="stepper_ul p-0 position-relative">
                      <Scrollspy style={{ color: '#666666' }}
                        items={[
                          "personal_details",
                          "address_details",
                          "work_experience",
                          "educational_details",
                          "accomplishments",
                        ]}
                        currentClassName="active"
                      >
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("profile_details");
                          }}
                        >
                          <h6 className="stepper_text">Personal Details</h6>
                        </li>
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("address_details");
                          }}
                        >
                          <h6 className="stepper_text">Address Details</h6>
                        </li>
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("work_experience");
                          }}
                        >
                          <h6 className="stepper_text">Work Experience</h6>
                        </li>
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("educational_details");
                          }}
                        >
                          <h6 className="stepper_text">Educational Details</h6>
                        </li>
                        <li
                          className="stepper_li"
                          onClick={() => {
                            ScrolltoContent("accomplishments");
                          }}
                        >
                          <h6 className="stepper_text">Accomplishments</h6>
                        </li>
                      </Scrollspy>
                    </ul>
                  </div>
                </div>

                <div className="col-md-9 top_container">
                  <div className="err_container">
                    {errors && (
                      <FormHelperText error>
                        {errors.dob && touched.dob ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.dob}</p>
                          </Alert>
                        ) : null}
                        {errors.info && touched.info ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.info}</p>
                          </Alert>
                        ) : null}
                        {errors.doorno && touched.doorno ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.doorno}</p>
                          </Alert>
                        ) : null}
                        {errors.address_1 && touched.address_1 ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.address_1}</p>
                          </Alert>
                        ) : null}
                        {/* {errors.address_2 && touched.address_2 ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.address_2}</p>
                          </Alert>
                        ) : null}
                         */}{errors.area && touched.area ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.area}</p>
                          </Alert>
                        ) : null}
                        {errors.city && touched.city ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.city}</p>
                          </Alert>
                        ) : null}
                        {errors.country && touched.country ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.country}</p>
                          </Alert>
                        ) : null}
                        {errors.pincode && touched.pincode ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.pincode}</p>
                          </Alert>
                        ) : null}

                        {errors.profile_pic && touched.profile_pic ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.profile_pic}</p>
                          </Alert>
                        ) : null}

                        {errors.intro_video && touched.intro_video ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.intro_video}</p>
                          </Alert>
                        ) : null}

                        {errors.workexperience && touched.workexperience
                          ? errors.workexperience?.map((data, i) => (
                            <>
                              <Alert variant="filled" severity="error">
                                <p>{errors.workexperience[i]?.role}</p>
                              </Alert>
                              <Alert variant="filled" severity="error">
                                <p>
                                  {errors.workexperience[i]?.organization}
                                </p>
                              </Alert>
                              {/*  <Alert variant="filled" severity="error">
                                  <p>{errors.workexperience[i].from_date}</p>
                                </Alert>
                                <Alert variant="filled" severity="error">
                                  <p>{errors.workexperience[i]?.to_date}</p>
                                </Alert> */}
                            </>
                          ))
                          : null}

                        {errors.educationdetails && touched.educationdetails
                          ? errors.educationdetails?.map((data, i) => (
                            <>
                              <Alert variant="filled" severity="error">
                                <p>{errors.educationdetails[i]?.degree}</p>
                              </Alert>
                              <Alert variant="filled" severity="error">
                                <p>{errors.educationdetails[i]?.major}</p>
                              </Alert>
                              <Alert variant="filled" severity="error">
                                <p>{errors.educationdetails[i].grade}</p>
                              </Alert>
                              <Alert variant="filled" severity="error">
                                <p>
                                  {errors.educationdetails[i]?.college_name}
                                </p>
                              </Alert>
                              {/*   <Alert variant="filled" severity="error">
                                <p>
                                  {errors.educationdetails[i]?.certificate}
                                </p>
                              </Alert> */}
                            </>
                          ))
                          : null}

                        {/*    {errors.accomplishment && touched.accomplishment
                          ? errors.accomplishment?.map((data, i) => (
                            <>
                              <Alert variant="filled" severity="error">
                                <p>{errors.accomplishment[i]?.name}</p>
                              </Alert>
                                <Alert variant="filled" severity="error">
                                <p>{errors.accomplishment[i]?.pic}</p>
                              </Alert> 
                            </>
                          ))
                          : null} 
                      */}       {errors.acceptTerms && touched.acceptTerms ? (
                          <Alert variant="filled" severity="error">
                            <p>{errors.acceptTerms}</p>
                          </Alert>
                        ) : null}
                      </FormHelperText>
                    )}
                  </div>

                  <h6 className="skip__registeration" onClick={() => navigate('/dashboard')}>Skip Registeration</h6>

                  <div
                    className="personal_details stepper_div"
                    ref={profile_details}
                    id="personal_details"
                  >
                    <div className="boxContainer">
                      <h5 className="box_header_title">
                        Personal Details{" "}
                        <span className="required_field">*</span>
                      </h5>
                      <h6 className="box_header_title field__mandatory">
                        <span className="required_field">*</span>{" "}
                        Fields are Mandatory</h6>

                      {/*    <h6 className="field__mandatory">*Fields are Mandatory</h6> */}
                      <div className="form_container">
                        <div className="dob_field form_field dob__prof">
                          <LocalizationProvider dateAdapter={DateAdapter}  >

                            <DesktopDatePicker className="dob__prof"
                              error={Boolean(touched.dob && errors.dob)}
                              helperText={touched.dob && errors.dob}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue("dob", formatDate(e));
                              }}

                              label="DOB"
                              value={values.dob}
                              maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="gender_field form_field">
                          <span className="gender_label" style={{ color: '#666666' }}>Select Gender</span>
                          <div className="btn_container d-flex" style={{ marginTop: '5px' }}>
                            {genderBtn.map((data, i) => (
                              <div
                                key={i}
                                className={
                                  data.isSelect
                                    ? "gender_btn btn_active"
                                    : "gender_btn"
                                }
                                onClick={() => {
                                  changeGender(i);
                                  setFieldValue("gender", i, false);
                                }}
                              >
                                <h6>{data.name}</h6>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="photo_upload form_field position-relative">
                          <TextField
                            id="outlined-basic"
                            label="Upload Your Photo"
                            variant="outlined"
                            //value={profile_pic_name}
                            //onBlur={handleBlur}
                            value={values.profile_pic}
                          />
                          <span className="form_des_info" style={{ color: '#666666' }}>(jpg/png files not more than 200Kb) </span>
                          <span
                            className="upload__icon"
                            onClick={() => {
                              upload.current.click();
                            }}
                          >
                            <img src={Images.upload_icon} alt="upload_icon" />
                          </span>
                          <div
                            className={
                              "profile_pic " +
                              (values.profile_pic ? "d-block" : "d-none")
                            }
                          >
                            <img
                              id="output"
                              src="#"
                              className="image_preview"
                            />
                            <h6
                              onClick={() => {
                                //setprofile_pic_name("");
                                setFieldValue("profile_pic", "");
                                upload.current.value = "";
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete Image
                            </h6>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            id="file"
                            ref={upload}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (
                                checkValidFile(
                                  e.target.files[0],
                                  [".jpeg", ".jpg", "png"],
                                  200000
                                )
                              ) {
                                setFieldValue(
                                  "profile_pic",
                                  e.target.files[0].name
                                );
                                uploadFile(
                                  e.target.files[0],
                                  e.target.files[0].name,
                                  setFieldValue,
                                  "profile_pic"
                                );
                                var output = document.getElementById("output");
                                output.src = URL.createObjectURL(
                                  e.target.files[0]
                                );
                                output.onload = function () {
                                  URL.revokeObjectURL(output.src); // free memory
                                };
                              } else {
                                toast.error(
                                  "Please Select Valid Image. or Check image Size",
                                  {
                                    position: toast.POSITION.TOP_RIGHT,
                                  }
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="photo_upload form_field position-relative">
                          <TextField
                            id="outlined-basic"
                            label="Upload Your Video"
                            variant="outlined"
                            //value={profile_video}
                            value={values.intro_video}
                          />
                          <span className="form_des_info" style={{ color: '#666666' }}>(All mp4.files are supported under 20Mb)</span>
                          <span
                            className="upload__icon"
                            onClick={() => {
                              profile_videoref.current.click();
                            }}
                          >
                            <img src={Images.upload_icon} alt="upload_icon" />
                          </span>
                          <div
                            className={
                              "profile_image " +
                              (values.intro_video ? "d-block" : "d-none")
                            }
                          >
                            <video
                              id="videoPreview"
                              width="320"
                              height="240"
                              controls
                            >
                              Your browser does not support the video tag.
                            </video>
                            <h6
                              onClick={() => {
                                //setProfile_video("");
                                setFieldValue("intro_video", "");
                                profile_videoref.current.value = "";
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete Video
                            </h6>
                          </div>
                          <input
                            type="file"
                            accept="video/*"
                            id="profile_video"
                            ref={profile_videoref}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (
                                checkValidFile(
                                  e.target.files[0],
                                  [".mp4"],
                                  20000000
                                )
                              ) {
                                setFieldValue(
                                  "intro_video",
                                  e.target.files[0].name
                                );
                                uploadFile(
                                  e.target.files[0],
                                  e.target.files[0].name,
                                  setFieldValue,
                                  "intro_video"
                                );
                                var output =
                                  document.getElementById("videoPreview");
                                output.src = URL.createObjectURL(
                                  e.target.files[0]
                                );
                                output.onload = function () {
                                  URL.revokeObjectURL(output.src); // free memory
                                };
                              } else {
                                toast.error(
                                  "Please Select Valid Video file or Size.",
                                  {
                                    position: toast.POSITION.TOP_RIGHT,
                                  }
                                );
                              }
                            }}
                          />
                        </div>

                        {/*   <div className="about_textarea form_field position-relative">
                          <TextField
                            name="info"
                            onBlur={(e) => {
                              handleBlur(e);
                              handleChange(e);
                            }}
                            id="outlined-multiline-static"
                            rows={4}
                            label="About Yourself"
                            variant="outlined"
                          />
                        </div> */}
                        <div className="about_textarea form_field position-relative">
                          <TextField name="info"
                            id="outlined-multiline-static" rows={4} label="About Yourself"
                            variant="outlined" multiline
                            onBlur={(e) => { handleBlur(e); handleChange(e); }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="address_details stepper_div"
                    ref={address_details}
                    id="address_details"
                  >
                    <div className="boxContainer">
                      <h5 className="box_header_title">
                        Address Details{" "}
                        <span className="required_field">*</span>
                      </h5>
                      <div className="form_container">
                        <div className=" form_field position-relative">
                          <TextField
                            onBlur={(e) => {
                              handleBlur(e);
                              handleChange(e);
                            }}
                            name="doorno"
                            //onChange={handleChange}
                            id="outlined-basic"
                            label="Door/Flat No."
                            variant="outlined"
                          />
                        </div>
                        <div className="container p-0">
                          <div className="row g-0 two_div_row">
                            <div className="col-md-6 address_field">
                              <div className=" form_field position-relative">
                                <TextField
                                  id="outlined-basic"
                                  label="Address Line 1"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleChange(e);
                                  }}
                                  name="address_1"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 address_field">
                              <div className=" form_field position-relative">
                                <TextField
                                  id="outlined-basic"
                                  label="Address Line 2"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleChange(e);
                                  }}
                                  name="address_2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form_field position-relative">
                          <TextField
                            id="outlined-basic"
                            label="Area"
                            variant="outlined"
                            onBlur={(e) => {
                              handleBlur(e);
                              handleChange(e);
                            }}
                            name="area"
                          />
                        </div>
                        <div className="container p-0">
                          <div className="row g-0 two_div_row">
                            <div className="col-md-6 address_field">
                              <div className="container p-0">
                                <div className="row g-0 two_div_row">
                                  <div className="col-md-6 address_field">
                                    <div className=" form_field position-relative">
                                      <FormControl fullWidth>
                                        <InputLabel id="city-label">
                                          City
                                        </InputLabel>
                                        <Field
                                          as={Select} style={{ textTransform: 'capitalize' }}
                                          //labelId="city-label"
                                          id="city"
                                          name="city"
                                          label="City"
                                          value={values.selectedCity}
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                        >
                                          {city.map((e, key) => {
                                            return (
                                              <MenuItem key={key} value={e.id}>
                                                {e.city_name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Field>
                                      </FormControl>
                                    </div>
                                  </div>
                                  <div className="col-md-6 address_field">
                                    <div className=" form_field position-relative">
                                      <FormControl fullWidth>
                                        <InputLabel id="city-label">
                                          Country
                                        </InputLabel>
                                        <Field
                                          as={Select}
                                          id="country"
                                          name="country"
                                          label="Country"
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                        >
                                          {country.map((e, key) => {
                                            return (
                                              <MenuItem key={key} value={e.id}>
                                                {e.country_name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Field>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 address_field">
                              <div className="form_field position-relative">
                                <TextField id="outlined-basic"
                                  label="Pincode"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                    handleChange(e);
                                  }}
                                  name="pincode"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="work_experience stepper_div"
                    ref={work_experience}
                    id="work_experience"
                  >
                    <div className="boxContainer">
                      <h5 className="box_header_title">
                        Work Experience
                        <span className="required_field">*</span>
                      </h5>

                      <div className="form_container">
                        <div className="form_field position-relative">
                          <FieldArray name="workexperience">
                            {({ push, remove }) => (
                              <>
                                {values.workexperience.map((_, index) => (
                                  <div className="position-keeper" key={index}>
                                    <div className="bkingeer">
                                      <div className="col-md-6 address_field">
                                        <div className=" form_field position-relative">
                                          <FormControl fullWidth>
                                            <InputLabel id="city-label">
                                              Select Role
                                            </InputLabel>
                                            <Field
                                              as={Select}
                                              labelId="city-label"
                                              id="ciry-select"
                                              name={`workexperience[${index}].role`}
                                              onChange={handleChange}
                                              label="Select Role"
                                            >
                                              {roles.map((e, key) => {
                                                return (
                                                  <MenuItem key={key} value={e.id} >{e.role_name}</MenuItem>
                                                );
                                              })}
                                            </Field>
                                          </FormControl>
                                        </div>
                                      </div>
                                      <div className="form_field position-relative">
                                        <TextField
                                          onBlur={(e) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          id="outlined-basic"
                                          label="Enter Organization / Institute Name"
                                          variant="outlined"
                                          name={`workexperience[${index}].organization`}
                                        />
                                      </div>
                                      <div className="container p-0">
                                        <div className="row g-0 two_div_row">
                                          <div className="col-md-6 address_field">
                                            <div className="container p-0">
                                              <div className="row g-0 two_div_row">
                                                <div className="col-md-6 address_field">
                                                  <div className="dob_field form_field">
                                                    <LocalizationProvider
                                                      dateAdapter={DateAdapter}>
                                                      <DesktopDatePicker
                                                        label="From"
                                                        value={
                                                          values.workexperience[
                                                            index
                                                          ].from_date
                                                        }
                                                        onBlur={handleBlur}
                                                        maxDate={new Date()}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            `workexperience[${index}].from_date`,
                                                            formatDate(e)
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </LocalizationProvider>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 address_field">
                                                  <div className="dob_field form_field">
                                                    <LocalizationProvider
                                                      dateAdapter={DateAdapter}>
                                                      <DesktopDatePicker
                                                        label="To"
                                                        value={
                                                          values.workexperience[
                                                            index
                                                          ].to_date
                                                        }
                                                        onBlur={handleBlur}
                                                        maxDate={new Date()}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            `workexperience[${index}].to_date`,
                                                            formatDate(e)
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </LocalizationProvider>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="add_new_contianers">

                                      {
                                        values.workexperience.length >= 2 && index != 0 ? (
                                          <img style={{ /* float: 'right' */ }}
                                            src={Images.Minus}
                                            onClick={() => {
                                              values.workexperience.splice(index, 1);
                                              console.log(values.workexperience, 'sssss')
                                              setDetect(new Date());
                                              //remove({ tutor_role: "", organization: "", from_date: new Date(), to_date: new Date(), });
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null
                                      }

                                      {index ===
                                        values.workexperience.length - 1 ? (
                                        <img style={{/*  float: 'right', marginRight: '20px' */ float: 'right',
                                          margin: '-12px 19px'
                                        }}
                                          src={Images.add_icon}
                                          onClick={() => {
                                            push({ tutor_role: '', organization: '', from_date: '' /*  new Date() */, to_date:'' /* new Date() */, });
                                          }}
                                          alt="imgg"
                                          className="add_icon"
                                        />
                                      ) : null}
                                    </div>
                                    {/* {index ===
                                      values.workexperience.length - 1 ? (
                                      <div className="add_new_contianer">
                                        <img
                                          src={Images.add_icon}
                                          onClick={() => {
                                            push({
                                              role: "",
                                              organization: "",
                                              from_date: new Date(),
                                              to_date: new Date(),
                                            });
                                          }}
                                         
                                          alt="imgg"
                                          className="add_icon"
                                        />
                                      </div>
                                    ) : (
                                      <div className="add_new_contianer">
                                        <RemoveCircleOutlineIcon
                                          style={{
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            remove({
                                              role: "",
                                              organization: "",
                                              from_date: new Date(),
                                              to_date: new Date(),
                                            });
                                            console.log(values);
                                          }}
                                          alt="imge"
                                          className="add_icon"
                                        />
                                      </div>
                                    )} */}
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="educational_details stepper_div"
                    ref={educational_details}
                    id="educational_details"
                  >
                    <div className="boxContainer">
                      <h5 className="box_header_title">
                        Educational Details{" "}
                        <span className="required_field">*</span>
                      </h5>
                      <div className="form_container">
                        <div className="container p-0 position-relative">
                          <FieldArray name="educationdetails">
                            {({ push, remove }) => (
                              <>
                                {values.educationdetails.map((_, index) => (
                                  <>
                                    <div
                                      className="position-keeper"
                                      key={index}
                                    >
                                      <div className="plusaccomplishmentse">
                                        <div className="row g-0 two_div_row">
                                          <div className="col-md-5 address_field">
                                            <div className="form_field position-relative">
                                              <FormControl fullWidth>
                                                <InputLabel id="city-label">
                                                  Select Degree
                                                </InputLabel>
                                                <Select
                                                  labelId="city-label"
                                                  id="ciry-select"
                                                  name={`educationdetails[${index}].degree`}
                                                  label="Select Degree"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                >
                                                  {degree.map((e, key) => {
                                                    return (
                                                      <MenuItem key={key} value={e.id}>{e.degree}</MenuItem>
                                                    );
                                                  })}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                          <div className="col-md-5 address_field">
                                            <div className=" form_field position-relative">
                                              <FormControl fullWidth>
                                                <InputLabel id="city-label">
                                                  Select Major
                                                </InputLabel>
                                                <Select
                                                  labelId="city-label"
                                                  id="ciry-select"
                                                  name={`educationdetails[${index}].major`}
                                                  label="Select Major"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                >
                                                  {major.map((e, key) => {
                                                    return (<MenuItem key={key} value={e.id}>{e.major}</MenuItem>);
                                                  })}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row g-0 two_div_row">
                                          <div className="col-md-5 address_field">
                                            <div className="form_field position-relative">
                                              <TextField
                                                id="outlined-basic"
                                                label="Enter Grade"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={`educationdetails[${index}].grade`}
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-5 address_field">
                                            <div className=" form_field position-relative">
                                              <TextField
                                                id="outlined-basic"
                                                label="College Name"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={`educationdetails[${index}].college_name`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row g-0 two_div_row">
                                          <div className="col-md-5 address_field">
                                            <div className="row g-0 two_div_row">
                                              <div className="col-md-6 address_field">
                                                <div className="dob_field form_field">
                                                  <LocalizationProvider
                                                    dateAdapter={DateAdapter}
                                                  >
                                                    <DesktopDatePicker
                                                      label="From"
                                                      value={values.educationdetails[index].from_date}
                                                      maxDate={new Date()}
                                                      onBlur={handleBlur}
                                                      onChange={(e) => {
                                                        setFieldValue(`educationdetails[${index}].from_date`, formatDate(e));
                                                      }}
                                                      renderInput={(params) => (<TextField  {...params} />)}
                                                    />
                                                  </LocalizationProvider>
                                                </div>
                                              </div>
                                              <div className="col-md-5 address_field">
                                                <div className="dob_field form_field">
                                                  <LocalizationProvider dateAdapter={DateAdapter} >
                                                    <DesktopDatePicker
                                                      label="To"
                                                      value={values.educationdetails[index].to_date}
                                                      maxDate={new Date()}
                                                      onBlur={handleBlur}
                                                      onChange={(e) => {
                                                        setFieldValue(`educationdetails[${index}].to_date`, formatDate(e));
                                                      }}
                                                      renderInput={(params) => (<TextField {...params} />
                                                      )}
                                                    />
                                                  </LocalizationProvider>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/*   {index ===
                                        values.educationdetails.length - 1 ? (
                                        <div className="add_new_contianer">
                                          <img
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({degree: "", major: "",grade: "",college_name: "",
                                                from_date: formatDate(new Date()),
                                                to_date: formatDate(new Date()),
                                              });
                                            }}
                                            alt="imge"
                                            className="add_icon"
                                          />
                                        </div>
                                      ) : (
                                        <div className="add_new_contianer">
                                          <RemoveCircleOutlineIcon
                                            style={{ color: "red", }}
                                            onClick={() => {remove({degree: "", major: "", grade: "", college_name: "", from_date: "", to_date: "",});}}
                                            alt="imge"
                                            className="add_icon"
                                          />
                                        </div>
                                      )} */}
                                      <div className="add_new_contianers_educations  add_new_contianers">
                                        {/*  <div className="add_new_contianers">  */}

                                        {values.educationdetails.length >= 2 && index != 0 ? (

                                          <img style={{ float: 'right', marginRight: '23px', marginLeft: '17px' }}
                                            src={Images.Minus}
                                            onClick={() => {
                                              values.educationdetails.splice(index, 1);
                                              setDetect(new Date());
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />

                                          /*  </div> */
                                        ) : null}

                                        {index ===
                                          values.educationdetails.length - 1 ? (
                                          <img style={{ float: 'right', margin: '-12px -8px' }}
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({ degree: "", major: "", grade: "", college_name: "", from_date: '' /*  formatDate(new Date()) */, to_date:'' /* formatDate(new Date()) */, });
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            )}
                          </FieldArray>

                          <div className="container p-0 position-relative">
                            <FieldArray name="certification">
                              {({ push, remove }) => (
                                <>
                                  {values.certification.map((_, index) => (
                                    <div
                                      className="position-keeper"
                                      key={index}
                                    >
                                      <div className="bking">
                                        <div className="row g-0 two_div_row">
                                          <div className="col-md-6 address_field">
                                            <div className=" form_field position-relative">
                                              <TextField
                                                id="outlined-basic"
                                                label="Certificate Name (optional)"
                                                variant="outlined"
                                                name={`certification[${index}].name`}
                                                onChange={handleChange}
                                              />
                                              {errors.certification ? (
                                                <FormHelperText error>
                                                  {
                                                    errors.certification[
                                                      `${index}`
                                                    ].certificate
                                                  }
                                                </FormHelperText>
                                              ) : null}
                                            </div>
                                            <div className="row g-0 two_div_row">
                                              <div className="col-md-6 address_field">
                                                <div className="dob_field form_field">
                                                  <LocalizationProvider
                                                    dateAdapter={DateAdapter}
                                                  >
                                                    <DesktopDatePicker
                                                      label="From"
                                                      value={
                                                        values.certification[
                                                          index
                                                        ].from_date
                                                      }
                                                      onBlur={handleBlur}
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          `certification[${index}].from_date`,
                                                          formatDate(e)
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                    />
                                                    {errors.certification ? (
                                                      <FormHelperText error>
                                                        {
                                                          errors.certification[
                                                            `${index}`
                                                          ]?.from_date
                                                        }
                                                      </FormHelperText>
                                                    ) : null}
                                                  </LocalizationProvider>
                                                </div>
                                              </div>
                                              <div className="col-md-6 address_field">
                                                <div className="dob_field form_field">
                                                  <LocalizationProvider
                                                    dateAdapter={DateAdapter}
                                                  >
                                                    <DesktopDatePicker
                                                      label="To"
                                                      value={
                                                        values.certification[
                                                          index
                                                        ].to_date
                                                      }
                                                      onBlur={handleBlur}
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          `certification[${index}].to_date`,
                                                          formatDate(e)
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                    />
                                                    {errors.certification ? (
                                                      <FormHelperText error>
                                                        {
                                                          errors.certification[
                                                            `${index}`
                                                          ]?.to_date
                                                        }
                                                      </FormHelperText>
                                                    ) : null}
                                                  </LocalizationProvider>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="add_new_contianers">

                                        {values.certification.length >= 2 && index != 0 ? (
                                          <img style={{ float: 'right' }}
                                            src={Images.Minus}
                                            onClick={() => {
                                              values.certification.splice(index, 1);
                                              console.log(values.certification, 'sssss')
                                              setDetect(new Date());
                                            }}
                                            alt="imgg"
                                            className="add_icon"
                                          />
                                        ) : null
                                        }

                                        {index ===
                                          values.certification.length - 1 ? (

                                          <img style={{ margin: '-12px 16px' }}
                                            src={Images.add_icon}
                                            onClick={() => {
                                              push({
                                                name: "",
                                                from_date: formatDate(
                                                  new Date()
                                                ),
                                                to_date: formatDate(new Date()),
                                              });
                                            }}
                                            alt="imge"
                                            className="add_icon"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accomplishments stepper_div"
                      //ref={accomplishments}
                      id="accomplishments"
                    >
                      <div className="boxContainer">
                        <h5 className="box_header_title">
                          Accomplishments{" "}
                          <span className="required_field"></span>
                        </h5>
                        <div className="form_container">
                          <div className="container p-0 position-relative">
                            <FieldArray name="accomplishment">
                              {({ push, remove }) => (
                                <>
                                  {values.accomplishment.map((_, index) => (
                                    <>
                                      <div
                                        className="position-keeper"
                                        key={index}
                                      >
                                        <div className="plusaccomplishment">
                                          <div className="row g-0 two_div_row">
                                            <div className="col-md-6 address_field">
                                              <div className=" form_field position-relative">
                                                <TextField
                                                  id="outlined-basic"
                                                  label="Accomplishment Name"
                                                  variant="outlined"
                                                //onBlur={handleBlur}
                                                //name={`accomplishment[${index}].name`}
                                                //onChange={handleChange}
                                                />{" "}
                                              </div>
                                              <div className="photo_upload form_field position-relative ">
                                                <TextField
                                                  id="outlined-basic"
                                                  label="Upload Certificate"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  name={`accomplishment[${index}].pic`}
                                                  value={
                                                    values.accomplishment[index]
                                                      .pic
                                                  }
                                                />{/* 
                                                {errors.accomplishment ? (
                                                  <FormHelperText error>
                                                    {
                                                      errors.accomplishment[
                                                        `${index}`
                                                      ]?.certificate
                                                    }
                                                  </FormHelperText>
                                                ) : null} */}
                                                <span
                                                  className="upload__icon w-100"
                                                  onClick={() => {
                                                    accomplishmentsImg.current.click();
                                                  }}
                                                >
                                                  <img src={Images.upload_icon} alt="upload_icon" />
                                                </span>

                                                {/*  {values.accomplishment[index].pic ? */}
                                                <div className={"profile_image " + (values.accomplishment[index].pic === "" ? "d-none" : "d-block")}>
                                                  {console.log(values.accomplishment, 'accomplishmentData')}
                                                  <img id={`outputaccomplishments${index}`} src="#" className="image_preview" alt="certificate" />
                                                  <h6
                                                    onClick={() => {
                                                      setFieldValue(`accomplishment[${index}].certificate`, "");
                                                      setFieldValue(`accomplishment[${index}].pic`, "");
                                                      accomplishmentsImg.current.value = "";
                                                    }} style={{ cursor: "pointer", }}>
                                                    Delete Image
                                                  </h6>
                                                </div> {/* : null} */}

                                                <input type="file" accept="image/*"
                                                  id="file" ref={accomplishmentsImg}
                                                  style={{ display: "none" }}
                                                  onChange={(e) => {
                                                    setFieldValue(`accomplishment[${index}].pic`, e.target.files[0].name);
                                                    uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, `accomplishment[${index}].certificate`);
                                                    var output =
                                                      document.getElementById("outputaccomplishments" + index);
                                                    output.src = URL.createObjectURL(e.target.files[0]);
                                                    output.onload = function () {
                                                      URL.revokeObjectURL(
                                                        output.src); // free memory
                                                    };
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="add_new_contianers_educations  add_new_contianers">

                                          {
                                            values.accomplishment.length >= 2 && index != 0 ? (
                                              <img style={{ position: 'absolute', top: '-21px', right: '73px' }}
                                                src={Images.Minus}
                                                onClick={() => {
                                                  values.accomplishment.splice(index, 1);
                                                  console.log(values.accomplishment, 'sssss')
                                                  setDetect(new Date());
                                                  //remove({ tutor_role: "", organization: "", from_date: new Date(), to_date: new Date(), });
                                                }}
                                                alt="imgg"
                                                className="add_icon"
                                              />
                                            ) : null
                                          }

                                          {index ===
                                            values.accomplishment.length - 1 ? (
                                            <img style={{ position: 'absolute', top: '-22px', right: '30px' }}
                                              src={Images.add_icon}
                                              onClick={() => { push({ name: '', certificate: '', pic: '' }); }}
                                              alt="imgg"
                                              className="add_icon"
                                            />
                                          ) : null}
                                        </div>


                                        {/* {index ===
                                          values.accomplishment.length - 1 ? (
                                          <div className="add_new_contianer">
                                            <img
                                              src={Images.add_icon}
                                              onClick={() => {
                                                push({
                                                  name: "",
                                                  certificate: "",
                                                  pic: "",
                                                });
                                              }}
                                              alt="imge"
                                              className="add_icon"
                                            />
                                          </div>
                                        ) : (
                                          <div className="add_new_contianer">
                                            <RemoveCircleOutlineIcon   style={{
                                            color: "red",
                                          }}
                                              onClick={() => {
                                                remove({
                                                  name: "",
                                                  profile_pic: "",
                                                  pic: "",
                                                });
                                              }}
                                              alt="imge"
                                              className="add_icon"
                                            />
                                          </div>
                                        )} */}
                                      </div>
                                    </>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="submit_container">
                      <div className="container p-0">
                        <div className="row g-0">
                          <div className="col checkBox">
                            <div className="d-felx">
                              {/*  <Checkbox /> */}
                              <div className="checkforgm">
                                <Field
                                  type="checkbox"
                                  name="acceptTerms"
                                  className={
                                    "form-check-input " +
                                    (errors.acceptTerms && touched.acceptTerms
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <span className="terms__cond">
                                  I agree to the{" "}
                                  <span className="term__color">
                                    Terms & Conditions
                                  </span>
                                </span>
                                {/*   <ErrorMessage
                            name="acceptTerms"
                            component="div"
                            className="invalid-feedback"
                          /> */}
                              </div>
                              {/*  </div> */}
                              {/*                               <Field
                            type="checkbox"
                            name="acceptTerms"
                            className={
                              "form-check-input " +
                              (errors.acceptTerms && touched.acceptTerms
                                ? " is-invalid"
                                : "")
                            }/> 
                              <h6 className="d-inline">
                                {" "}
                                I agree to the{" "}
                                <a href="#" className="link">
                                  Terms and Conditions
                                </a>
                                <ErrorMessage
                            name="acceptTerms"
                            component="div"
                            className="invalid-feedback"
                          />
                              </h6> */}
                            </div>
                            {/* </div> */}
                            <div className="submit_btn_container">
                              <div
                                className="gender_btn btn_active"
                                onClick={() => {
                                  console.log(errors)
                                  handleSubmit();
                                }}
                              >
                                <h6>Submit</h6>
                              </div>
                              <div className="gender_btn">
                                <h6 onClick={() => navigate("/dashboard")}>Cancel</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-100"></div>
      <Footer />
    </>
  );
}
