import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import Header from '../../../components/Header/Header';
import { loading } from '../../../store/actions';
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import SelectForm from "../../../components/Forms/select/Select";
import Checkbox from '@mui/material/Checkbox';
import './../CreateQuiz/CreateQuiz.css';
import constant from "../../../constant/Validation";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import { axiosToken } from '../../../services/api';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Footer from "../../../components/Footer/Footer";

const QuizSchema = Yup.object().shape({
  quiz_name: Yup.string().required(constant.CreateQuiz.quiz_name).trim(),
  course_id: Yup.string().required(constant.CreateQuiz.course_id),
  duration: Yup.string().required(constant.CreateQuiz.duration),
  questions: Yup.array(
    Yup.object().shape({
      question: Yup.string().required(constant.CreateQuiz.question).trim(),
      option1: Yup.string().required(constant.CreateQuiz.option1).trim(),
      option2: Yup.string().required(constant.CreateQuiz.option2).trim(),
      option3: Yup.string().required(constant.CreateQuiz.option3).trim(),
      option4: Yup.string().required(constant.CreateQuiz.option4).trim(),
      answer: Yup.string().nullable().required(constant.CreateQuiz.answer)
    })
  ),
});



const durationData = [
  { name: '10 Min', id: 10 },
  { name: '20 Min', id: 20 },
  { name: '30 Min', id: 30 },
  { name: '45 Min', id: 45 },
  { name: '60 Min', id: 60 }
]

const label = { inputProps: { 'aria-label': 'Quiz Answer' } };

export default function EditQuiz() {
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('user'))?.role[0])
  const navigation = useNavigate();
  const {state} = useLocation();
  const { data } = state;
  useEffect(()=>{console.log(data,'ssss')},[])
  const initialValues = {
      id :data?.id,
    quiz_name: data?.quiz_name,
    course_id: data?.course_id,
    duration: data?.duration,
    publish_status: 1,
    quiz_bank: data?.quiz_bank
  }
  const newQues = { question: '', option1: '', option2: '', option3: '', option4: '', answer: null }
  const dispatch = useDispatch();

  const UpdateQuiz = (data) => {
    dispatch(loading(true))
    axiosToken.put('/tutor/updatequizset', data)
      .then((res) => {
        if (res.data.status) {
          setTimeout(() => {
            dispatch(loading(false))
            navigation(-1)
          }, 500);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
      })
      .catch((err) => { 
        toast.error(err.response.data.detail, {
            position: toast.POSITION.TOP_RIGHT,
          });  
        console.log(err) })
  }
  const getCourse = () => {
    axiosToken.get('tutor/list_tutor_courses')
      .then((res) => { setCourse(res.data.courses) })
      .catch((err) => { console.log(err) })
  }
  useEffect(() => {
    (
      dispatch(loading(false)))
    getCourse();
  }, [])
  return (
    <div>
      <Header />
   {/*    <Sidebar /> */}
      <div className='container mt-4 mx-5'>
        <div className='row'>
          <div className='col-md-7'>
           {/*  <div className='breadcrumb'>
              <span>Quiz / </span>
              <span onClick={()=>{navigation('/manage-quiz')}} style={{cursor:'pointer'}}>  Manage  Quiz  /  </span>
              <span> Ediz Quiz  </span>
            </div> */}
             {role == 2 ?  <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                      <li className="breadcrumb-item" onClick={() => navigate('/manage-quiz')} style={{cursor:'pointer'}}>Quiz</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                       Manage  Quiz / Edit Quiz
                      </li>
                    </ol> 
                   </nav> :null }
            <div className='create_quiz_container'>
              <h2 className='quiz__header'>Edit Quiz</h2>

              {/* QUIZ FORM START HERE  */}
              <Formik
                initialValues={initialValues}
                validationSchema={QuizSchema}
                onSubmit={(values) => {
                  UpdateQuiz(values)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                  touched,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className='row quiz__form'>
                      {/* ERROR MESSAGE HERE */}

                      <div className="err_container col-md-12">
                        {errors && (
                          <FormHelperText error>
                            {errors.quiz_name && touched.quiz_name ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.quiz_name}</p>
                              </Alert>
                            ) : null}
                            {errors.course_id && touched.course_id ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.course_id}</p>
                              </Alert>
                            ) : null}
                            {errors.duration && touched.duration ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.duration}</p>
                              </Alert>
                            ) : null}
                          </FormHelperText>
                        )}
                      </div>

                      <div className='col-md-4 form_field'>
                        <SelectForm
                          values={values.course_id}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          dorpdownValue={course}
                          label="Select Course"
                          name="course_id"
                          dropwndownValueName="course_name"
                        />
                      </div>
                      <div className='col-md-4 form_field quiz_name'>
                        <TextField
                          id="outlined-multiline-static"
                          label="Quiz Name"
                          variant="outlined"
                          name="quiz_name"
                          defaultValue={data.quiz_name}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleChange(e);
                          }}
                        />
                      </div>
                      <div className='col-md-4 form_field quiz_name'>
                        <SelectForm
                          values={values.duration}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          dorpdownValue={durationData}
                          label="Select Duration"
                          name="duration"
                          dropwndownValueName="name"
                        />
                      </div>
                      <div className='col-md-12'>
                        <FieldArray name="quiz_bank">
                          {({ push, remove }) => (
                            <>
                              {values.quiz_bank.map((data, i) => (
                                <>
                                  <div className='form_field'>
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="Question"
                                      defaultValue={values.quiz_bank[i]?.question}
                                      name={`quiz_bank[${i}].question`}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        handleChange(e);

                                      }}
                                    />
                                    {errors?.quiz_bank ?
                                      <FormHelperText id="component-error-text" style={{ left: '5px' }}>
                                        {errors?.quiz_bank[i]?.quiz_bank}
                                      </FormHelperText> : null
                                    }
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.quiz_bank[i].answer === null
                                          || values.quiz_bank[i].answer == 1  ? false : true}
                                         defaultChecked={values.quiz_bank[i]?.answer == 1 ? true:false }
                                       
                                        onChange={
                                          (e) => {
                                            return (
                                              //  console.log('sds')
                                              e.target.checked ?
                                                setFieldValue(`quiz_bank[${i}].answer`, 1) :
                                                setFieldValue(`quiz_bank[${i}].answer`, null)
                                            )

                                          }}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 1"
                                        name={`quiz_bank[${i}].option1`}
                                        defaultValue={values.quiz_bank[i]?.option1}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.quiz_bank ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.quiz_bank[i]?.option1}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.quiz_bank[i].answer === null
                                          || values.quiz_bank[i].answer == 2 ? false : true}
                                          defaultChecked={values.quiz_bank[i]?.answer == 2 ? true:false }
                                        onChange={
                                          (e) => (e.target.checked ? setFieldValue(`quiz_bank[${i}].answer`, 2) : setFieldValue(`quiz_bank[${i}].answer`, null))}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        rows={4}
                                        label="Option 2"
                                        name={`quiz_bank[${i}].option2`}
                                        defaultValue={values.quiz_bank[i]?.option2}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.quiz_bank ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.quiz_bank[i]?.option2}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.quiz_bank[i].answer === null
                                          || values.quiz_bank[i].answer == 3 ? false : true}
                                          defaultChecked={values.quiz_bank[i]?.answer == 3 ? true:false }
                                        onChange={
                                          (e) => (e.target.checked
                                            ? setFieldValue(`quiz_bank[${i}].answer`, 3)
                                            : setFieldValue(`quiz_bank[${i}].answer`, null)
                                          )}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 3"
                                        name={`quiz_bank[${i}].option3`}
                                        defaultValue={values.quiz_bank[i]?.option3}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.quiz_bank ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.quiz_bank[i]?.option3}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.quiz_bank[i].answer === null
                                          || values.quiz_bank[i].answer == 4 ? false : true}
                                          defaultChecked={values.quiz_bank[i]?.answer == 4 ? true:false }
                                          
                                        onChange={
                                          (e) => (e.target.checked ? setFieldValue(`quiz_bank[${i}].answer`, 4) : setFieldValue(`quiz_bank[${i}].answer`, null))}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 4"
                                        name={`quiz_bank[${i}].option4`}
                                        defaultValue={values.quiz_bank[i]?.option4}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.quiz_bank ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.quiz_bank[i]?.option4}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    {errors?.quiz_bank ?
                                      <FormHelperText id="answer_error">
                                        {errors?.quiz_bank[i]?.answer}
                                      </FormHelperText> : null
                                    }
                                       {values.quiz_bank[i].answer ? <span style={{color:'#f88214'}}>Please unselect selected answer to change new one</span>:null}
                                  </div>
                                  <div className="submit_btn_container add_quiz_btn_cont" >
                                    {values.quiz_bank.length === 1 || values.quiz_bank.length === i + 1 ?
                                      <div className="gender_btn btn_active"
                                        style={{ marginLeft: 'auto', width: 'auto', padding: '10px 20px', maxWidth: '100%' }}
                                        onClick={() => { push(newQues) }}
                                      >
                                        <h6>Add Another Question</h6>
                                      </div>
                                      : null
                                    }
                                    {values.quiz_bank.length >= 2 && i != 0 ?
                                      <div className="gender_btn "
                                        style={{ marginLeft: 'auto', width: 'auto', padding: '10px 20px', maxWidth: '100%' }}
                                        onClick={() => { remove(i) }}
                                      >
                                        <h6>Remove Question</h6>
                                      </div> : null
                                    }
                                  </div>
                                </>
                              ))
                              }
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    <div className="submit_btn_container">
                      <div
                        className="gender_btn__cc btn_active"
                        onClick={() => {
                          setFieldValue('publish_status', 1)
                          setTimeout(() => { handleSubmit(); }, 500);
                        }}
                      >
                        <h6> Update</h6>
                      </div>
                   
                      <div className="gender_btn__cc" onClick={()=>{navigation(-1)}}>
                        <h6>Cancel</h6>
                      </div>
                    </div>
                  </Form>
                )
                }
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-100"></div>
      {role == 2 ? <Footer /> : null } 
    </div>
  )
}
