import React, { useEffect } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import '../ManageParents/ManageParents.css';
import { useDispatch } from 'react-redux';
import { loading } from '../../../store/actions';
import ManageParentsListTable from '../ManageParents/components/ManageParentListTable';

export default function ManageParentsAdmin() {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loading(false))
    },[])
  return (
    <div>
        <Header />
        <Sidebar />
        <div className='container-fluid px-5'>
        <div className='row'>
          <div className='col-md-12'>
           <ManageParentsListTable />
          </div>
        </div>
      </div>
    </div>
  )
}
