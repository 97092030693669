import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { dateFormat, timeFormat } from '../../../../helpers/utils';


export default function StudentAttendance({ trigger, data }) {
    const [data1, setData] = useState(data.data);
    useEffect(() => { console.log(data) }, [data])
    return (
        <div>
            <div className='header_attendance'>
                <h5 style={{ textAlign: 'left' }} className="my_child_title">Student Attendance Log</h5>
                <Tooltip title="Close Attendance" onClick={() => { trigger(false) }}>
                    <IconButton> <CloseIcon /></IconButton>
                </Tooltip>
            </div>
            <div className='my_childs_list'>
                {data1?.map((data, i) => (
                    <>
                        <div className="class_info">
                            {data.class_details?.map((d) => (
                                <>
                                    <h6 style={{ textAlign: 'left' }}>Class Name :{d.class_name}</h6>
                                    <h6 style={{ textAlign: 'left' }}>Date :{d.date}</h6>
                                    <h6 style={{ textAlign: 'left' }}>Start Time :{d.class_start_on}</h6>
                                    <h6 style={{ textAlign: 'left' }}>End Time :{d.class_end_on}</h6>
                                </>
                            ))}

                        </div>
                        <div className='attlog'><h6 style={{ textAlign: 'left' }}><b>Attendance</b></h6></div>
                        <div className='log_conts'>
                            <div className='my_childs_list'>

                                <div className='child_list child_list_header '>
                                    <h4 className='child_text pack_head child_name'>Date</h4>
                                    <h4 className='child_text pack_head child_name'>Time</h4>
                                </div>
                                <div className='child_list_body package__list'>
                                    {data.attendance?.map((a) => (
                                        <>
                                            <div className='child_list'>
                                                <h4 className='child_text pack_text'>{dateFormat(a.date)} </h4>
                                                <h4 className='child_text pack_text'>{a.class_time} </h4>
                                            </div>
                                        </>

                                    ))}
                                    {!data.attendance? <h6>No Log Found</h6>:null}
                                </div>
                            </div>
                        </div>
                    </>
                ))
                }


            </div>

        </div>
    )
}
